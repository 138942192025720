import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Layout, Modal, Row, Space, Table} from 'antd';
import {ExclamationCircleOutlined, MenuOutlined} from '@ant-design/icons';
import {doc, updateDoc} from "firebase/firestore";
import {db} from '../FirebaseApp';
import {useDispatch, useSelector} from "react-redux";
import {getItems, selectItems} from "../redux/reducers/items";
import {Link, useHistory} from 'react-router-dom'
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const {Content} = Layout;

const type = 'DraggableBodyRow';
let orderData = [];

const DraggableBodyRow = ({index, moveRow, className, style, ...restProps}) => {
  const ref = useRef();
  const [{isOver, dropClassName}, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const {index: dragIndex} = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: async item => {
      orderData = [];
      moveRow(item.index, index);

    },
  });
  const [, drag] = useDrag({
    type,
    item: {index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{cursor: 'move', ...style}}
      {...restProps}
    />
  );
};

const ItemSort = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector(selectItems);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    let newData = [];
    if (items !== null) {
      for (const itemsKey in items) {
        const item = items[itemsKey]

        newData.push({
          key: item.id,
          id: item.id,
          name: item.data().name,
          score: item.data().score,
          order: item.data().order,
        })
      }
    }

    setDatas(newData)
  }, [items]);

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '1%',
      render: () => {
        return (
          <MenuOutlined/>
        );
      }
    },
    {
      title: 'No.',
      dataIndex: 'order',
      key: 'order',
      width: '1%',
      render: (order) => {
        return (
          order + 1
        );
      }
    },
    {
      title: '품목',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: '스코어',
      dataIndex: 'score',
      key: 'score',
      width: '10%',
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = datas[dragIndex];
      // console.log(dragRow)
      setDatas(
        update(datas, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [datas],
  );

  const sort = async () => {
    for (const orderKey in orderData) {
      const docRef = doc(db, "items", orderData[orderKey].id);
      await updateDoc(docRef, {
        'order': orderData[orderKey].order
      });
    }
    dispatch(getItems())
    // history.push({pathname: '/item'});
  }

  return (
    <Content style={{margin: '0 16px', height: '100%'}}>
      <div className="site-layout-background" style={{padding: 24, height: '100%'}}>
        <DndProvider backend={HTML5Backend}>
          <Table
            pagination={false}
            columns={columns}
            dataSource={datas}
            components={components}
            onRow={(record, index) => (
              console.log(index + " : " + record.name + ', id: ' + record.id),
                orderData.push({
                  id: record.id,
                  order: index,
                }),
                {
                  record,
                  index,
                  moveRow,
                  onClick: () => {
                    console.log(record)
                    console.log(index)
                  }
                })}
            style={{marginTop: '50px'}} rowKey={record => record.id}/>
        </DndProvider>

        <Row justify="end" style={{marginTop: "30px"}}>
          <Space>
            <Link to="/item">
              <Button>목록</Button>
            </Link>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: "품목 정렬",
                  icon: <ExclamationCircleOutlined/>,
                  content: "품목 수에 따라 시간이 약 30초 이상 소요됩니다. 정렬 하시겠습니까?",
                  okText: "정렬",
                  cancelText: "취소",
                  onOk: sort,
                });
              }}
              type="primary"
            >
              정렬
            </Button>
          </Space>
        </Row>


      </div>
    </Content>
  );
}

export default ItemSort;