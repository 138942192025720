import React, { useEffect, useState, useRef } from 'react';
import { Layout, Table, Input, Button, Space, Tag, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getCollectHistorySubCollection, selectCollectHistories, selectCollectHistoriesItems } from "../redux/reducers/collect_history";
import { selectItems } from "../redux/reducers/items";
import { selectShops } from "../redux/reducers/shops";
import { selectUsers } from "../redux/reducers/users";
import { Excel } from 'antd-table-saveas-excel';

const { Content } = Layout;

const BranchCollectHistory = (props) => {
  const collectHistories = useSelector(selectCollectHistories);
  const collectHistoriesItems = useSelector(selectCollectHistoriesItems);
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);
  const items = useSelector(selectItems);

  const [datas, setDatas] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function updateData() {
      let newData = [];
      if(collectHistories !== null && shops !== null) {
        const collectHistoriesLength = Object.keys(collectHistories).length
        let count = 0

        for (const collectHistoriesKey in collectHistories) {
          const collectHistory = collectHistories[collectHistoriesKey]
          const shopID = props.location.state.shopid;
          
          if(collectHistory.data().shop_id === shopID) {
            let newItems = [];
            let quantity = null;
            /* collectHistory.collect_items.forEach((item) => {
              newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
              quantity = quantity + item.data().quantity          
            }) */
            
            /* let itemSnap = collectHistory.collect_items
            if(itemSnap===null) {
              const action = await dispatch( getCollectHistorySubCollection({id: collectHistory.id, subCollection: "collect_items"}) )
              itemSnap = action.payload
            }
            
            itemSnap.forEach((item) => {
              newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
              quantity = quantity + item.data().quantity          
            }) */
            
            let shopName = null;
            if (shops[collectHistory.data().shop_id] !== undefined) {
              shopName = shops[collectHistory.data().shop_id].data().name
            }
            
            let nickname = "";
            if (users[collectHistory.data().uid] !== undefined) {
              nickname = users[collectHistory.data().uid].data().name
            }
            
            const idx = newData.push({
              idx: collectHistoriesLength - count,
              id: collectHistory.id,
              uid: nickname,
              shopName: shopName,
              name: collectHistory.data().name,
              score: collectHistory.data().score,
              items: newItems,
              quantity: quantity,
              create: collectHistory.data().create.toDate(),
              time: collectHistory.data().create.toDate().toLocaleTimeString('it-IT'),
            })

            if (!collectHistoriesItems[collectHistoriesKey]) {
              dispatch( getCollectHistorySubCollection( {id: collectHistoriesKey, subCollection: "collect_items"} ) ).then((data)=>{
                data.payload.docs?.forEach((item)=>{
                    newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
                    quantity = quantity + item.data().quantity
                  // console.log(items[item.id].data().name)
                })
                newData[idx-1]["items"] = newItems
                newData[idx-1]["quantity"] = quantity
              })
            } else {
              collectHistoriesItems[collectHistoriesKey]?.forEach((item)=>{
                newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
                quantity = quantity + item.data().quantity
              })
              newData[idx-1]["items"] = newItems
              newData[idx-1]["quantity"] = quantity
            }
  
            count++
          }
        }
      }
      setTimeout(()=>{
        setLoading(false)
        setDatas(newData)
      },500)
    }
    updateData();
  }, [collectHistories, shops, users, items, props, dispatch]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  }; 

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '1%',
      render: (text, record, index) => {
        return (
          index + 1
        );
      }
    },
    {
      title: '닉네임',
      dataIndex: 'uid',
      key: 'uid',
      width: '30%',
      ...getColumnSearchProps('uid'),
      sorter: (a, b) => a.uid.length - b.uid.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '적립 품목',
      dataIndex: 'items',
      key: 'items',
      render: (items)=> {
        return (
          <>
          {items.map(item => (
            <Tag color="magenta" key={item.name}>
              {item.name} +{item.quantity}
            </Tag>
          ))}
        </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map(item => {return item.name + item.quantity})
        return resultItems.join(', ');
      }
    },
    {
      title: '개수',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '획득 점수/포인트',
      dataIndex: 'score',
      key: 'score',
      width: '20%',
      sorter: (a, b) => a.score - b.score,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
    },
  ];
  return (
    <Content style={{ margin: '0 16px', height: '100%' }}>
      <div className="site-layout-background" style={{ padding: 24, height: '100%' }}>
        {/*
        <Button icon={<DownloadOutlined />} style={{ position:'absolute', right: '40px',}} 
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({ background: 'FFFFFF', })
              .addSheet('적립 히스토리')
              .addColumns(columns)
              .addDataSource(datas)
              .saveAs('적립 히스토리.xlsx');
          }}
        >
          다운로드</Button>
        */}
        <Spin tip="Loading..." spinning={loading} >
          <Table columns={columns} dataSource={datas} style={{ marginTop: '50px' }} rowKey={record => record.id}/>
        </Spin>
      </div>
    </Content> 
  );
}

export default BranchCollectHistory;