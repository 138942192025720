import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ExclamationCircleOutlined, FileExcelOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";

import {Button, Input, Layout, Modal, Space, Spin, Table, Tag} from 'antd';
import Highlighter from 'react-highlight-words';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getAnnouncements, selectAnnouncements} from "../redux/reducers/announcement";
import {Excel} from 'antd-table-saveas-excel';

const {Content} = Layout;
const nowDate = new Date();

const Announcement = () => {
  const announcements = useSelector(selectAnnouncements);

  const history = useHistory();

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef < HTMLInputElement > (null);

  const dispatch = useDispatch();


  const updateData = useCallback(() => {
    let newData = [];
    for (const announcementsKey in announcements) {
      const announcement = announcements[announcementsKey]

      newData.push({
        id: announcement.id,
        key: announcement.id,
        title: announcement.data().title,
        date: announcement.data().start.toDate().toLocaleDateString("fr-CA") + ' ~ ' + announcement.data().end.toDate().toLocaleDateString("fr-CA"),
        post: (announcement.data().start.toDate() <= nowDate && nowDate <= announcement.data().end.toDate()) ? '게시중' : '게시완료',
      })
    }
    setDatas(newData)
    setLoading(false)
  }, [announcements])

  function addNotice() {
    Modal.confirm({
      title: '공지사항을 추가하시겠습니까?',
      icon: <ExclamationCircleOutlined style={{color: "#52C41A"}}/>,
      content: '',
      okText: '추가',
      cancelText: '취소',
      onOk() {
        history.push('/announcement/add')
//        signOut(auth).then(() => {
        //         history.push('/')
        // this.destroy();
        //      }).catch((error) => {
        //     });
      }
    });
  }

  useEffect(() => {
    if (announcements === null) {
      dispatch(getAnnouncements())
      updateData()
    } else {
      updateData()
    }

  }, [announcements, dispatch, updateData]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      width: '1%',
      render: (text, record, index) => {
        return (
          index + 1
        );
      }
    },
    {
      title: '게시 여부',
      dataIndex: 'post',
      key: 'post',
      width: '15%',
      ...getColumnSearchProps('post'),
      sorter: (a, b) => a.post.length - b.post.length,
      sortDirections: ['descend', 'ascend'],
      render: (post) => {
        let color = post.length > 3 ? 'default' : 'success';
        return (
          <Tag color={color} key={post}>
            {post}
          </Tag>
        );
      },
      excelRender: (post) => {
        return post;
      }
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: '65%',
      ...getColumnSearchProps('title'),
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '게시 기간',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '20%',
    },
    {
      title: '비고',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      width: '5%',
      render: (text, record, index) => {
        return (
          <Link to={
            {
              pathname: "/announcement/info/" + record.id,
            }
          }><Button>수정</Button></Link>
        );
      },
      excelRender: () => {
        return;
      }
    },
  ];

  return (
    <Content style={{margin: '0 16px', height: '100%'}}>
      <div className="site-layout-background" style={{padding: 24, height: '100%', marginTop: 0}}>
        <Link to={"/announcement/add"}>
          <Button
            icon={<PlusOutlined/>}
            type="primary"
            style={{position: 'absolute', right: '40px',}}
          >
            공지 추가
          </Button>
        </Link>
        <Spin tip="Loading..." spinning={loading}>
          <Table columns={columns} dataSource={datas} style={{marginTop: '50px'}}/>
        </Spin>
      </div>
    </Content>
  );
}

export default Announcement;
