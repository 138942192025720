import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getHashtags = createAsyncThunk(
  "getHashtags",
  async (params, { dispatch, getState }) => {
    console.log("call getHashtags")
    const hashtagsRef = collection(db, "hashtags")
    // const q = query(hashtagsRef, orderBy("create", "asc"));
    const q = query(hashtagsRef, orderBy("order", "asc"));
    const hashtagsSnap = await getDocs(q)
    dispatch(setHashtags(hashtagsSnap));
  }
);

const initialState = {
  docs: null,
};

const hashtags = createSlice({
  name: "hashtags",
  initialState,
  reducers: {
    setHashtags: (state, action) => {
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp
    },
  },
});

//actions
export const {
  setHashtags,
} = hashtags.actions;

//selectors
export const selectHashtags = (state) => state.hashtags.docs;

export default hashtags.reducer;
