import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { selectDonateHistories } from "../redux/reducers/donate_history";
import {getUsersSubCollection, selectUsers } from "../redux/reducers/users";
import { getDonates, selectDonates } from "../redux/reducers/donate";

const UserDonate = (props) => {
  const donateHistories = useSelector(selectDonateHistories);
  const users = useSelector(selectUsers);
  const donates = useSelector(selectDonates);

  const [datas, setDatas] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const updateData = useCallback(data => {
    if(donates === null) dispatch(getDonates())
      let newData = [];
      for (let i = 0; i < data.size; i++) {
        const donate = data.docs[i]
        const userDonate = donateHistories[donate.id]
        if (userDonate !== undefined && donates !== null) {
          newData.push({
            id: donate.id,
            name: donates[userDonate.data().donate_id] === undefined ? '': donates[userDonate.data().donate_id].data().name,
            coin: userDonate.data().coin,
            create: userDonate.data().create.toDate(),
            time: userDonate.data().create.toDate().toLocaleTimeString('it-IT'),
          })
        }
      }

      setDatas(newData)
      setLoading(false)
  },[donateHistories, dispatch, donates])

  useEffect(() => {
    async function loadSubCollection() {
      const user = users ? users[props?.id] : null
      if(user !== null) {
        setLoading(true)
        if(user.donate===null){
          const action = await dispatch( getUsersSubCollection( {id: props.id, subCollection: "donate"} ) )
          updateData(action.payload)
        } else {
          updateData(user.donate)
        }
      }
    }
    loadSubCollection();
  }, [dispatch, props, updateData, users]);

  const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };  

  const columns = [
    {
      title: '기부  이름',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      ...getColumnSearchProps('name'),
    },
    {
      title: '사용 코인',
      dataIndex: 'coin',
      key: 'coin',
      sorter: (a, b) => a.coin - b.coin,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
    },
  ];
  return (
    <div className="site-layout-background" style={{ padding: 24, height: '100%', marginTop: 0 }}>
      <Spin tip="Loading..." spinning={loading} >
        <Table columns={columns} dataSource={datas} style={{ marginTop: '30px' }} rowKey={record => record.id}/>
      </Spin>
    </div>   
  );
}


export default UserDonate;