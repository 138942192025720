import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteReview } from "../redux/reducers/reviews";
import {
  getShops,
  getShopsSubCollection,
  selectShops,
} from "../redux/reducers/shops";
import { getUsersSubCollection, selectUsers } from "../redux/reducers/users";
import ThumbnailContainer from "./ThumbnailContainer";

const ShopReviews = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);
  const [id, setID] = useState("");
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState([]);

  const updateData = useCallback(
    (data) => {
      let newData = [];
      setID(props.id);
      const shop = shops[props?.id];

      data.docs.forEach((doc) => {
        const rdata = doc.data();
        const user = users[rdata.uid].data().name;
        const odata = {
          reviewData: {
            userId: rdata.uid,
            shopId: props.id,
            reviewId: rdata.reviewId,
          },
          content: rdata.content,
          user,
          thumbnail: rdata?.name ?? "",
          date: rdata.create,
          time: rdata.create.toDate().toLocaleTimeString("it-IT"),
        };
        newData.push(odata);
      });
      //
      newData.sort((a, b) => b.date - a.date);
      setDatas(
        newData.map((data, index) => {
          data.no = index + 1;
          return data;
        })
      );
      //
      setLoading(false);
    },
    [props, shops, users]
  );

  useEffect(() => {
    (async () => {
      const shop = shops ? shops[props?.id] : null;
      if (shop !== null) {
        setLoading(true);
        if (!shop?.reviews) {
          const action = await dispatch(
            getShopsSubCollection({ id: props.id, subCollection: "reviews" })
          );
          updateData(action.payload);
        } else {
          updateData(shop.reviews);
        }
      }
    })();
  }, [dispatch, props, updateData, shops]);

  const deleteConfirm = (info) => {
    const { userId, shopId, reviewId } = info;
    console.log("/shop/info/" + shopId);
    Modal.confirm({
      title: "리뷰를 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        // delete review
        await dispatch(deleteReview(info));

        // reload page
        dispatch(getShops());
        history.push({ pathname: "/shop/info/" + shopId });
      },
    });
  };

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, height: "100%", marginTop: 0 }}
    >
      <Table
        rowKey="no"
        columns={getColumns(deleteConfirm)}
        dataSource={datas}
        style={{ marginTop: "30px" }}
      />
    </div>
  );
};

export default ShopReviews;

const getColumns = (deleteConfirm) => {
  return [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "썸네일",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (name) => <ThumbnailContainer name={name} />,
    },
    {
      title: "글쓴이",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "내용",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "날짜",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <div>{date.toDate().toLocaleDateString("fr-CA")}</div>;
      },
    },
    {
      title: "시간",
      dataIndex: "time",
      key: "time",
    },
    {
      dataIndex: "reviewData",
      key: "delete",
      render: (reviewData, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DeleteOutlined
              style={{
                color: "#F5222D",
              }}
              onClick={() => deleteConfirm(reviewData)}
            />
          </div>
        );
      },
    },
  ];
};
