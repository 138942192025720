import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Layout, Modal, Row, Space, Spin, Table} from 'antd';
import {ExclamationCircleOutlined, MenuOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {getDonates, selectDonates} from "../redux/reducers/donate";
import {selectShops} from "../redux/reducers/shops";
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {doc, updateDoc} from "firebase/firestore";
import {db} from '../FirebaseApp';

const {Content} = Layout;
const type = 'DraggableBodyRow';
let orderData = [];

const DraggableBodyRow = ({index, moveRow, className, style, ...restProps}) => {
  const ref = useRef();
  const [{isOver, dropClassName}, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const {index: dragIndex} = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: async item => {
      orderData = [];
      moveRow(item.index, index);

    },
  });
  const [, drag] = useDrag({
    type,
    item: {index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{cursor: 'move', ...style}}
      {...restProps}
    />
  );
};

const DonateSort = () => {
  const donates = useSelector(selectDonates);
  const shops = useSelector(selectShops);

  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    let newData = [];
    if (donates === null) {
      dispatch(getDonates())
    } else {
      for (const donatesKey in donates) {
        const donate = donates[donatesKey]

        let shopName = null;
        if (shops !== null) {
          if (shops[donate.data().shop_id] !== undefined) {
            shopName = shops[donate.data().shop_id].data().name
          }
        }

        newData.push({
          id: donate.id,
          key: donate.id,
          name: donate.data().name,
          shopName: shopName,
          // sponsor: donate.data().sponsor, 
          goal: donate.data().goal,
          current: donate.data().current,
          start: donate.data().start.toDate(),
          end: donate.data().end.toDate(),
          order: donate.data().order,
        })
      }
    }

    setDatas(newData)
    setLoading(false)
  }, [donates, shops, dispatch]);

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '1%',
      render: () => {
        return (
          <MenuOutlined/>
        );
      }
    },
    {
      title: 'No.',
      dataIndex: 'order',
      key: 'order',
      width: '1%',
      render: (order) => {
        return (
          order + 1
        );
      }
    },
    {
      title: '기부 이름',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: '후원처',
      dataIndex: 'shopName',
      key: 'shopName',
      width: '10%',
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = datas[dragIndex];
      // console.log(dragRow)
      setDatas(
        update(datas, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [datas],
  );

  const sort = async () => {
    const sortedOrder = [...new Set(orderData)];

    for (const orderKey in orderData) {
      const docRef = doc(db, "donate", orderData[orderKey].id);
      await updateDoc(docRef, {
        'order': orderData[orderKey].order
      });
    }
    dispatch(getDonates())
  }

  return (
    <Content style={{margin: '0 16px', height: '100%'}}>
      <div className="site-layout-background" style={{padding: 24, height: '100%'}}>

        <Spin tip="Loading..." spinning={loading}>
          <DndProvider backend={HTML5Backend}>
            <Table
              pagination={false}
              columns={columns}
              dataSource={datas}
              components={components}
              onRow={(record, index) => (
                console.log(index + " : " + record.name + ', id: ' + record.id),
                  orderData.push({
                    id: record.id,
                    order: index,
                  }),
                  {
                    record,
                    index,
                    moveRow,
                    onClick: () => {
                      console.log(record)
                      console.log(index)
                    }
                  })}
              style={{marginTop: '50px'}} rowKey={record => record.id}/>
          </DndProvider>
        </Spin>

        <Row justify="end" style={{marginTop: "30px"}}>
          <Space>
            <Link to="/donate">
              <Button>목록</Button>
            </Link>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: "기부 정렬",
                  icon: <ExclamationCircleOutlined/>,
                  content: "기부 수에 따라 시간이 약 30초 이상 소요됩니다. 정렬 하시겠습니까?",
                  okText: "정렬",
                  cancelText: "취소",
                  onOk: sort,
                });
              }}
              type="primary"
            >
              정렬
            </Button>
          </Space>
        </Row>

      </div>
    </Content>
  );
}

export default DonateSort;