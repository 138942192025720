import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Layout, Space, Spin, Table} from 'antd';
import Highlighter from 'react-highlight-words';
import {FileExcelOutlined, PlusOutlined, SearchOutlined, VerticalAlignMiddleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {getGoods, selectGoods} from "../redux/reducers/goods";
import {selectShops} from "../redux/reducers/shops";
import {Excel} from 'antd-table-saveas-excel';

const {Content} = Layout;

const Goods = () => {
  const goods = useSelector(selectGoods);
  const shops = useSelector(selectShops);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef < HTMLInputElement > (null);

  const dispatch = useDispatch();

  useEffect(() => {
    let newData = [];

    if (goods === null) {
      dispatch(getGoods())
    } else {
      for (const goodsKey in goods) {
        const good = goods[goodsKey]

        let shopName = null;
        if (shops !== null) {
          if (shops[good.data().shop_id] !== undefined) {
            shopName = shops[good.data().shop_id].data().name
          }
        }

        newData.push({
          id: good.id,
          key: good.id,
          name: good.data().name,
          shopName: shopName,
          price: good.data().price,
          total: good.data().total,
          current: good.data().current,
          start: good.data().start.toDate(),
          end: good.data().end.toDate(),
          order: good.data().order,
        })
      }
    }

    setDatas(newData)
    setLoading(false)
  }, [goods, shops, dispatch]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'order',
      key: 'order',
      render: (order) => {
        return order + 1
      },
      excelRender: (order) => {
        return order + 1
      }
    },
    {
      title: '상품 이름',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '코인',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      sortDirections: ['descend', 'ascend'],
      excelRender: (price) => {
        return price
      }
    },
    {
      title: '사용처',
      dataIndex: 'shopName',
      key: 'shopName',
      ...getColumnSearchProps('shopName'),
      excelRender: (shopName) => {
        return shopName
      }
    },
    {
      title: '판매 총량',
      dataIndex: 'total',
      key: 'total',
      sorter: (a, b) => a.total - b.total,
      sortDirections: ['descend', 'ascend'],
      excelRender: (total) => {
        return total
      }
    },
    {
      title: '판매 재고',
      dataIndex: 'current',
      key: 'current',
      sorter: (a, b) => a.current - b.current,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '판매 시작 날짜',
      dataIndex: 'start',
      key: 'start',
      sorter: (a, b) => a.start - b.start,
      sortDirections: ['descend', 'ascend'],
      render: (start) => {
        return start.toLocaleDateString("fr-CA")
      },
      excelRender: (start) => {
        return start.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '판매 마감 날짜',
      dataIndex: 'end',
      key: 'end',
      sorter: (a, b) => a.end - b.end,
      sortDirections: ['descend', 'ascend'],
      render: (end) => {
        return end.toLocaleDateString("fr-CA")
      },
      excelRender: (end) => {
        return end.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '비고',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      render: (text, record, index) => {
        return (
          <Link to={
            {
              pathname: "/goods/info/" + record.id,
            }
          }><Button type="primary" ghost>수정</Button></Link>
        );
      },
      excelRender: () => {
        return;
      }
    },
  ];
  return (
    <Content style={{margin: '0 16px', height: '100%'}}>
      <div className="site-layout-background" style={{padding: 24, height: '100%'}}>
        <Link to="/goods/sort">
          <Button
            icon={<VerticalAlignMiddleOutlined/>}
            type="second"
            style={{position: 'absolute', left: '240px',}}
          >
            상품 정렬
          </Button>
        </Link>
        <Button
          icon={<FileExcelOutlined/>}
          type={"default"}
          style={{position: 'absolute', left: '365px',}}
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({background: 'FFFFFF', width: 110})
              .addSheet('상품 목록')
              .addColumns(columns)
              .addDataSource(datas, {
                str2Percent: true
              })
              .saveAs('상품 목록.xlsx');
          }}
        >
          다운로드
        </Button>
        <Link to="/goods/add">
          <Button
            icon={<PlusOutlined/>}
            type="primary"
            style={{position: 'absolute', right: '40px',}}
          >상품 추가</Button>
        </Link>
        <Spin tip="Loading..." spinning={loading}>
          <Table columns={columns} dataSource={datas} style={{marginTop: '50px'}} rowKey={record => record.id}/>
        </Spin>
      </div>
    </Content>
  );
}

export default Goods;