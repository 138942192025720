import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Layout, Modal, Row, Space, Spin, Table} from 'antd';
import {useSelector} from "react-redux";
import {selectShops} from "../redux/reducers/shops";
import {ExclamationCircleOutlined, MenuOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {doc, updateDoc} from "firebase/firestore";
import {db} from '../FirebaseApp';

const {Content} = Layout;
const type = 'DraggableBodyRow';

const DraggableBodyRow = ({index, moveRow, className, style, ...restProps}) => {
  const ref = useRef();
  const [{isOver, dropClassName}, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const {index: dragIndex} = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: async item => {
      console.log("drop")
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{cursor: 'move', ...style}}
      {...restProps}
    />
  );
};


const ShopSort = () => {
  const shops = useSelector(selectShops);

  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [needUpdate, setNeedUpate] = useState(true)

  const updateData = () => {
    let newData = []
    for (const shopsKey in shops) {
      const shop = shops[shopsKey]
      const data = shop.data()
      newData.push({
        key: shop.id,
        id: shop.id,
        name: data.name,
        sggnm: data.sggnm,
        order: data.order,
      })
    }
    setDatas(newData)

    setTimeout(() => {
      setLoading(false)
      setNeedUpate(false)
    }, 100)
  }

  useEffect(async () => {
    if (needUpdate && shops !== null && Object.keys(shops).length > 0) {
      setLoading(true)
      updateData()
    }
  }, [shops, needUpdate]);

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '1%',
      render: (text, record, index) => {
        return (
          <MenuOutlined/>
        );
      }
    },
    {
      title: 'No.',
      dataIndex: 'order',
      key: 'order',
      width: '1%',
      render: (order) => {
        return (
          order + 1
        );
      }
    },
    {
      title: '가게 이름',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: '지역',
      dataIndex: 'sggnm',
      key: 'sggnm',
      width: '10%',
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      console.log("moveRow- from: " + dragIndex + ", to: " + hoverIndex)
      const dragRow = datas[dragIndex];
      setDatas(
        update(datas, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [datas],
  );

  const sort = async () => {
    setLoading(true)

    let order = 0
    for (const data of datas) {
      const docRef = doc(db, "shops", data.id);
      await updateDoc(docRef, {
        'order': order
      });
      console.log("id: " + data.id + ", order: " + order)
      order++
    }

    setNeedUpate(true)
  }

  return (
    <Content style={{margin: '0 16px', height: '100%'}}>
      <div className="site-layout-background" style={{padding: 24, height: '100%'}}>

        <Spin tip="Loading..." spinning={loading}>
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={columns}
              dataSource={datas}
              components={components}
              onRow={(record, index) => (
                {
                  record,
                  index,
                  moveRow,
                  onClick: () => console.log(record.id)
                }
              )}
              pagination={{
                total: 1000,
                pageSize: 1000,
                hideOnSinglePage: true
              }}
              style={{marginTop: '50px'}}
              rowKey={record => record.id}
            />
          </DndProvider>
        </Spin>

        <Row justify="end" style={{marginTop: "30px"}}>
          <Space>
            <Link to="/shop">
              <Button>목록</Button>
            </Link>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: "가게 정렬",
                  icon: <ExclamationCircleOutlined/>,
                  content: "가게 수에 따라 시간이 약 30초 이상 소요됩니다. 정렬 하시겠습니까?",
                  okText: "정렬",
                  cancelText: "취소",
                  onOk: sort,
                });
              }}
              type="primary"
            >
              정렬
            </Button>
          </Space>
        </Row>

      </div>
    </Content>
  )
}

export default ShopSort;