import React, {useEffect, useRef, useState} from "react";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Descriptions,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectHashtags} from "../redux/reducers/hashtags";
import {selectItems} from "../redux/reducers/items";
import {selectUsers} from "../redux/reducers/users";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import {collection, doc, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "../FirebaseApp";
import FileManager from "./FileManager";
import {selectShops} from "../redux/reducers/shops";
import zones from "../zones.json";

const { Content } = Layout;
const { TextArea, Search } = Input;
let newOrder = null;

const zoneOptions = zones.data.map((zone) => {
  return {
    value: zone.adm_nm,
  };
});
const zoneList = zones.data;

function onChange(value) {
  console.log(value);
}
const divisionList = [
  { label: "미가입", value: "none" },
  { label: "개인", value: "individual" },
  { label: "클러스터1", value: "cluster1" },
  { label: "클러스터2", value: "cluster2" },
];
const publishList = [
  { label: "비공개", value: "private" },
  { label: "공개", value: "public" },
  { label: "숨김", value: "protect" },
];

const ShopAdd = () => {
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const hashtags = useSelector(selectHashtags);
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);

  const [itemList, setItemList] = useState([]);
  const [hashtagList, setHashtagList] = useState([]);
  const [zone, setZone] = useState([]);
  const formRef = useRef();
  const fileRef = useRef();
  const history = useHistory();

  useEffect(() => {
    console.log("==== shops ====");
    console.log(shops);
    newOrder = Object.keys(shops).length;

    let tempItems = [];
    for (const itemsKey in items) {
      tempItems.push({
        value: items[itemsKey].data().id,
        label: items[itemsKey].data().name,
      });
    }
    setItemList(tempItems);

    let tempHashtags = [];
    for (const hashtagsKey in hashtags) {
      tempHashtags.push({
        value: hashtags[hashtagsKey].data().id,
        label: hashtags[hashtagsKey].data().hashtag,
      });
    }
    setHashtagList(tempHashtags);
  }, [items, hashtags, users, shops]);

  const onSelect = (value) => {
    setZone(zoneList.filter((zone) => zone.adm_nm === value));
    console.log(zone);
  };

  const generatePin = async () => {
    let seq = (Math.floor(Math.random() * 10000) + 10000)
      .toString()
      .substring(1);
    for (const shopsKey in shops) {
      const data = shops[shopsKey].data();
      if (data.pin === seq) {
        seq = await generatePin();
      }
    }
    return seq;
  };

  const addConfirm = (value) => {
    Modal.confirm({
      title: "가게 정보를 추가하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{ color: "#52C41A" }} />,
      content: "",
      okText: "추가",
      cancelText: "취소",
      async onOk() {
        const pin = await generatePin();
        const docRef = doc(collection(db, "shops"));
        await setDoc(docRef, {
          id: docRef.id,
          name: value.name,
          summary: value.summary === undefined ? "" : value.summary,
          phone: value.phone === undefined ? "" : value.phone,
          address: value.address === undefined ? "" : value.address,
          publish:
            value.publish === undefined ? "private" : value.publish.join(),
          division:
            value.division === undefined ? "none" : value.division.join(),
          pin: pin === undefined ? "" : pin,
          open: value.open === undefined ? "" : value.open,
          url: value.url === undefined ? "" : value.url,
          info: value.info === undefined ? "" : value.info,
          tip: value.tip === undefined ? "" : value.tip,
          create: serverTimestamp(),
          update: serverTimestamp(),
          sido: zone[0].sido,
          sgg: zone[0].sgg,
          sggnm: zone[0].sggnm,
          adm_cd2: zone[0].adm_cd2,
          adm_nm: zone[0].adm_nm,
          order: newOrder,
          type: value.type === undefined ? "" : value.type[0],
          items: value.items === undefined ? [] : value.items,
          hashtags: value.hashtags === undefined ? [] : value.hashtags,
          initial_stock: value.initial_stock === undefined ? 0 : value.initial_stock,
        });

        for (let i = 0; i < value.items?.length; i++) {
          const itemRef = doc(docRef, "items", value.items[i]);
          await setDoc(itemRef, {
            create: serverTimestamp(),
          });
        }

        for (let i = 0; i < value.hashtags?.length; i++) {
          const itemRef = doc(docRef, "hashtags", value.hashtags[i]);
          await setDoc(itemRef, {
            create: serverTimestamp(),
          });
        }

        const idList = await fileRef.current.addFiles();
        for (const idListKey in idList) {
          const data = idList[idListKey];
          const name = data.name;
          const uri = data.uri;
          const fileRef = doc(docRef, "files", data.id);
          await setDoc(fileRef, {
            id: data.id,
            create: serverTimestamp(),
            name,
            uri,
          });
        }

        history.push({ pathname: "/shop" });
      },
    });
  };

  return (
    <Content style={{ margin: "0 16px", height: "100%" }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, height: "100%", marginTop: 0 }}
      >
        <div style={{ padding: 50, height: "100%" }}>
          <span style={{color: "#888888", fontSize: "small"}}>* 필수 항목</span>
          <Form ref={formRef} layout="vertical" name="form_in_modal">
            <Descriptions bordered>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="가게 이름 *" span={2}>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "가게 이름을 입력하세요." },
                  ]}
                >
                  <Input placeholder="가게 이름" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="공개 여부 *" span={1}>
                <Form.Item name="pin" hidden>
                  <Input maxLength="4" readOnly={true} />
                </Form.Item>
                <Form.Item
                  name="publish"
                  rules={[{ required: true, message: "공개 여부를 선택하세요." }]}>
                  <Cascader
                    defaultValue={['private']}
                    options={publishList}
                    onChange={onChange}
                    placeholder="선택"
                  />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="컵공유 *" span={2}>
                <Form.Item
                  name="division"
                  rules={[{ required: true, message: '컵공유 유형을 선택하세요.' }]}>
                  <Cascader
                    options={divisionList}
                    onChange={onChange}
                    placeholder="선택"
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="최초 재고" span={1}>
                <Form.Item name="initial_stock">
                  <Input maxLength="6" value={0}/>
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="동네 *" span={2}>
                <Form.Item
                  name="zone"
                  rules={[{ required: true, message: "동네를 선택하세요." }]}
                >
                  <AutoComplete
                    options={zoneOptions}
                    onSelect={onSelect}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    <Search placeholder="검색" />
                  </AutoComplete>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="가게 유형" span={1}>
                <Form.Item
                  name="type">
                  <Cascader
                    options={hashtagList}
                    onChange={onChange}
                    placeholder="선택"
                  />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="주소" span={3}>
                <Form.Item name="address">
                  <Input placeholder="00시 00동 00-00" />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="전화번호" span={1}>
                <Form.Item name="phone">
                  <Input maxLength="13" placeholder="000-0000-0000" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="사이트" span={1}>
                <Form.Item name="url">
                  <Input placeholder="http://" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="영업 시간" span={1}>
                <Form.Item name="open">
                  <Input placeholder="00:00 ~ 00:00" />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="품목" span={3}>
                <Form.Item
                  name="items">
                  <Checkbox.Group options={itemList} onChange={onChange} />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="해시태그" span={3}>
                <Form.Item
                  name="hashtags">
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="선택"
                    options={hashtagList}
                    onChange={onChange}
                  />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="한줄 요약" span={3}>
                <Form.Item name="summary">
                  <Input />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="상세 정보" span={3}>
                <Form.Item name="info">
                  <TextArea showCount />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="제로웨이스트 팁" span={3}>
                <Form.Item name="tip">
                  <TextArea showCount />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="사진 추가하기" span={3}>
                <FileManager ref={fileRef} path={"shop"} max={5} />
              </Descriptions.Item>
            </Descriptions>
          </Form>

          <Row justify="end" style={{ marginTop: "30px" }}>
            <Space>
              <Link to="/shop">
                <Button>목록</Button>
              </Link>
              <Button
                onClick={() => {
                  formRef.current
                    .validateFields()
                    .then((values) => {
                      console.log(values);
                      addConfirm(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
                type="primary"
              >
                추가
              </Button>
            </Space>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default ShopAdd;
