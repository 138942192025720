import React from "react";
import {Button, Cascader, DatePicker, Descriptions, Form, Input, Layout, Modal, Row, Space,} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import {collection, doc, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "../FirebaseApp";
import FileManager from "./FileManager";
import {useDispatch} from "react-redux";
import {getAnnouncements} from "../redux/reducers/announcement";

const {Content} = Layout;
const {RangePicker} = DatePicker;

const typeList = [
  {label: "팝업", value: "popup"},
  {label: "배너", value: "banner"},
];

const AnnouncementAdd = () => {
  const dispatch = useDispatch();
  const formRef = React.createRef(null);
  const fileRef = React.createRef(null);
  const history = useHistory();

  const addConfirm = (value) => {
    Modal.confirm({
      title: "공지사항을 추가하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{color: "#52C41A"}}/>,
      content: "",
      okText: "추가",
      cancelText: "취소",
      async onOk() {
        // 파일 입력 처리
        const idList = await fileRef.current.addFiles();
        let name = "";
        let uri = "";
        if (idList.length > 0) {
          name = idList[0].name;
          uri = idList[0].uri;
        }
        // 데이터 입력 처리
        const docRef = doc(collection(db, "announcement"));
        await setDoc(docRef, {
          id: docRef.id,
          title: value.title,
          link: value.link === undefined ? "" : value.link,
          start: value.dateOfNotice[0]._d,
          end: value.dateOfNotice[1]._d,
          create: serverTimestamp(),
          update: serverTimestamp(),
          type: value.type === undefined ? "banner" : value.type.join(),
          name,
          uri,
        });
        // 파일 입력 처리
        // const idList = await fileRef.current.addFiles();
        for (const idListKey in idList) {
          const data = idList[idListKey];
          const fileRef = doc(docRef, "files", data.id);
          await setDoc(fileRef, {
            id: data.id,
            create: serverTimestamp(),
            name: data.name,
            uri: data.uri,
          });
          // const storage = getStorage();
          // getDownloadURL(ref(storage, data.name))
          //   .then(async (url) => {
          //     await setDoc(fileRef, {
          //       id: data.id,
          //       create: serverTimestamp(),
          //       link: url,
          //     });
          //   })
          //   .catch((error) => {
          //     // Handle any errors
          //   });
        }
        dispatch(getAnnouncements());
        history.push({pathname: "/announcement"});
      },
    });
  };

  return (
    <Content style={{margin: "0 16px", height: "100%"}}>
      <div
        className="site-layout-background"
        style={{padding: 24, height: "100%", marginTop: 0}}
      >
        <div style={{padding: 50, height: "100%"}}>
          <Form ref={formRef} layout="vertical" name="form_in_modal">
            <Descriptions title="" bordered>
              <Descriptions.Item label="제목" span={3}>
                <Form.Item
                  name="title"
                  rules={[
                    {required: true, message: "공지 제목을 입력하세요!"},
                  ]}
                >
                  <Input placeholder="공지 제목"/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="타입" span={3}>
                <Form.Item
                  name="type"
                  rules={[{required: true, message: "타입을 선택하세요!"}]}
                >
                  <Cascader
                    options={typeList}
                    placeholder="선택"
                    style={{width: "320px"}}
                  ></Cascader>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사진 추가하기" span={3}>
                <FileManager ref={fileRef} path={"announcement"} max={1}/>
              </Descriptions.Item>
              <Descriptions.Item label="링크" span={3}>
                <Form.Item name="link">
                  <Input placeholder="공지 링크"/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="게시 기간" span={3}>
                <Form.Item
                  name="dateOfNotice"
                  rules={[
                    {required: true, message: "게시 기간을 선택하세요!"},
                  ]}
                >
                  <RangePicker/>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Form>

          <Row justify="end" style={{marginTop: "30px"}}>
            <Space>
              <Link to="/announcement">
                <Button>목록</Button>
              </Link>
              <Button
                onClick={() => {
                  formRef.current
                    .validateFields()
                    .then((values) => {
                      addConfirm(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
                type="primary"
              >
                추가
              </Button>
            </Space>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default AnnouncementAdd;
