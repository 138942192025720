import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Layout, Space, Spin, Table} from 'antd';
import Highlighter from 'react-highlight-words';
import {FileExcelOutlined, SearchOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {getGoodsHistories, selectGoodsHistories} from "../redux/reducers/goods_history";
import {selectUsers} from "../redux/reducers/users";
import {getGoods, selectGoods} from "../redux/reducers/goods";
import {Excel} from 'antd-table-saveas-excel';

const {Content} = Layout;
const reg = /^([0-9]{0,3})[-. ]?([0-9]{0,4})[-. ]?([0-9]{0,4})$/;

const GoodsHistory = () => {
  const goodsHistories = useSelector(selectGoodsHistories);
  const users = useSelector(selectUsers);
  const goods = useSelector(selectGoods);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef < HTMLInputElement > (null);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
    let newData = [];
    if (goodsHistories === null) {
      dispatch(getGoodsHistories())
    } else {
      if (goods === null) {
        dispatch(getGoods())
      }
      for (const goodsHistoriesKey in goodsHistories) {
        const goodsHistory = goodsHistories[goodsHistoriesKey]

        let nickname = "";
        let mobile = "";
        if (users !== null) {
          if (users[goodsHistory.data().uid] !== undefined) {
            nickname = users[goodsHistory.data().uid].data().name
            mobile = users[goodsHistory.data().uid].data().mobile
          }
        }
        let goodsID = "";

        if (goods !== null) {
          if (goods[goodsHistory.data().goods_id] !== undefined) {
            goodsID = goods[goodsHistory.data().goods_id].data().name
          }
        }
        newData.push({
          key: goodsHistory.id,
          id: goodsHistory.id,
          uid: nickname,
          goodsID: goodsID,
          coin: goodsHistory.data().coin,
          score: goodsHistory.data().score,
          create: goodsHistory.data().create.toDate(),
          time: goodsHistory.data().create.toDate().toLocaleTimeString('it-IT'),
          update: goodsHistory.data().status === 'used' ? goodsHistory.data().update.toDate() : "",
          updateTime: goodsHistory.data().status === 'used' ? goodsHistory.data().update.toDate().toLocaleTimeString('it-IT') : "",
          mobile: mobile.trim().replace(reg, '$2-$3'),
        })
      }
    }

    setDatas(newData)
    setLoading(false)
  }, [goodsHistories, users, goods, dispatch]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      width: '1%',
      render: (text, record, index) => {
        return (
          index + 1
        );
      }
    },
    {
      title: '닉네임',
      dataIndex: 'uid',
      key: 'uid',
      ...getColumnSearchProps('uid'),
      sorter: (a, b) => {
        if (a.uid > b.uid) return 1;
        if (a.uid < b.uid) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '회원 번호',
      dataIndex: 'mobile',
      key: 'mobile',
      ...getColumnSearchProps('mobile'),
    },
    {
      title: '상품 이름',
      dataIndex: 'goodsID',
      key: 'goodsID',
      ...getColumnSearchProps('goodsID'),
      sorter: (a, b) => {
        if (a.goodsID > b.goodsID) return 1;
        if (a.goodsID < b.goodsID) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '사용 코인',
      dataIndex: 'coin',
      key: 'coin',
      sorter: (a, b) => a.coin - b.coin,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '사용 날짜',
      dataIndex: 'update',
      key: 'update',
      sorter: (a, b) => a.update - b.update,
      sortDirections: ['descend', 'ascend'],
      render: (update) => {
        if (update !== "")
          return update.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '사용 시간',
      dataIndex: 'updateTime',
      key: 'updateTime',
    },
  ];
  return (
    <Content style={{margin: '0 16px', height: '100%'}}>
      <div className="site-layout-background" style={{padding: 24, height: '100%'}}>
        <Button
          icon={<FileExcelOutlined/>}
          style={{position: 'absolute', left: '240px',}}
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({background: 'FFFFFF',})
              .addSheet('구매 히스토리')
              .addColumns(columns)
              .addDataSource(datas)
              .saveAs('구매 히스토리.xlsx');
          }}
        >
          다운로드
        </Button>
        <Spin tip="Loading..." spinning={loading}>
          <Table columns={columns} dataSource={datas} style={{marginTop: '50px'}} rowKey={record => record.id}/>
        </Spin>
      </div>
    </Content>
  );
}

export default GoodsHistory;