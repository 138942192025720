import React, { useEffect, useState, useRef } from 'react';
import { Layout, Table, Input, Button, Space, Tag, Spin, Row } from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, DownloadOutlined, FileExcelOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {getCollectHistorySubCollection, selectCollectHistories, selectCollectHistoriesItems} from "../redux/reducers/collect_history";
import { selectItems } from "../redux/reducers/items";
import { selectHashtags } from "../redux/reducers/hashtags";
import { selectShops } from "../redux/reducers/shops";
import { selectUsers } from "../redux/reducers/users";
import { Excel } from 'antd-table-saveas-excel';

const { Content } = Layout;
const reg = /^([0-9]{0,3})[-. ]?([0-9]{0,4})[-. ]?([0-9]{0,4})$/;

const CollectHistory = () => {
  const collectHistories = useSelector(selectCollectHistories);
  const collectHistoriesItems = useSelector(selectCollectHistoriesItems);
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);
  const items = useSelector(selectItems);
  const hashtags = useSelector(selectHashtags);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function updateData() {
      setLoading(true)
      let newData = [];

      if(collectHistories !== null && shops !== null && users !== null) {
        const collectHistoriesLength = Object.keys(collectHistories).length
        let count = 0

        for (const collectHistoriesKey in collectHistories) {
          const collectHistory = collectHistories[collectHistoriesKey]
          let newItems = [];

          /* let itemSnap = collectHistory.collect_items
          if(itemSnap===null) {
            const action = await dispatch( getCollectHistorySubCollection({id: collectHistory.id, subCollection: "collect_items"}) )
            itemSnap = action.payload
          }

          itemSnap.forEach((item) => {
            newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
          }) */

          let shopName = "";
          if (shops[collectHistory.data().shop_id] !== undefined) {
            shopName = shops[collectHistory.data().shop_id].data().name
          }
          
          let nickname = "";
          let mobile = "";
          if (users[collectHistory.data().uid] !== undefined) {
            nickname = users[collectHistory.data().uid].data().name
            mobile = users[collectHistory.data().uid].data().mobile
          }

          let shopType = ""
          if(hashtags !== null) {
            if(shops[collectHistory.data().shop_id] !== undefined) {
              if(hashtags[shops[collectHistory.data().shop_id].data().type] !== undefined)
                shopType = hashtags[shops[collectHistory.data().shop_id].data().type].data().hashtag
            }
          } 
          
          const idx = newData.push({
            idx: collectHistoriesLength - count,
            key: collectHistory.id,
            id: collectHistory.id,
            uid: nickname,
            shopName: shopName,
            name: collectHistory.data().name,
            score: collectHistory.data().score,
            items: newItems,
            create: collectHistory.data().create.toDate(),
            time: collectHistory.data().create.toDate().toLocaleTimeString('it-IT'),
            mobile: mobile.trim().replace(reg, '$2-$3'),
            type: shopType,
          })

          if (!collectHistoriesItems[collectHistoriesKey]) {
            dispatch( getCollectHistorySubCollection( {id: collectHistoriesKey, subCollection: "collect_items"} ) ).then((data)=>{
              data.payload.docs?.forEach((item)=>{
                  newItems.push({name: items[item.id].data().name, quantity:item.data().quantity, score: items[item.id].data().score})
                // console.log(items[item.id].data().name)
              })
              newData[idx-1]["items"] = newItems
            })
          } else {
            collectHistoriesItems[collectHistoriesKey]?.forEach((item)=>{
              newItems.push({name: items[item.id].data().name, quantity:item.data().quantity, score: items[item.id].data().score})
            })
            newData[idx-1]["items"] = newItems
          }

          count++
        }
        setLoading(false)
      }

      setTimeout(()=>{
        setDatas(newData)
        // setLoading(false)
      },500)
    }
    updateData()
  }, [collectHistories, shops, users, items, dispatch]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  }; 

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      width: '1%',
      render: (text, record, index) => {
        return (
          index + 1
        );
      }
    },
    {
      title: '닉네임',
      dataIndex: 'uid',
      key: 'uid',
      ...getColumnSearchProps('uid'),
      sorter: (a, b) => {
        if (a.uid>b.uid) return -1;
        if (a.uid<b.uid) return 1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '회원 번호',
      dataIndex: 'mobile',
      key: 'mobile',
      ...getColumnSearchProps('mobile'),
    },
    {
      title: '적립 가게',
      dataIndex: 'shopName',
      key: 'shopName',
      ...getColumnSearchProps('shopName'),
      sorter: (a, b) => {
        if (a.shopName>b.shopName) return -1;
        if (a.shopName<b.shopName) return 1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '가게 유형',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSearchProps('type'),
      sorter: (a, b) => {
        if (a.type>b.type) return -1;
        if (a.type<b.type) return 1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '적립 품목',
      dataIndex: 'items',
      key: 'items',
      render: (items)=> {
        return (
          <>
          {items.map(item => (
            <Row key={item.name}>
              <Tag color="magenta" key={item.name}>
                {item.name}
              </Tag>
            </Row>
          ))}
          </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map(item => {return item.name})
        return resultItems.join(', ');
      }
    },
    {
      title: '스코어',
      dataIndex: 'items',
      key: 'itemScore',
      render: (items)=> {
        return (
          <>
          {items.map(item => (
            <Row key={item.name}>
              <Tag color="magenta" key={item.name}>
                {item.score}
              </Tag>
            </Row>
          ))}
          </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map(item => {return item.score})
        return resultItems.join(', ');
      }
    },
    {
      title: '개수',
      dataIndex: 'items',
      key: 'itemsQuantity',
      render: (items)=> {
        return (
          <>
          {items.map(item => (
            <Row key={item.name}>          
              <Tag color="magenta" key={item.name}>
                {item.quantity}
              </Tag>
            </Row>
          ))}
          </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map(item => {return item.quantity})
        return resultItems.join(', ');
      }
    },
    {
      title: '적립 스코어',
      dataIndex: 'items',
      key: 'itemCollectScore',
      render: (items)=> {
        return (
          <>
          {items.map(item => (
            <Row key={item.name}>
              <Tag color="magenta" key={item.name}>
                {item.score * item.quantity}
              </Tag>
            </Row>
          ))}
          </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map(item => {return item.score * item.quantity})
        return resultItems.join(', ');
      }
    },
    {
      title: '적립 코인',
      dataIndex: 'items',
      key: 'itemCollectScore',
      render: (items)=> {
        return (
          <>
          {items.map(item => (
            <Row key={item.name}>
              <Tag color="gold" key={item.name}>
                {item.score * item.quantity * 10}
              </Tag>
            </Row>
          ))}
          </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map(item => {return item.score * item.quantity * 10})
        return resultItems.join(', ');
      }
    },
    {
      title: '획득 스코어/코인',
      dataIndex: 'score',
      key: 'score',
      sorter: (a, b) => a.score - b.score,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
    },
  ];
  return (
    <Content style={{ margin: '0 16px', height: '100%' }}>
      <div className="site-layout-background" style={{ padding: 24, height: '100%' }}>
        <Button
          icon={<FileExcelOutlined/>}
          style={{ position:'absolute', left: '240px',}}
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({ background: 'FFFFFF', })
              .addSheet('적립 히스토리')
              .addColumns(columns)
              .addDataSource(datas)
              .saveAs('적립 히스토리.xlsx');
          }}
        >
          다운로드
        </Button>
        <Spin tip="Loading..." spinning={loading} >
          <Table columns={columns} dataSource={datas} style={{ marginTop: '50px' }} rowKey={record => record.id}/>
        </Spin>
      </div>
    </Content> 
  );
}

export default CollectHistory;