import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getAnnouncements = createAsyncThunk(
  "getAnnouncements",
  async (params, { dispatch, getState }) => {
    console.log("call getAnnouncements")
    const announcementsRef = collection(db, "announcement")
    const q = query(announcementsRef, orderBy("create", "desc"));
    const announcementsSnap = await getDocs(q)
    dispatch(setAnnouncements(announcementsSnap));
  }
);

const initialState = {
  docs: null,
};

const announcements = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    setAnnouncements: (state, action) => {
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp
    },

  },
});

//actions
export const {
    setAnnouncements,
} = announcements.actions;

//selectors
export const selectAnnouncements = (state) => state.announcements.docs;

export default announcements.reducer;
