import React, { useRef, useCallback, useEffect, useState } from "react";
import {
  Layout,
  Table,
  Input,
  Button,
  Space,
  Modal,
  Form,
  InputNumber,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  DownloadOutlined,
  SearchOutlined,
  ExclamationCircleOutlined, PlusOutlined,
} from "@ant-design/icons";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseApp";
import { useDispatch, useSelector } from "react-redux";
import {
  addContents,
  deleteContents,
  getContents,
  selectContents,
  updateContents,
} from "../redux/reducers/contents";
import { Link } from "react-router-dom";
import { Excel } from "antd-table-saveas-excel";
import FileManager from "./FileManager";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { async } from "@firebase/util";
import ThumbnailContainer from "./ThumbnailContainer";
import { getFilesByName, selectFilesByName } from "../redux/reducers/files";

const { Content } = Layout;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const Contents = () => {
  const dispatch = useDispatch();
  const selectedFiles = useSelector(selectFilesByName);
  const contents = useSelector(selectContents);
  const formAddRef = useRef(null);
  const formModRef = useRef(null);
  const fileAddRef = useRef(null);
  const fileModRef = useRef(null);

  const [visibleAdd, setVisibleAdd] = useState(null);
  const [visibleMod, setVisibleMod] = useState(null);

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [hashtag, setHashtag] = useState("");
  const [thumb, setThumb] = useState("");
  const [files, setFiles] = useState([]);

  // 콘텐츠 데이터 가져오기
  useEffect(() => {
    dispatch(getContents());
  }, []);

  // 수정 버튼을 눌러 썸네일 이름이 바뀔때마다 이미지 데이터 가져오기
  useEffect(() => {
    dispatch(getFilesByName({ name: thumb }));
  }, [thumb]);

  // 요청한 이미지 데이터 적용
  useEffect(() => {
    setFiles(selectedFiles);
  }, [selectedFiles]);

  const showAddModal = () => {
    setVisibleAdd(true);
  };
  const showModModal = async () => {
    setVisibleMod(true);
  };
  const hideModal = () => {
    resetVars();
    setVisibleMod(false);
    setVisibleAdd(false);
  };

  const resetVars = () => {
    setId("");
    setTitle("");
    setLink("");
    setHashtag("");
    setThumb("");
  };

  const addContent = async (value) => {
    // 파일 등록 후 이미지 네임 가져오기(이미지 한개 등록)
    const idList = await fileAddRef.current.addFiles();
    let thumb = "";
    let uri = "";
    for (const idListKey in idList) {
      const data = idList[idListKey];
      thumb = data.name;
      uri = data.uri;
    }
    //
    // contents 데이터 저장
    const { title, link, hashtag } = value;
    dispatch(addContents({ title, link, hashtag, thumb, uri }));
  };

  const updateContent = async (data) => {
    // 파일 등록 후 이미지 네임 가져오기(이미지 한개 등록)
    const idList = await fileModRef.current.addFiles();
    let thumb = "";
    let uri = "";
    for (const idListKey in idList) {
      const data = idList[idListKey];
      thumb = data.name;
      uri = data.uri;
    }
    //
    dispatch(updateContents({ id, data: { ...data, thumb, uri } }));
  };

  const deleteConfirm = () => {
    Modal.confirm({
      title: "해당 콘텐츠를 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        dispatch(deleteContents({ id }));
        hideModal();
      },
    });
  };

  // const getURL = (_id) => {
  //   if (contents[_id] !== undefined && contents[_id].data().thumb !== "temp") {
  //     const storage = getStorage();
  //     getDownloadURL(ref(storage, contents[_id].data().thumb))
  //       .then((_url) => {
  //         console.log("url:", _url);
  //         document.getElementById(_id).src = _url;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };

  const columns = [
    {
      title: "No.",
      key: "order",
      width: "1%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "썸네일",
      dataIndex: "thumb",
      width: "15%",
      render: (thumb, record, index) => {
        return <ThumbnailContainer key={index} name={thumb} />;
      },
    },
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      width: "25%",
    },
    {
      title: "링크",
      dataIndex: "link",
      key: "link",
      width: "25%",
    },
    {
      title: "해시태크",
      dataIndex: "hashtag",
      key: "hashtag",
      width: "25%",
    },
    {
      title: "날짜",
      dataIndex: "create",
      key: "create",
      width: "7%",
    },
    {
      title: "비고",
      dataIndex: "active",
      key: "active",
      align: "center",
      width: "5%",
      render: (text, record, index) => {
        // console.log("record.thumb: " + record.thumb);
        // console.log("contents.thumb: " + contents[record.id].thumb);

        return (
          <Button
            type="primary"
            ghost
            onClick={() => {
              setId(record.id);
              setTitle(record.title);
              setLink(record.link);
              setHashtag(record.hashtag);
              setThumb(record.thumb);
              showModModal();
            }}
          >
            수정
          </Button>
        );
      },
      excelRender: () => {
        return;
      },
    },
  ];
  return (
    <Content style={{ margin: "0 16px", height: "100%" }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, height: "100%" }}
      >
        <Button
          icon={<PlusOutlined/>}
          type="primary"
          style={{ position: "absolute", right: "40px" }}
          onClick={showAddModal}
        >
          콘텐츠 추가
        </Button>
        <Table
          columns={columns}
          dataSource={contents}
          style={{ marginTop: "50px" }}
          rowKey={(record) => record.id}
        />

        {/* 추가하기 모달 */}
        <Modal
          visible={visibleAdd}
          onOk={() => {
            formAddRef.current
              .validateFields()
              .then((values) => {
                formAddRef.current.resetFields();
                addContent(values);
                hideModal();
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
          onCancel={hideModal}
          destroyOnClose
          okText="추가"
          cancelText="취소"
        >
          <p style={{ fontWeight: "600", fontSize: "16px" }}>콘텐츠 추가</p>
          <Form ref={formAddRef} {...layout} name="nest-messages">
            <Form.Item
              name={"title"}
              label="콘텐츠 제목"
              rules={[{ required: true, message: "콘텐츠 제목을 입력하세요." }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"link"}
              label="링크"
              rules={[{ required: true, message: "링크를 입력하세요." }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"hashtag"}
              label="해시태그"
              rules={[{ required: true, message: "해시태그를 입력하세요." }]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item name={'upload'} label="썸네일" rules={[{ required: false }]}> */}
            <FileManager ref={fileAddRef} path={"contents"} max={1} />
            {/* </Form.Item> */}
          </Form>
        </Modal>

        {/* 수정 모달 */}
        <Modal
          visible={visibleMod}
          onCancel={hideModal}
          destroyOnClose
          footer={[
            <Button
              key="delete"
              type="primary"
              danger
              onClick={deleteConfirm}
              style={{ position: "absolute", left: "20px" }}
            >
              삭제
            </Button>,
            <Button key="cancel" onClick={hideModal}>
              취소
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                formModRef.current
                  .validateFields()
                  .then((values) => {
                    formModRef.current.resetFields();
                    updateContent(values);
                    hideModal();
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              수정
            </Button>,
          ]}
        >
          <p style={{ fontWeight: "600", fontSize: "16px" }}>콘텐츠 수정</p>
          <Form
            ref={formModRef}
            {...layout}
            name="nest-messages"
            fields={[
              {
                name: ["title"],
                value: title,
              },
              {
                name: ["link"],
                value: link,
              },
              {
                name: ["hashtag"],
                value: hashtag,
              },
              {
                name: ["thumb"],
                value: thumb,
              },
            ]}
          >
            <Form.Item
              name={"title"}
              label="콘텐츠 제목"
              rules={[{ required: true, message: "콘텐츠 제목을 입력하세요." }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"link"}
              label="링크"
              rules={[{ required: true, message: "링크를 입력하세요." }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"hashtag"}
              label="해시태그"
              rules={[{ required: true, message: "해시태그를 입력하세요." }]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item name={'upload'} label="썸네일" rules={[{ required: false }]}> */}
            <FileManager
              ref={fileModRef}
              path={"contents"}
              max={1}
              files={files}
            />
            {/* </Form.Item> */}
          </Form>
        </Modal>
      </div>
    </Content>
  );
};

export default Contents;
