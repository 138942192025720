import React, { useEffect, useState, useCallback } from "react";
import { Modal, List, Spin } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersSubCollection,
  selectUsers,
  getUsers,
} from "../redux/reducers/users";
import { selectShops } from "../redux/reducers/shops";
import { db } from "../FirebaseApp";
import { doc, deleteDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";

const UserShops = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);

  const [datas, setDatas] = useState([]);

  const [id, setID] = useState("");
  const [loading, setLoading] = useState(true);

  const updateData = useCallback(
    (data) => {
      let newData = [];
      setID(props.id);
      const user = users[props.id];

      if (user !== null) {
        if (shops !== null) {
          user.shops.forEach((shop) => {
            const userShops = shops[shop.id];

            if (userShops !== undefined) {
              newData.push({
                id: userShops.data().id,
                name: userShops.data().name,
              });
            }
          });
        }
      }

      setDatas(newData);
      setLoading(false);
    },
    [props, shops, users]
  );

  useEffect(() => {
    async function loadSubCollection() {
      const user = users ? users[props?.id] : null;
      if (user !== null) {
        setLoading(true);
        if (user.shops === null) {
          const action = await dispatch(
            getUsersSubCollection({ id: props.id, subCollection: "shops" })
          );
          updateData(action.payload);
        } else {
          updateData(user.shops);
        }
      }
    }
    loadSubCollection();
  }, [dispatch, props, updateData, users]);

  const deleteConfirm = (shopID) => {
    Modal.confirm({
      title: "찜한 가게를 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        const docRef = doc(db, "users", id, "shops", shopID);
        await deleteDoc(docRef);
        dispatch(getUsers());
        history.push({ pathname: "/user/info/" + id });
      },
    });
  };

  return (
    <div
      className="site-layout-background"
      style={{ padding: 40, height: "100%" }}
    >
      <Spin tip="Loading..." spinning={loading}>
        <List
          style={{ marginTop: 30 }}
          size="large"
          bordered
          dataSource={datas}
          renderItem={(item) => (
            <List.Item>
              {item.name}
              <DeleteOutlined
                style={{
                  position: "absolute",
                  right: "10px",
                  color: "#F5222D",
                }}
                onClick={() => deleteConfirm(item.id)}
              />
            </List.Item>
          )}
        ></List>
      </Spin>
    </div>
  );
};

export default UserShops;
