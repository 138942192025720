import { React, useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Highlighter from 'react-highlight-words';
import { Table, Input, Button, Space, Tag, Spin, Modal } from 'antd';
import {DownloadOutlined, SearchOutlined, ExclamationCircleOutlined, FileExcelOutlined} from "@ant-design/icons";
import { delBalances, getBalances, getBalancesByUid, selectBalances } from "../redux/reducers/balances";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../FirebaseApp";
import { Excel } from "antd-table-saveas-excel";
import axios from 'axios';
import firebaseConfig from "../firebaseConfig";
//import { Link } from "react-router-dom";

const { Search } = Input;

const Payments = (props) => {
  //console.log("Payments props:", props);
  const balances = useSelector(selectBalances);

  const [datas, setDatas] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);

  let searchInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const updateData = useCallback(() => {
    if(balances === null) {
      if ( props.uid !== null && props.uid !== undefined ) dispatch(getBalancesByUid(props.uid));
      else dispatch(getBalances());
    }
    let newData = [];
    for (const balanceId in balances) {
      const balance = balances[balanceId];
      const data = balance.data();

      if(data.pgcode!=="bottleclub") {
        let etc = '';
        if (data.isExpired !== undefined && data.isExpired === true) etc = '';
        else if (data.status === 'refund' || data.status === 'expired' || data.status === 'lost') etc = '';
        else etc = balanceId;

        newData.push({
          ...data,
          key: balanceId,
          name: data.user_name,
          etc: etc,
        });
      }

    }
    setDatas(newData);
    setLoading(false);
  }, [props, dispatch, balances]);

  useEffect(() => {
    dispatch(delBalances());
  }, []);

  useEffect(() => {
    updateData();
  }, [updateData]);

  const refund = (id) => {
    Modal.confirm({
      title: '해당 결제내역을 환불처리 하겠습니까?',
      icon: <ExclamationCircleOutlined style={{ color: "#F5222D" }}/>,
      content: '',
      okText: '환불',
      okType: 'danger',
      cancelText: '취소',
      async onOk() {
        //  client_id: 'bfactory1', // 네이버페이
        //  client_id: 'bfactory', // 나머지
        let clientId = 'bfactory';
        if (
          balances[id].data().pgcode === 'navercard' ||
          balances[id].data().pgcode === 'naverpoint'
        ) clientId = 'bfactory1';

        const pgCode = (balances[id].data().pgcode==="navercard" ||
        balances[id].data().pgcode==="naverpoint") ? "naverpay" : balances[id].data().pgcode;

        let data = {
          pgcode: pgCode,
          client_id: clientId,
          user_id: id,
          tid: balances[id].data().tid,
          amount: balances[id].data().amount,
          ip_addr: "127.0.0.1"
        };
        //console.log("Request data: ", data);
        const host = firebaseConfig.middleHost; // 미들웨어
        const post = 'billing/refund'; // 결제 취소 라우트
        axios.post(host+post, data, {
          headers: {
            "Content-Type": 'application/json',
          }
        })
        .then((res) => {
          console.log(res);
          if ( res.data.tid !== undefined ) {
            const docRef = doc(db, "balances", id);
            updateDoc(docRef, {
              refund_tid: res.data.tid,
              refund_cid: res.data.cid,
              refund_date: res.data.cancel_date,
              status: 'refund',
            });
            dispatch(getBalances());
            alert("정상 처리 되었습니다.")
          } else if ( res.data.code !== undefined ) {
            console.log("Failed code: " + res.data.code, "Message: " + res.data.message);
            alert("Failed code: " + res.data.code + ", Message: " + res.data.message);
          }
        })
        .catch(function (err) {
          console.log(err);
        });   
      }
    });
  }

  const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };  

  const columns = [
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filters: [
        { text: '대여중', value: 'rent' }, // 사용중(대여중 rent, 충전 완료 charge)
        { text: '사용중', value: 'charge' }, // 사용중(대여중 rent, 충전 완료 charge)
        { text: '환불 요청중', value: 'requestRefunding' },
        { text: '환불 완료', value: 'refund' },
        { text: '분실', value: 'lost' },
        { text: '기간 만료', value: 'expired' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        let _a = '';
        let _b = '';
        if (a.status==='rent') _a = 'charge';
        else _a = a.status;
        if (b.status==='rent') _b = 'charge';
        else _b = b.status;
        if (_a>_b) return 1;
        if (_a<_b) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
      render: (status)=> {
        // 사용중, 기간 만료, 환불 완료
        let color = '';
        let key = '';
        let statusTxt = '';
        if (status === 'rent') {
          statusTxt = '대여중';
          color = 'blue';
          key = status;
        } else if (status === 'charge') {
          statusTxt = '사용중';
          color = 'blue';
          key = status;
        } else if (status === 'requestRefunding') {
          statusTxt = '환불 요청';
          color = 'red';
          key = status;
        } else if (status === 'refund') {
          statusTxt = '환불 완료';
          color = 'default';
          key = status;
        } else if (status === 'expired') {
          statusTxt = '기간 만료';
          color = 'orange';
          key = status;
        } else if (status === 'lost') {
          statusTxt = '분실';
          color = 'purple';
          key = status;
        }
        return (
          <Tag color={color} key={key}>
            {statusTxt}
          </Tag>
        );
      },
      excelRender: (status) => {
        let statusTxt = '';
        if (status === 'rent' || status === 'charge') {
          statusTxt = '사용중';
        } else if (status === 'requestRefunding') {
          statusTxt = '환불 요청';
        } else if (status === 'refund') {
          statusTxt = '환불 완료';
        } else if (status === 'expired') {
          statusTxt = '기간 만료';
        } else if (status === 'lost') {
          statusTxt = '분실';
        }
        return statusTxt;
      }
    },
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      width: "1%",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: '닉네임',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => {
        if (a.name>b.name) return 1;
        if (a.name<b.name) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '고유 결제 번호',
      dataIndex: 'tid',
      key: 'tid',
      ...getColumnSearchProps('tid'),
    },
/*
banktransfer 	인터넷뱅킹(금융결제원)
virtualaccount 	가상계좌
mobile 	휴대폰
book 	도서문화상품권
culture 	문화상품권
smartculture 	스마트문상
happymoney 	해피머니상품권
mobilepop 	모바일팝
teencash 	틴캐시
tmoney 	교통카드결제
cvs 	편의점캐시
eggmoney 	에그머니
oncash 	온캐시
phonebill 	폰빌
cashbee 	캐시비
checkpay 	체크페이
toss 	토스
ssgpay 	SSG페이
lpay 	L.Pay
samsungpay 	삼성페이	
chai 	차이
*/
    {
      title: '결제수단',
      dataIndex: 'pgcode',
      key: 'pgcode',
      sorter: (a, b) => {
        if (a.pgcode>b.pgcode) return -1;
        if (a.pgcode<b.pgcode) return 1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
      render: (pgcode) => {
        if (pgcode === 'creditcard') pgcode = '신용카드';
        else if (pgcode === 'kakaopay') pgcode = '카카오페이';
        else if (pgcode === 'naverpay') pgcode = '네이버페이';
        else if (pgcode === 'payco') pgcode = '페이코';
        return pgcode;
      }
    },
    {
      title: '결제액',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['descend', 'ascend'],
      render: (amount) => { return (amount !== undefined)?new Intl.NumberFormat().format(amount)+'원':""; }
    },
    {
      title: '결제 날짜',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      sorter: (a, b) => Date.parse(a.transaction_date) - Date.parse(b.transaction_date),
      sortDirections: ['descend', 'ascend'],
      render: (transaction_date) => {
        return transaction_date.substring(0, transaction_date.indexOf(' '));
      }
    },
    {
      title: '결제 시간',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      render: (transaction_date) => {
        return transaction_date.substring(transaction_date.indexOf(' '));
      }
    },
    {
      title: '환불 날짜',
      dataIndex: 'refund_date',
      key: 'refund_date',
      sorter: (a, b) => {
        let _a=0, _b=0;
        if (a.refund_date!==undefined) _a = Date.parse(a.refund_date);
        if (b.refund_date!==undefined) _b = Date.parse(b.refund_date);
        return _a - _b;
      },
      sortDirections: ['descend', 'ascend'],
      render: (refund_date) => {
        return (refund_date!==undefined)?refund_date.substring(0, refund_date.indexOf(' ')):'';
      }
    },
    {
      title: '환불 시간',
      dataIndex: 'refund_date',
      key: 'refund_date',
      render: (refund_date) => {
        return (refund_date!==undefined)?refund_date.substring(refund_date.indexOf(' ')):'';
      }
    },
    {
      title: "비고",
      dataIndex: "etc",
      key: "etc",
      align: "center",
      width: "5%",
      render: (etc) => {
        if ( etc !== '' ) {
          return (
            <Button danger onClick={()=>refund(etc)}>환불</Button>
          );
        } else {
          return (
            <Button type="primary" disabled danger>환불</Button>
          );
        }
      }
    },
  ];
  return (
    <div className="site-layout-background" style={{ padding: 24, height: '100%', marginTop: 0 }}>
      <Spin tip="Loading..." spinning={loading} >
        <Button type="primary" ghost icon={<FileExcelOutlined />}
        onClick={() => {
          console.log("columns: ", columns);
          console.log("datas: ", datas)
          const excel = new Excel();
          excel
            .setTHeadStyle({ background: "FFFFFF" })
            .addSheet("결제 목록")
            .addColumns(columns)
            .addDataSource(datas)
            .saveAs("결제 목록.xlsx");
        }} style={{ position:'absolute', right: '0px',}} >다운로드</Button>
        <Table columns={columns} dataSource={datas} style={{ marginTop: '30px' }} rowKey={record => record.id}/>
      </Spin>
    </div>   
  );
}

export default Payments;