import React from "react";
import { Layout, Tabs } from "antd";
import ShopModify from "./ShopModify";
import ShopReviews from "./ShopReviews";
//import ShopRental from "./ShopRental";
import RentalList from "./RentalList";

const { Content } = Layout;
const { TabPane } = Tabs;

const ShopInfo = (props) => {
  const id = props.match.params.id;
  console.log("ShopInfo: ", id);
  return (
    <Content style={{ margin: "0 16px", height: "100%" }}>
      <Tabs onChange={() => {}} type="card">
        <TabPane tab="정보 수정" key="1">
          <ShopModify {...props} />
        </TabPane>
        <TabPane tab="리뷰" key="2">
          <ShopReviews id={id} />
        </TabPane>
        <TabPane tab="대여 목록" key="3">
          <RentalList sid={id} />
        </TabPane>
      </Tabs>
    </Content>
  );
};
export default ShopInfo;
