import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Layout, Space, Spin, Table} from 'antd';
import Highlighter from 'react-highlight-words';
import {FileExcelOutlined, SearchOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {selectDonateHistories} from "../redux/reducers/donate_history";
import {selectUsers} from "../redux/reducers/users";
import {getDonates, selectDonates} from "../redux/reducers/donate";
import {Excel} from 'antd-table-saveas-excel';

const {Content} = Layout;
const reg = /^([0-9]{0,3})[-. ]?([0-9]{0,4})[-. ]?([0-9]{0,4})$/;

const DonateHistory = () => {
  const donateHistories = useSelector(selectDonateHistories);
  const users = useSelector(selectUsers);
  const donates = useSelector(selectDonates);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef < HTMLInputElement > (null);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
    let newData = [];
    if (donateHistories !== null) {
      if (donates === null) {
        dispatch(getDonates())
      }
      for (const donateHistoriesKey in donateHistories) {
        const donateHistory = donateHistories[donateHistoriesKey]

        let nickname = "";
        let mobile = "";
        if (users !== null) {
          if (users[donateHistory.data().uid] !== undefined) {
            nickname = users[donateHistory.data().uid].data().name
            mobile = users[donateHistory.data().uid].data().mobile
          }
        }

        let donateID = "";
        if (donates !== null) {
          if (donates[donateHistory.data().donate_id] !== undefined) {
            donateID = donates[donateHistory.data().donate_id].data().name
          }
        }
        newData.push({
          key: donateHistory.id,
          id: donateHistory.id,
          uid: nickname,
          donateID: donateID,
          coin: donateHistory.data().coin,
          score: donateHistory.data().score,
          create: donateHistory.data().create.toDate(),
          time: donateHistory.data().create.toDate().toLocaleTimeString('it-IT'),
          mobile: mobile.trim().replace(reg, '$2-$3'),
        })
      }
    }

    setDatas(newData)
    setLoading(false)
  }, [donateHistories, users, donates, dispatch]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      width: '1%',
      render: (text, record, index) => {
        return (
          index + 1
        );
      }
    },
    {
      title: '닉네임',
      dataIndex: 'uid',
      key: 'uid',
      ...getColumnSearchProps('uid'),
      sorter: (a, b) => {
        if (a.uid > b.uid) return 1;
        if (a.uid < b.uid) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '회원 번호',
      dataIndex: 'mobile',
      key: 'mobile',
      ...getColumnSearchProps('mobile'),
    },
    {
      title: '기부 이름',
      dataIndex: 'donateID',
      key: 'donateID',
      ...getColumnSearchProps('donateID'),
      sorter: (a, b) => {
        if (a.donateID > b.donateID) return 1;
        if (a.donateID < b.donateID) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '사용 코인',
      dataIndex: 'coin',
      key: 'coin',
      sorter: (a, b) => a.coin - b.coin,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
    },
  ];

  return (
    <Content style={{margin: '0 16px', height: '100%'}}>
      <div className="site-layout-background" style={{padding: 24, height: '100%'}}>
        <Button
          icon={<FileExcelOutlined/>}
          style={{position: 'absolute', left: '240px',}}
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({background: 'FFFFFF',})
              .addSheet('기부 히스토리')
              .addColumns(columns)
              .addDataSource(datas)
              .saveAs('기부 히스토리.xlsx');
          }}
        >
          다운로드
        </Button>
        <Spin tip="Loading..." spinning={loading}>
          <Table columns={columns} dataSource={datas} style={{marginTop: '50px'}} rowKey={record => record.id}/>
        </Spin>
      </div>
    </Content>
  );
}

export default DonateHistory;