import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebaseConfig from "../firebaseConfig";
import { Input, Button } from "antd";
import { withRouter } from "react-router-dom";
import { handleInputPhoneNember } from "../utils/phoneNumber";

firebase.initializeApp(firebaseConfig);

const SignIn = () => {
  const [mobile, setMobile] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [otp, setOtp] = useState("");

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
          console.log("Recaptca varified");
        },
        defaultCountry: "KO",
      }
    );
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    configureCaptcha();
    const phoneNumber = "+82" + mobile;
    console.log(phoneNumber);
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("인증번호 전송 완료");
        setDisabled(false);
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log(error);
        console.log("SMS not sent");
      });
  };

  const onSubmitOTP = (e) => {
    e.preventDefault();
    const code = otp;
    console.log(code);
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        // const user = result.user;
        // this.props.history.push({pathname: '/'});
        // // console.log("로그인 완료 uid:"+JSON.stringify(user.uid));
        // console.log("로그인 완료: "+JSON.stringify(user.phoneNumber));
        // this.setState({uid: JSON.stringify(user.uid)})
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  return (
    <div className="wrapper">
      <img
        src={require("../image/logo.jpg").default}
        style={{
          width: "200px",
          height: "200px",
          borderRadius: 200,
          marginBottom: "80px",
        }}
        alt="보틀클럽 로고"
      />
      <p style={{ width: "320px" }}>전화번호 입력</p>
      <form onSubmit={onSignInSubmit} style={{ width: "320px" }}>
        <div id="sign-in-button"></div>
        <Input
          placeholder="Mobile number"
          name="mobile"
          onChange={(e) => handleInputPhoneNember(e.target.value, setMobile)}
          value={mobile}
        />
        <Button style={{ width: "320px", marginTop: "10px" }} htmlType="submit">
          인증번호 받기
        </Button>
      </form>

      <form
        onSubmit={onSubmitOTP}
        style={{ width: "320px", marginTop: "50px" }}
      >
        <Input
          name="otp"
          placeholder="OTP Number"
          maxLength="6"
          onChange={(e) => {
            setOtp(e.target.value);
          }}
        />
        {/* <Button style={{width: '320px'}} type="primary" htmlType="submit" >인증하기</Button> */}
        <Button
          style={{ width: "320px", marginTop: "10px" }}
          type="primary"
          htmlType="submit"
          disabled={disabled}
        >
          인증하기
        </Button>
      </form>
    </div>
  );
};
export default withRouter(SignIn);
