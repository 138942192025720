import React, { Component } from 'react';
import { Layout, Table, Input, Button, Space, Tag, Modal, Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import RentalList from "./RentalList";
import PaymentList from "./PaymentList";
//import BottleclubRental from "./BottleclubRental";
//import BottleclubPayment from "./BottleclubPayment";
import { getRents, getRentsByUid, getRentsBySid, selectRents, deleteRents } from "../redux/reducers/rents";
import { useDispatch, useSelector } from "react-redux";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;
const onSearch = value => console.log(value);

function confirm() {
    Modal.confirm({
      title: '해당 보틀클럽 기록을 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '삭제',
      cancelText: '취소',
    });
}

function callback(key) {
    //console.log(key);
}

const data = [
    {
      key: '1',
      name: 'John Brown',
      phoneNumber: 32,
      tags: '대여중',
      score: 100,
      points: 5000,
      date: '21.08.30',
      time: '13:56:08',
      region: 'New York No. 1 Lake Park',
      ranking: 2,
    },
    {
      key: '2',
      name: 'Joe Black',
      phoneNumber: 42,
      tags: '기한 만료',
      score: 255,
      points: 7523,
      region: 'London No. 1 Lake Park',
      ranking: 1,
    },
    {
      key: '3',
      name: 'Jim Green',
      phoneNumber: 32,
      tags: '반납 완료',
      score: 255,
      points: 7523,
      region: 'Sidney No. 1 Lake Park',
      ranking: 1,
    },
    {
      key: '4',
      name: 'Jim Red',
      phoneNumber: 32,
      tags: '반납 완료',
      score: 80,
      points: 1000,
      region: 'London No. 2 Lake Park',
      ranking: 4,
    },
  ];

class Bottleclub extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    tags: [],
  };

  //componentDidMount() {
  //  console.log("Bottleclub mounted");
  //}

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '', });
  };

  render() {
    return (
        <Content style={{ margin: '0 16px', height: '100%' }}>
            <Tabs onChange={callback} type="card">
                <TabPane tab="대여 목록" key="7">
                  <RentalList />
                </TabPane>
                <TabPane tab="결제 목록" key="8">
                  <PaymentList />
                </TabPane>
            </Tabs>
        </Content>
    );
}
}

export default Bottleclub;
