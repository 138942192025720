import { Layout, Spin } from 'antd';

const Loading = () => {
  return (
    <div style={{position:"absolute",top:0,left:0,right:0,bottom:0}}>
      <div style={{textAlign:"center", width:"100px", height:"100px", margin:"auto", marginTop:200}}>
        <Spin tip="Loading..."/>
      </div>
    </div>
  );
}

export default Loading;