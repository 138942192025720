import React, {useEffect, useImperativeHandle, useState} from 'react';
import {message, Upload} from "antd";
import {getAuth} from "firebase/auth";
import {collection, deleteDoc, doc, getDoc, getDocs, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "../FirebaseApp";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import Compressor from "compressorjs";
import {v4 as uuidv4} from "uuid";

const FileManager = React.forwardRef((props, fowardRef) => {

  const {path, max, files} = props
  const [fileList, setFileList] = useState([])

  useImperativeHandle(fowardRef, () => ({
    addFiles,
    deleteAll,
  }))

  useEffect(() => {
    if (files) updateFiles()
  }, [files])

  const updateFiles = async () => {
    let temp = []
    const getFiles = await getDocs(files);
    // 파일 업데이트
    for (const file of getFiles.docs) {
      const docRef = doc(db, "files", file.id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const data = docSnap.data()
        const file = {
          key: data.id,
          uid: data.id,
          name: data.name,
          status: "done",
          url: data.uri,
          uri: data.uri,
        };
        temp.push(file)
      } else {
        console.log("No such document!");
      }
    }
    console.log(temp)
    setFileList(temp)
  }

  const addFiles = async () => {
    const user = getAuth().currentUser;
    let idList = [];
    for (const fileListKey in fileList) {
      const id = await addFile(user, fileList[fileListKey]);
      idList.push(id);
    }
    return idList;
  }

  const addFile = async (user, file) => {
    console.log("addFile user: ", user);
    if (file.xhr === undefined) {
      // files에 등록된 파일
      return {id: file.uid, name: file.name, uri: file.uri};
    } else {
      // 신규 파일
      console.log("=== add to db ===");
      console.log(file);
      const docRef = doc(collection(db, "files"));
      const id = docRef.id;
      const uid = user.uid;
      const type = file.type;
      const name = file.xhr.metadata.fullPath;
      //
      const storage = getStorage();
      const uri = await getDownloadURL(ref(storage, name));
      //
      await setDoc(docRef, {
        id,
        uid,
        type,
        name,
        uri,
        create: serverTimestamp(),
      });
      //
      return {id, name, uri};
    }
  }

  const onChange = ({fileList: newFileList}) => {
    let files = [];
    for (const newFileListKey in newFileList) {
      const file = newFileList[newFileListKey];
      if (file.status !== undefined) files.push(file);
    }

    setFileList(files)
  }

  const onPreview = async (file) => {
    let src = file.url ?? file.thumbUrl;
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  }

  const deleteAll = async () => {

    console.log("%c delete all", "color: cyan")
    console.log(files)

    if (files && files.length > 0) {
      const fileDocs = await getDocs(files);
      for (const file of fileDocs) {
        await deleteDoc(doc(db, "files", file.id))
      }
    }
  }

  const onRemove = async (file) => {
    const storage = getStorage();
    let path = "";
    if (file.xhr === undefined) {
      // files에 등록된 파일
      path = file.name;
      if (!path.includes("contents/")) await deleteDoc(doc(files, file.uid)); // 문서의 파일 삭제
      await deleteDoc(doc(db, "files", file.uid)); // files 컬렉션의 파일 삭제
    } else {
      // 신규 파일
      path = file.xhr.metadata.fullPath;
    }

    const deleteRef = ref(storage, path);
    deleteObject(deleteRef)
      .then(() => {
        console.log("success remove: " + path);
      })
      .catch((error) => {
      });
  }

  const beforeUpload = (file) => {
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("이미지 파일만 업로드 가능합니다.");
    }
    // You can remove this validation if you want
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("용량은 5Mb를 넘길 수 없습니다.");
    }

    return isImage && isLt5M;
  }

  const customUpload = async ({onError, onSuccess, file}) => {
    //console.log("1fileList: ", state.fileList);
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      maxWidth: 1024,
      maxHeight: 1024,
      success: async (compressedResult) => {
        const metadata = {
          contentType: compressedResult.type,
        };
        const ext = compressedResult.name.split(".").pop();
        const imagePath = `${path}/${uuidv4()}.${ext}`; //a unique name for the image
        const storage = getStorage();
        const imgRef = ref(storage, imagePath);
        try {
          const image = await uploadBytes(imgRef, compressedResult, metadata);
          onSuccess(null, image);
        } catch (e) {
          onError(e);
        }
      },
    });
  }

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        customRequest={customUpload}
        onRemove={onRemove}
      >
        {(max && fileList.length < max) && "+ Upload"}
      </Upload>
      <span>
          Max: {fileList.length}/{!max ? 0 : max}
        </span>
    </div>
  );
});

export default FileManager;