import React, { useEffect, useState, useRef } from 'react';
import { Layout, Table, Input, Button, Space, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { selectGoodsHistories } from "../redux/reducers/goods_history";
import { selectUsers } from "../redux/reducers/users";
import { selectGoods } from "../redux/reducers/goods";
import { Excel } from 'antd-table-saveas-excel';

const { Content } = Layout;

const BranchGoodsHistory = (props) => {
  const goodsHistories = useSelector(selectGoodsHistories);
  const users = useSelector(selectUsers);
  const goods = useSelector(selectGoods);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let newData = [];
    if(goodsHistories !== null) {
      for (const goodsHistoriesKey in goodsHistories) {
        const goodsHistory = goodsHistories[goodsHistoriesKey]
        const shopID = props.location.state.shopid;

        if (goods[goodsHistory.data().goods_id] !== undefined) {
          const goodsID = goodsHistory.data().goods_id
          if (goods[goodsID].data().shop_id === shopID) {
            let nickname = "";
            if(users !== null) {  
              if (users[goodsHistory.data().uid] !== undefined) {
                nickname = users[goodsHistory.data().uid].data().name
              }
            }
              
            newData.push({
              key: goodsHistory.id,
              id: goodsHistory.id,
              uid: nickname,
              goodsName: goods[goodsID].data().name,
              current: goods[goodsID].data().current,
              score: goodsHistory.data().score,
              create: goodsHistory.data().create.toDate(),
              time: goodsHistory.data().create.toDate().toLocaleTimeString('it-IT'),
            })
          }
        }
      }
    }

    setDatas(newData)
    setLoading(false)
  }, [goodsHistories, users, goods, props]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  }; 

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      width: '1%',
      render: (text, record, index) => {
        return (
          index + 1
        );
      }
    },
    {
      title: '닉네임',
      dataIndex: 'uid',
      key: 'uid',
      ...getColumnSearchProps('uid'),
      sorter: (a, b) => a.uid.length - b.uid.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '상품 이름',
      dataIndex: 'goodsName',
      key: 'goodsName',
      ...getColumnSearchProps('goodsName'),
      sorter: (a, b) => a.goodsName.length - b.goodsName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '남은 재고',
      dataIndex: 'current',
      key: 'current',
      sorter: (a, b) => a.current - b.current,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
    },
  ];
  return (
    <Content style={{ margin: '0 16px', height: '100%' }}>
      <div className="site-layout-background" style={{ padding: 24, height: '100%' }}>
      {/* 
        <Button icon={<DownloadOutlined />} style={{ position:'absolute', right: '40px',}} 
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({ background: 'FFFFFF', })
              .addSheet('구매 히스토리')
              .addColumns(columns)
              .addDataSource(datas)
              .saveAs('구매 히스토리.xlsx');
          }}
        >
          다운로드</Button>
        */}
        <Spin tip="Loading..." spinning={loading} >
          <Table columns={columns} dataSource={datas} style={{ marginTop: '50px' }} rowKey={record => record.id}/>
        </Spin>
      </div>
    </Content>
  );
}

export default BranchGoodsHistory;