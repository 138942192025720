import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query, where, limit } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getRents = createAsyncThunk(
  "getRents",
  async (params, { dispatch, getState }) => {
    console.log("call getRents")
    const rentsRef = collection(db, "rents");
    const q = query(rentsRef, orderBy("rented_date", "desc"));
    const rentsSnap = await getDocs(q)
    dispatch(setRents(rentsSnap));
  }
);

export const getRentsByUid = createAsyncThunk(
  "getRentsByUid",
  async (params, { dispatch, getState }) => {
    //console.log("call getRents")
    //console.log("rents params User id: ", params);
    const rentsRef = collection(db, "rents");
    const q = query(rentsRef, where('uid', '==', params), orderBy("rented_date", "desc"));
    const rentsSnap = await getDocs(q)
    dispatch(setRents(rentsSnap));
  }
);

export const getRentsBySid = createAsyncThunk(
  "getRentsBySid",
  async (params, { dispatch, getState }) => {
    //console.log("call getRents")
    //console.log("rents params Shop id: ", params);
    const rentsRef = collection(db, "rents");
    const q = query(rentsRef, where('rented_shop_id', '==', params), orderBy("rented_date", "desc"));
    const rentsSnap = await getDocs(q)
    dispatch(setRents(rentsSnap));
  }
);

const initialState = {
  docs: null,
};

const rents = createSlice({
  name: "rents",
  initialState,
  reducers: {
    setRents: (state, action) => {
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp;
    },
    delRents: (state, action) => {
      state.docs = null;
    }
  },
});

//actions
export const { setRents, delRents } = rents.actions;

//selectors
export const selectRents = (state) => state.rents.docs;

export default rents.reducer;
