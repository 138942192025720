import React, { useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const ThumbnailContainer = ({ name, width, height }) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (name) {
      getDownloadURL(ref(getStorage(), name))
        .then((url) => {
          setUrl(url);
        })
        .catch((err) => console.log(err));
    }
  }, [name]);

  if (!name || url === "")
    return (
      <div
        style={{
          width: width ?? 75,
          height: height ?? 75,
        }}
      ></div>
    );
  return (
    <img
      style={{
        width: width ?? 75,
        height: height ?? 75,
        objectFit: "cover",
        backgroundColor: "#C4C4C4",
      }}
      src={url}
    />
  );
};

export default ThumbnailContainer;
