import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Typography } from "antd";
import { RestOutlined, UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import {selectUsers} from "../redux/reducers/users";
import { selectShops, getBranch } from "../redux/reducers/shops";
// import {selectItems} from "../redux/reducers/items";
import { selectRents, getRentsBySid } from "../redux/reducers/rents";
import firebaseConfig from "../firebaseConfig";

const { Content } = Layout;
const { Title } = Typography;
const month = new Date().getMonth() + 1;

const BranchDashboard = (props) => {
  const dispatch = useDispatch();
  const shops = useSelector(selectShops);
  const rents = useSelector(selectRents);
  const [shopStat, setShopStat] = useState(null);
  const [stock, setStock] = useState(0);
  const [totalCollect, setTotalCollect] = useState(0);
  const [monthCollect, setMonthCollect] = useState(0);
  const [totalCollectItems, setTotalCollectItems] = useState([]);
  const [monthCollectItems, setMonthCollectItems] = useState([]);

  const cupItemId = firebaseConfig.itemCupId;

  useEffect(() => {
    dispatch(getBranch({ id: props.shopid }));
  }, []);

  useEffect(() => {
    if (rents === null) {
      dispatch(getRentsBySid(props.shopid));
    } else {
      let temp = {
        total: 0,
        unreturned: 0,
        total_returned: 0,
        other_branch_returned: 0,
      };
      for (const rid in rents) {
        temp.total++;
        if (rents[rid].data().returned_shop_id === undefined) temp.unreturned++;
        if (rents[rid].data().status === "return") temp.total_returned++;
        if (
          rents[rid].data().returned_shop_id !== undefined &&
          props.shopid !== rents[rid].data().returned_shop_id
        )
          temp.other_branch_returned++;
      }
      setShopStat(temp);
    }

    if (shops?.savingsTotal?.data()) {
      console.log("shops.savingsTotal" + shops.savingsTotal.data()[cupItemId]);
      /*           const totalCollectValue = Object.values(shops.savingsTotal.data())
          let countTotal = 0;
          for (let i = 0; i < totalCollectValue.length; i++) {
            countTotal = countTotal + totalCollectValue[i]
          }
          setTotalCollect(countTotal) */

      const totalCollectValue = shops.savingsTotal.data()[cupItemId];
      setTotalCollect(totalCollectValue);
      //setTotalCollectItems(shops.savingsTotal[rsioVX3qv0VX2AF3AlFJ].data())

      if (shops.data().current_stock !== undefined)
        setStock(shops.data().current_stock);
    }

    if (shops?.savingsMonth?.data()) {
      // setMonthCollectItems(monthStatisticsSnap.data())
      // const monthCollectValue = Object.values(monthStatisticsSnap.data())
      let countMonth = 0;
      /* let monthCollect = {}
          for (let i = 0; i < Object.keys(shops.savingsMonth.data()).length; i++) {
            countMonth = countMonth + Object.values(shops.savingsMonth.data())[i][month] //월 적립수

            const key =  Object.keys(shops.savingsMonth.data())[i]
            monthCollect={
              ...monthCollect,
              [key]: Object.values(shops.savingsMonth.data())[i][month]
            }
          } */
      countMonth = shops.savingsMonth.data()[cupItemId][month];
      //setMonthCollect(totalCollectValue);
      setMonthCollect(countMonth); //월 적립수
      /* setMonthCollectItems(monthCollect) // {아이템id: 월 적립수} obj */
    }
  }, [shops, props, dispatch, rents]);
  return (
    <>
      <Row style={{ marginLeft: "20px" }}>
        <Col>
          <Row justify="start" align="middle">
            <Content style={{ margin: "40px 16px 0", width: 305, height: 273 }}>
              <div
                className="site-layout-background"
                style={{ padding: 40, height: "100%" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <UserOutlined
                    style={{
                      width: 50,
                      height: 50,
                      fontSize: "50px",
                      color: "#8c8c8c",
                    }}
                  />
                  <Title
                    level={3}
                    style={{
                      position: "relative",
                      top: 0,
                      left: 50,
                      margin: 0,
                    }}
                  >
                    이용자 수
                  </Title>
                </div>
                <Row justify="space-around" style={{ textAlign: "left" }}>
                  <Col span={12}>
                    <p style={{ marginTop: "70px", fontSize: "16px" }}>
                      줄인 일회용컵 수 (Month)
                    </p>
                    <p style={{ fontSize: "36px", fontWeight: "900" }}>
                      {new Intl.NumberFormat().format(monthCollect)}
                    </p>
                  </Col>
                  <Col span={12}>
                    <p style={{ marginTop: "70px", fontSize: "16px" }}>
                      줄인 일회용컵 수 (Total)
                    </p>
                    <p style={{ fontSize: "36px", fontWeight: "900" }}>
                      {new Intl.NumberFormat().format(totalCollect)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Content>
          </Row>

          <Row justify="start" align="middle">
            <Col>
              <Content
                style={{ margin: "40px 16px 0", width: 305, height: 273 }}
              >
                <div
                  className="site-layout-background"
                  style={{ padding: 40, height: "100%" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RestOutlined
                      style={{
                        width: 50,
                        height: 50,
                        fontSize: "50px",
                        color: "#8c8c8c",
                      }}
                    />
                    <Title
                      level={3}
                      style={{
                        position: "relative",
                        top: 0,
                        left: 50,
                        margin: 0,
                      }}
                    >
                      반납 현황
                    </Title>
                  </div>
                  <Row justify="space-around">
                    <Col span={12} style={{ textAlign: "left" }}>
                      <p style={{ marginTop: "70px", fontSize: "16px" }}>
                        미반납
                      </p>
                      <p style={{ fontSize: "30px", fontWeight: "900" }}>
                        {shopStat !== null
                          ? new Intl.NumberFormat().format(shopStat.unreturned)
                          : 0}
                      </p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <p style={{ marginTop: "70px", fontSize: "16px" }}>
                        컵재고
                      </p>
                      <p style={{ fontSize: "30px", fontWeight: "900" }}>
                        {new Intl.NumberFormat().format(stock)}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Col>
            <Col>
              <Content
                style={{ margin: "40px 16px 0", width: 660, height: 273 }}
              >
                <div
                  className="site-layout-background"
                  style={{ padding: 40, height: "100%" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RestOutlined
                      style={{
                        width: 50,
                        height: 50,
                        fontSize: "50px",
                        color: "#8c8c8c",
                      }}
                    />
                    <Title
                      level={3}
                      style={{
                        position: "relative",
                        top: 0,
                        left: 50,
                        margin: 0,
                      }}
                    >
                      반납 완료
                    </Title>
                  </div>
                  <Row justify="space-around">
                    <Col span={11} style={{ textAlign: "left" }}>
                      <p style={{ marginTop: "70px", fontSize: "16px" }}>
                        누적 반납 완료
                      </p>
                      <p style={{ fontSize: "30px", fontWeight: "900" }}>
                        {shopStat !== null
                          ? new Intl.NumberFormat().format(
                              shopStat.total_returned
                            )
                          : 0}
                      </p>
                    </Col>
                    <Col span={5} style={{ textAlign: "left" }}>
                      <p style={{ marginTop: "70px", fontSize: "16px" }}>
                        반납률
                      </p>
                      <p style={{ fontSize: "30px", fontWeight: "900" }}>
                        {shopStat !== null && shopStat.total > 0
                          ? (
                              (shopStat.total_returned / shopStat.total) *
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </p>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <p style={{ marginTop: "70px", fontSize: "16px" }}>
                        타지점 반납률
                      </p>
                      <p style={{ fontSize: "30px", fontWeight: "900" }}>
                        {shopStat !== null && shopStat.total_returned > 0
                          ? (
                              (shopStat.other_branch_returned /
                                shopStat.total_returned) *
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BranchDashboard;
