import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getDonateHistories = createAsyncThunk(
  "getDonateHistories",
  async (params, { dispatch, getState }) => {
    console.log("call getDonateHistories")
    const donateHistoriesRef = collection(db, "donate_history")
    const q = query(donateHistoriesRef, orderBy("create", "desc"));
    const donateHistoriesSnap = await getDocs(q)
    dispatch(setDonateHistories(donateHistoriesSnap));
  }
);

const initialState = {
  docs: null,
};

const donateHistories = createSlice({
  name: "donateHistories",
  initialState,
  reducers: {
    setDonateHistories: (state, action) => {
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp
    },

  },
});

//actions
export const {
    setDonateHistories,
} = donateHistories.actions;

//selectors
export const selectDonateHistories = (state) => state.donateHistories.docs;

export default donateHistories.reducer;
