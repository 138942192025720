import React, {useCallback, useEffect, useState} from "react";
import {Button, Cascader, DatePicker, Descriptions, Form, Input, Layout, Modal, Row, Space,} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import {collection, deleteDoc, doc, getDocs, serverTimestamp, setDoc, updateDoc,} from "firebase/firestore";
import {db} from "../FirebaseApp";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getAnnouncements, selectAnnouncements,} from "../redux/reducers/announcement";
import FileManager from "./FileManager";

const {Content} = Layout;
const {RangePicker} = DatePicker;
const dateFormat = "YYYY-MM-DD";

const typeList = [
  {label: "팝업", value: "popup"},
  {label: "배너", value: "banner"},
];

const AnnouncementInfo = (props) => {
  const dispatch = useDispatch();
  const formRef = React.createRef(null);
  const fileRef = React.createRef(null);
  const announcements = useSelector(selectAnnouncements);
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [files, setFiles] = useState(null);
  const [type, setType] = useState("");
  const id = props.match.params.id;

  const updateData = useCallback(() => {
    if (announcements === null) return;
    const announcement = announcements[id];
    if (announcement === undefined) {
      history.push({pathname: "/announcement"});
      return;
    }

    const docRef = doc(db, "announcement", id);
    const filesRef = collection(docRef, "files");
    setFiles(filesRef);

    setTitle(announcement.data().title);
    setLink(announcement.data().link);
    setStart(announcement.data().start.toDate());
    setEnd(announcement.data().end.toDate());
    setType(announcement.data().type);
  }, [announcements, history, id]);

  useEffect(() => {
    if (announcements === null) {
      // history.push({pathname: '/announcement'});
      dispatch(getAnnouncements());
      updateData();
    } else {
      updateData();
    }
  }, [announcements, dispatch, updateData]);

  const modifyConfirm = (value) => {
    Modal.confirm({
      title: "공지사항을 수정하시겠습니까?",
      icon: <ExclamationCircleOutlined/>,
      content: "",
      okText: "수정",
      cancelText: "취소",
      async onOk() {
        const idList = await fileRef.current.addFiles();
        let name = "";
        let uri = "";
        if (idList.length > 0) {
          name = idList[0].name;
          uri = idList[0].uri;
        }
        //
        const id = props.match.params.id;
        const docRef = doc(db, "announcement", id);
        await updateDoc(docRef, {
          id: docRef.id,
          title: value.title,
          link: value.link === undefined ? "" : value.link,
          start: value.dateOfNotice[0]._d,
          end: value.dateOfNotice[1]._d,
          update: serverTimestamp(),
          type: value.type === undefined ? "banner" : value.type.join(),
          name,
          uri,
        });

        await deleteSubCollection(docRef, "files");
        // const idList = await fileRef.current.addFiles();
        for (const idListKey in idList) {
          const data = idList[idListKey];
          const fileRef = doc(docRef, "files", data.id);
          await setDoc(fileRef, {
            id: data.id,
            create: serverTimestamp(),
            name: data.name,
            uri: data.uri,
          });
          // const storage = getStorage();
          // getDownloadURL(ref(storage, data.name))
          //   .then(async (url) => {
          //     await setDoc(fileRef, {
          //       id: data.id,
          //       create: serverTimestamp(),
          //       link: url,
          //     });
          //   })
          //   .catch((error) => {
          //     // Handle any errors
          //   });
        }

        dispatch(getAnnouncements());
        history.push({pathname: "/announcement"});
      },
    });
  };

  const deleteSubCollection = async (docRef, sub) => {
    const q = collection(docRef, sub);
    const docs = await getDocs(q);
    for (let i = 0; i < docs.size; i++) {
      const d = docs.docs[i];
      await deleteDoc(d.ref);
    }
    console.log(sub + " - collection all docs deleted");
  };

  const deleteConfirm = () => {
    Modal.confirm({
      title: "공지사항을 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{color: "red"}}/>,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        const id = props.match.params.id;
        const docRef = doc(db, "announcement", id);
        await deleteSubCollection(docRef, "files");
        await fileRef.current.deleteAll();
        await deleteDoc(docRef);
        dispatch(getAnnouncements());
        history.push({pathname: "/announcement"});
      },
    });
  };

  return (
    <Content style={{margin: "0 16px", height: "100%"}}>
      <div
        className="site-layout-background"
        style={{padding: 24, height: "100%", marginTop: 0}}
      >
        <div style={{padding: 50, height: "100%"}}>
          <Form
            ref={formRef}
            layout="vertical"
            name="form_in_modal"
            fields={[
              {
                name: ["title"],
                value: title,
              },
              {
                name: ["type"],
                value: [type],
              },
              {
                name: ["link"],
                value: link,
              },
              {
                name: ["dateOfNotice"],
                value: [moment(start, dateFormat), moment(end, dateFormat)],
              },
            ]}
          >
            <Descriptions title="" bordered>
              <Descriptions.Item label="제목" span={3}>
                <Form.Item
                  name="title"
                  rules={[
                    {required: true, message: "공지 제목을 입력하세요!"},
                  ]}
                >
                  <Input placeholder="공지 제목"/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="타입" span={3}>
                <Form.Item
                  name="type"
                  rules={[{required: true, message: "타입을 선택하세요!"}]}
                >
                  <Cascader
                    options={typeList}
                    placeholder="선택"
                    style={{width: "320px"}}
                  ></Cascader>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사진 추가하기" span={3}>
                <FileManager
                  ref={fileRef}
                  path={"announcement"}
                  max={1}
                  files={files}
                />
              </Descriptions.Item>
              <Descriptions.Item label="링크" span={3}>
                <Form.Item name="link">
                  <Input placeholder="공지 링크"/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="게시기간" span={3}>
                <Form.Item
                  name="dateOfNotice"
                  rules={[
                    {required: true, message: "게시 기간을 선택하세요!"},
                  ]}
                >
                  <RangePicker/>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Form>

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Button danger onClick={deleteConfirm}>공지사항 삭제</Button>
            <Row justify="end" style={{width: "100%"}}>
              <Space>
                <Link to="/announcement">
                  <Button>목록</Button>
                </Link>
                <Button
                  onClick={() => {
                    formRef.current
                      .validateFields()
                      .then((values) => {
                        modifyConfirm(values);
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                  type="primary"
                >
                  수정
                </Button>
              </Space>
            </Row>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default AnnouncementInfo;
