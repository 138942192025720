import React, { Component } from 'react';
import { Layout, Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import zones from '../zones.json'

const { Content } = Layout;

class Region extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    tags: [],
    data: []
  };

  async componentDidMount (props) {
    // const zonesRef = collection(db, "zones")
    // const q = query(zonesRef, orderBy("OBJECTID"));
    // const querySnapshot = await getDocs(q);
    // let temp = []
    // querySnapshot.forEach((doc) => {
    //   const data = doc.data()
    //   temp.push({
    //     OBJECTID: data.OBJECTID,
    //     adm_cd:  data.adm_cd,
    //     adm_nm: data.adm_nm,
    //     sggnm: data.sggnm
    //   })
    // });
    //
    this.setState({data: zones.data})

  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter 
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '', });
  };

  render() {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'OBJECTID',
            key: 'OBJECTID',
            width: '5%',
            sorter: (a, b) => a.OBJECTID - b.OBJECTID,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '지역 코드',
            dataIndex: 'sgg',
            key: 'sgg',
            width: '10%',
            ...this.getColumnSearchProps('sgg'),
            sorter: (a, b) => a.sgg - b.sgg,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '지역',
            dataIndex: 'sggnm',
            key: 'sggnm',
            width: '10%',
            ...this.getColumnSearchProps('sggnm'),
            sorter: (a, b) => a.sggnm.length - b.sggnm.length,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '동네 코드',
            dataIndex: 'adm_cd',
            key: 'adm_cd',
            width: '10%',
            ...this.getColumnSearchProps('adm_cd'),
            sorter: (a, b) => a.adm_cd - b.adm_cd,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '동네',
            dataIndex: 'adm_nm',
            key: 'adm_nm',
            width: '65%',
            ...this.getColumnSearchProps('adm_nm'),
            sorter: (a, b) => a.adm_nm.length - b.adm_nm.length,
            sortDirections: ['descend', 'ascend'],
        },

    ];
    return (
      <Content style={{ margin: '0 16px', height: '100%' }}>
        <div className="site-layout-background" style={{ padding: 24, height: '100%' }}>
          <Table
            columns={columns}
            dataSource={this.state.data}
            style={{ marginTop: '30px' }}
            rowKey={record => record.adm_cd}
          />
        </div>
      </Content>
    );
}
}

export default Region;