import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  orderBy,
  query,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../FirebaseApp";

const year = new Date().getFullYear().toString();

export const getShops = createAsyncThunk(
  "getShops",
  async (params, { dispatch, getState }) => {
    const shopsRef = collection(db, "shops");
    // const q = query(shopsRef, orderBy("create", "desc"))
    const q = query(shopsRef, orderBy("order", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      dispatch(setShops(querySnapshot));
    });

    // const shopsSnap = await getDocs(q)
    // let temp = {}
    // for (let i = 0; i < shopsSnap.size; i++) {
    //   const shop = shopsSnap.docs[i]
    //   shop.items = null
    //   shop.hashtags = null
    //
    //   temp[shop.id] = shop
    // }
    // dispatch(setShops(temp));
  }
);

export const getShopsSubCollection = createAsyncThunk(
  "getShopsSubCollection",
  async (params, { dispatch, getState }) => {
    const { id, subCollection } = params;
    const collectRef = collection(db, "shops", id, subCollection);
    const q = query(collectRef, orderBy("create", "desc"));
    const unsubscribe =
      subCollection === "savings"
        ? onSnapshot(collectRef, (querySnapshot) => {
            dispatch(
              setShopsSubCollection({
                id: id,
                collection: subCollection,
                snap: querySnapshot,
              })
            );
          })
        : onSnapshot(q, (querySnapshot) => {
            dispatch(
              setShopsSubCollection({
                id: id,
                collection: subCollection,
                snap: querySnapshot,
              })
            );
          });
    const snapData = await getDocs(collectRef);
    return snapData;
  }
);

export const getBranch = createAsyncThunk(
  "getBranch",
  async (params, { dispatch, getState }) => {
    console.log("call getBranch");
    const { id } = params;
    const branchRef = doc(db, "shops", id);
    const branchSnap = await getDoc(branchRef);

    const totalRef = doc(branchRef, "savings", "total");
    const totalSnap = await getDoc(totalRef);
    branchSnap.savingsTotal = totalSnap.data() !== undefined ? totalSnap : null;
    const monthRef = doc(branchRef, "savings", year);
    const monthSnap = await getDoc(monthRef);
    branchSnap.savingsMonth = monthSnap.data() !== undefined ? monthSnap : null;

    dispatch(setBranch(branchSnap));
    /* const shopsRef = collection(db, "shops")
    const q = query(shopsRef, orderBy("create", "desc"));
    const shopsSnap = await getDocs(q)
    let temp = {}
    for (let i = 0; i < shopsSnap.size; i++) {
      const shop = shopsSnap.docs[i]
      shop.items = null
      shop.hashtags = null
      // shop.savingsTotal = null
      // shop.savingsMonth = null

      const totalRef = doc(shop.ref, "savings", "total")
      const totalSnap = await getDoc(totalRef)
      shop.savingsTotal = totalSnap.data() !== undefined ? totalSnap: null
      const monthRef = doc(shop.ref, "savings", year)
      const monthSnap = await getDoc(monthRef)
      shop.savingsMonth = monthSnap.data() !== undefined ? monthSnap: null

      temp[shop.id] = shop
    }
    dispatch(setBranch(temp)); */
  }
);

const initialState = {
  shops: {},
  items: {},
  hashtags: {},
  savings: {},
  reviews: {},
};

const shops = createSlice({
  name: "shops",
  initialState,
  reducers: {
    setShops: (state, action) => {
      // reset
      state.shops = {};
      const docs = action.payload.docs.slice();
      // sorting by create
      // docs.sort((a,b) => {
      //   return b.data().create - a.data().create
      // })
      for (const doc of docs) {
        state.shops[doc.id] = doc;
      }
    },
    setShopsSubCollection: (state, action) => {
      const { id, collection, snap } = action.payload;
      state[collection][id] = snap;
    },
    setBranch: (state, action) => {
      state.shops = action.payload;
    },
  },
});

//actions
export const { setShops, deleteShop, setShopsSubCollection, setBranch } =
  shops.actions;

//selectors
export const selectShops = (state) => state.shops.shops;
export const selectShopItems = (state) => state.shops.items;
export const selectShopHashtags = (state) => state.shops.hashtags;
export const selectShopSavings = (state) => state.shops.savings;
export const selectShopReviews = (state) => state.shops.review;

export default shops.reducer;
