import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteReview } from "../redux/reducers/reviews";
import { getShopsSubCollection, selectShops } from "../redux/reducers/shops";
import {
  getUsers,
  getUsersSubCollection,
  selectUsers,
} from "../redux/reducers/users";
import ThumbnailContainer from "./ThumbnailContainer";

const UserReviews = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);
  const [id, setID] = useState("");
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState([]);
  const [reload, setReload] = useState(false);

  const updateData = useCallback(
    (data) => {

      console.log("UserReview::updateData")
      console.log(data)

      let newData = [];
      setID(props.id);
      const user = users[props.id];

      if (user !== null && shops !== null && data !== null) {
        data.docs.forEach( async (doc) => {
          const rdata = doc.data();
          const shopId = rdata.categoryId;
          const reviewId = rdata.reviewId;

          const getShopReviews = await dispatch(getShopsSubCollection({ id: shopId, subCollection: "reviews" }))
          const reviewDocs = getShopReviews.payload.docs
          for (const review of reviewDocs) {
            const data = review.data()
            const shop = shops[shopId]?.data();
            if (!!data?.reviewId && shop) {
              if (reviewId === data.reviewId) {
                const odata = {
                  reviewData: {
                    userId: props.id,
                    shopId,
                    reviewId: data.reviewId,
                  },
                  content: data.content,
                  shop: shop.name,
                  thumbnail: data?.name ?? "",
                  date: data.create,
                  time: data.create.toDate().toLocaleTimeString("it-IT"),
                };
                newData.push(odata);
                newData.sort((a, b) => b.date - a.date);
                setDatas(
                  newData.map((data, index) => {
                    data.no = index + 1;
                    return data;
                  })
                );
              }
            }
          }







        });
      }
      //
      setLoading(false);
    },
    [props, shops, users]
  );

  useEffect(() => {
    (async () => {
      const user = users ? users[props?.id] : null;
      if (user !== null) {
        setLoading(true);
        if (!user?.shops) {
          const action = await dispatch(
            getUsersSubCollection({ id: props.id, subCollection: "review" })
          );
          updateData(action.payload);
        } else {
          updateData(user.review);
        }
      }
    })();
  }, [dispatch, props, updateData, users]);

  const deleteConfirm = (info) => {
    const { userId, shopId, reviewId } = info;
    Modal.confirm({
      title: "리뷰를 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        // delete review
        await dispatch(deleteReview(info));

        // reload page
        dispatch(getUsers());
        history.push({ pathname: "/user/info/" + userId });
      },
    });
  };

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, height: "100%", marginTop: 0 }}
    >
      <Table
        rowKey="no"
        columns={getColumns(deleteConfirm)}
        dataSource={datas}
        style={{ marginTop: "30px" }}
      />
    </div>
  );
};

export default UserReviews;

const getColumns = (deleteConfirm) => {
  return [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "썸네일",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (name) => <ThumbnailContainer name={name} />,
    },
    {
      title: "가게",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "내용",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "날짜",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return <div>{date.toDate().toLocaleDateString("fr-CA")}</div>;
      },
    },
    {
      title: "시간",
      dataIndex: "time",
      key: "time",
    },
    {
      dataIndex: "reviewData",
      key: "delete",
      render: (reviewData, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DeleteOutlined
              style={{
                color: "#F5222D",
              }}
              onClick={() => deleteConfirm(reviewData)}
            />
          </div>
        );
      },
    },
  ];
};
