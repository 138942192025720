import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../FirebaseApp";

// 특정 파일 데이터 가져오기
export const getFilesByName = createAsyncThunk(
  "files/getFilesByName",
  async (params, { dispatch, getState }) => {
    const { name } = params;
    const fileRef = collection(db, "files");
    const q = query(fileRef, where("name", "==", name));
    dispatch(setSelectedFile(q));
  }
);

// 파일 데이터 가져오기
export const getFiles = createAsyncThunk(
  "files/getFiles",
  async (params, { dispatch, getState }) => {
    const fileRef = await collection(db, "files");
    const unsubscribe = onSnapshot(fileRef, (querySnapshot) => {
      const datas = querySnapshot.docs.map((doc) => doc.data());
      dispatch(setFiles(datas));
    });
  }
);

const initialState = {
  files: [],
  fileByName: null,
};

const files = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setSelectedFile: (state, action) => {
      state.fileByName = action.payload;
    },
  },
});

export const { setFiles, setSelectedFile } = files.actions;
export const selectFiles = (state) => state.files.files;
export const selectFilesByName = (state) => state.files.fileByName;
export default files.reducer;
