import React, {useCallback, useEffect, useState} from "react";
import {
  AutoComplete,
  Button,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Space,
} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import {collection, deleteDoc, doc, getDocs, serverTimestamp, setDoc, updateDoc,} from "firebase/firestore";
import {db} from "../FirebaseApp";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getGoods, selectGoods} from "../redux/reducers/goods";
import {selectShops} from "../redux/reducers/shops";
import FileManager from "./FileManager";

const {Content} = Layout;
const {TextArea, Search} = Input;
const {RangePicker} = DatePicker;
const dateFormat = "YYYY-MM-DD";

let shopName = "";

const GoodsInfo = (props) => {
  const dispatch = useDispatch();
  const formRef = React.createRef(null);
  const fileRef = React.createRef(null);
  const goods = useSelector(selectGoods);
  const shops = useSelector(selectShops);
  const history = useHistory();

  const [shopList, setShopList] = useState([]);
  const [shopID, setShopID] = useState("");

  const [name, setName] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [price, setPrice] = useState("");
  const [total, setTotal] = useState("");
  const [current, setCurrent] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [able_start, setAbleStart] = useState(null);
  const [able_end, setAbleEnd] = useState(null);
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState(null);

  // console.log(goods[props.match.params.id].data().shop_id)
  // setShopID(goods[props.match.params.id].data().shop_id)
  // console.log(shopID)
  // setShopName(shops[goods[props.match.params.id].data().shop_id].data().name)
  const id = props.match.params.id;

  const updateData = useCallback(() => {
    if (goods === null) return;
    const good = goods[id];
    if (good === undefined) {
      history.push({pathname: "/goods"});
      return;
    }

    if (shops !== null) {
      shopName =
        shops[good.data().shop_id] === undefined
          ? ""
          : shops[good.data().shop_id].data().name;
      let tempShops = [];
      for (const shopsKey in shops) {
        tempShops.push({
          value: shops[shopsKey].data().name,
          label: shops[shopsKey].data().name,
          id: shops[shopsKey].data().id,
        });
      }
      setShopList(tempShops);
    }

    const docRef = doc(db, "goods", id);
    const filesRef = collection(docRef, "files");
    setFiles(filesRef);

    setName(good.data().name);
    setSubtitle(good.data().subtitle);
    setPrice(good.data().price);
    setTotal(good.data().total);
    setCurrent(good.data().current);
    setStart(good.data().start.toDate());
    setEnd(good.data().end.toDate());
    setAbleStart(good.data().able_start.toDate());
    setAbleEnd(good.data().able_end.toDate());
    setDescription(good.data().description);
    setShopID(good.data().shop_id);
  }, [shops, history, id]);

  useEffect(() => {
    if (goods === null) {
      dispatch(getGoods());
      updateData();
    } else {
      updateData();
    }
  }, [goods, updateData, dispatch]);

  const onSelectShop = (value) => {
    shopName = value;
    setShopID(shopList.filter((shop) => shop.value === value)[0].id);
  };

  const modifyConfirm = (value) => {
    Modal.confirm({
      title: "상품을 수정하시겠습니까?",
      icon: <ExclamationCircleOutlined/>,
      content: "",
      okText: "수정",
      cancelText: "취소",
      async onOk() {
        const id = props.match.params.id;
        const docRef = doc(db, "goods", id);
        await updateDoc(docRef, {
          id: docRef.id,
          shop_id: shopID,
          name: value.name,
          subtitle: value.subtitle,
          price: value.price,
          total: value.total,
          current: value.current,
          start: value.dateOfGoods[0]._d,
          end: value.dateOfGoods[1]._d,
          description: value.description,
          update: serverTimestamp(),
          able_start: value.albeDateOfGoods[0]._d,
          able_end: value.albeDateOfGoods[1]._d,
        });

        await deleteSubCollection(docRef, "files");
        const idList = await fileRef.current.addFiles();
        for (const idListKey in idList) {
          const data = idList[idListKey];
          const name = data.name;
          const uri = data.uri;
          const fileRef = doc(docRef, "files", data.id);
          await setDoc(fileRef, {
            id: data.id,
            create: serverTimestamp(),
            name,
            uri,
          });
          // const storage = getStorage();
          // getDownloadURL(ref(storage, data.name))
          //   .then(async (url) => {
          //     await setDoc(fileRef, {
          //       id: data.id,
          //       create: serverTimestamp(),
          //       link: url,
          //     });
          //   })
          //   .catch((error) => {
          //     // Handle any errors
          //   });
        }

        dispatch(getGoods());
        history.push({pathname: "/goods"});
      },
    });
  };

  const deleteSubCollection = async (docRef, sub) => {
    const q = collection(docRef, sub);
    const docs = await getDocs(q);
    for (let i = 0; i < docs.size; i++) {
      const d = docs.docs[i];
      await deleteDoc(d.ref);
    }
    console.log(sub + " - collection all docs deleted");
  };

  const deleteConfirm = () => {
    Modal.confirm({
      title: "상품을 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{color: "red"}}/>,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        const id = props.match.params.id;
        const docRef = doc(db, "goods", id);
        await deleteSubCollection(docRef, "files");
        await fileRef.current.deleteAll();
        await deleteDoc(docRef);
        dispatch(getGoods());
        history.push({pathname: "/goods"});
      },
    });
  };

  return (
    <Content style={{margin: "0 16px", height: "100%"}}>
      <div
        className="site-layout-background"
        style={{padding: 24, height: "100%", marginTop: 0}}
      >
        <div style={{padding: 50, height: "100%"}}>
          <Form
            ref={formRef}
            layout="vertical"
            name="form_in_modal"
            fields={[
              {
                name: ["name"],
                value: name,
              },
              {
                name: ["subtitle"],
                value: subtitle,
              },
              {
                name: ["price"],
                value: price,
              },
              {
                name: ["shopName"],
                value: shopName,
              },
              {
                name: ["total"],
                value: total,
              },
              {
                name: ["current"],
                value: current,
              },
              {
                name: ["dateOfGoods"],
                value: [moment(start, dateFormat), moment(end, dateFormat)],
              },
              {
                name: ["albeDateOfGoods"],
                value: [
                  moment(able_start, dateFormat),
                  moment(able_end, dateFormat),
                ],
              },
              {
                name: ["description"],
                value: description,
              },
            ]}
          >
            <Descriptions title="" bordered>
              <Descriptions.Item label="상품 이름" span={3}>
                <Form.Item
                  name="name"
                  rules={[
                    {required: true, message: "상품 이름을 입력하세요."},
                  ]}
                >
                  <Input placeholder="상품 이름" style={{width: "453px"}}/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="서브타이틀" span={3}>
                <Form.Item
                  name="subtitle"
                  rules={[
                    {required: true, message: "서브타이틀을 입력하세요."},
                  ]}
                >
                  <TextArea showCount maxLength={500}/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="코인" span={3}>
                <Form.Item
                  name="price"
                  rules={[{required: true, message: "코인을 입력하세요."}]}
                >
                  <InputNumber/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사용처" span={3}>
                <Form.Item
                  name="shopName"
                  // rules={[{ required: true, message: '사용처를 입력하세요.' }]}
                >
                  <AutoComplete
                    style={{
                      width: 200,
                    }}
                    options={shopList}
                    placeholder=""
                    onSelect={onSelectShop}
                    filterOption={(inputValue, option) =>
                      option.value.indexOf(inputValue) !== -1
                    }
                    // defaultValue={shopName}
                  >
                    <Search placeholder="검색"/>
                  </AutoComplete>
                  {/* <Search placeholder="input search text" style={{ width: 200 }} /> */}
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="판매 총량" span={1}>
                <Form.Item
                  name="total"
                  rules={[
                    {required: true, message: "판매 총량을 입력하세요."},
                  ]}
                >
                  <InputNumber/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="재고" span={2}>
                <Form.Item
                  name="current"
                  rules={[{required: true, message: "재고를 입력하세요."}]}
                >
                  <InputNumber/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="판매기간" span={1}>
                <Form.Item
                  name="dateOfGoods"
                  rules={[
                    {required: true, message: "판매기간을 선택하세요."},
                  ]}
                >
                  <RangePicker/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사용기한" span={2}>
                <Form.Item
                  name="albeDateOfGoods"
                  rules={[
                    {required: true, message: "사용기한을 선택하세요."},
                  ]}
                >
                  <RangePicker/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="상품 설명" span={3}>
                <Form.Item
                  name="description"
                  rules={[
                    {required: true, message: "상품 설명을 입력하세요."},
                  ]}
                >
                  <TextArea showCount/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사진 추가하기" span={3}>
                <FileManager
                  ref={fileRef}
                  path={"goods"}
                  max={5}
                  files={files}
                />
              </Descriptions.Item>
            </Descriptions>
          </Form>

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Button danger onClick={deleteConfirm}>상품 삭제</Button>
            <Row justify="end" style={{width: "100%"}}>
              <Space>
                <Link to="/goods">
                  <Button>목록</Button>
                </Link>
                <Button
                  onClick={() => {
                    formRef.current
                      .validateFields()
                      .then((values) => {
                        modifyConfirm(values);
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                  type="primary"
                >
                  수정
                </Button>
              </Space>
            </Row>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default GoodsInfo;
