import React, { useEffect, useState, useRef } from 'react';
import { Layout, Table, Input, Button, Space, Modal, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import {
  DownloadOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  VerticalAlignMiddleOutlined, FileExcelOutlined, PlusOutlined
} from '@ant-design/icons';
import { collection, doc, setDoc, deleteDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../FirebaseApp';
import { useDispatch, useSelector } from "react-redux";
import { getHashtags, selectHashtags } from "../redux/reducers/hashtags";
import { Link } from 'react-router-dom'
import { Excel } from 'antd-table-saveas-excel';

const { Content } = Layout;
let newOrder = null;

const Hashtag = () => {
  const dispatch = useDispatch();
  const hashtags = useSelector(selectHashtags);
  const formRef = React.createRef(null);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [visibleAdd, setVisibleAdd] = useState(null);
  const [visibleMod, setVisibleMod] = useState(null);

  const [name, setName] = useState('');
  const [id, setID] = useState(null);
  let searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let newData = [];
    if(hashtags !== null) {
      newOrder = Object.keys(hashtags).length
      for (const hashtagsKey in hashtags) {
        const hashtag = hashtags[hashtagsKey]

        newData.push({
          key: hashtag.id,
          id: hashtag.id,
          name: hashtag.data().hashtag,
          order: hashtag.data().order,
        })
      }
    }

    setDatas(newData)
  }, [hashtags]);

  const showAddModal = () => {
    setVisibleAdd(true)
  };
  const showModModal = () => {
    setVisibleMod(true)
  };
  const hideModal = () => {
    setVisibleMod(false)
    setVisibleAdd(false)
  };

  const addItem = async (value) => {
    const docRef = doc(collection(db, "hashtags"));
    await setDoc(docRef, {'id': docRef.id, ...value, create: serverTimestamp(), update: serverTimestamp(), order: newOrder} );
    dispatch(getHashtags());
  };
  
  const updateItem = async (value) => {
    const docRef = doc(db, "hashtags", id);
    await updateDoc(docRef, {...value, update: serverTimestamp()});
    dispatch(getHashtags());
  };

  const deleteConfirm = () => {
    Modal.confirm({
      title: '해당 해시태그를 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined style={{color:"red"}}/>,
      content: '',
      okText: '삭제',
      cancelText: '취소',
      async onOk() {
        await deleteDoc(doc(db, "hashtags", id));
        dispatch(getHashtags());
        hideModal();
      }
    });
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  }; 

  const columns = [
    {
      title: 'No.',
      dataIndex: 'order',
      key: 'order',
      width: '1%',
      render: (order) => {
        return (
          order+1
        );
      }
    },
    {
        title: '해시태그',
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        ...getColumnSearchProps('name'),
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: '비고',
        dataIndex: 'active',
        key: 'active',
        align: 'center',
        width: '1%',
        render: (text, record, index) => {
          return (
            <Button type="primary" ghost onClick={
              () => {
                setName(record.name);
                setID(record.id);
                showModModal();
              }
            }>수정</Button>
          );
        },
        excelRender: () => {
          return;
        }
    },
  ];

  return (
    <Content style={{ margin: '0 16px', height: '100%' }}>
      <div className="site-layout-background" style={{ padding: 24, height: '100%' }}>
        <Button type="primary"style={{ position:'absolute', left: '240px',}}><Link to="/hashtags/sort">해시태그 정렬</Link></Button>

        <Link to="/hashtags/sort">
          <Button
            icon={<VerticalAlignMiddleOutlined/>}
            type={"default"}
            style={{position: 'absolute', left: '240px',}}
          >
            해시태그 정렬
          </Button>
        </Link>

        <Button
          icon={<PlusOutlined/>}
          type="primary"
          style={{ position:'absolute', right: '40px',}}
          onClick={showAddModal}
        >
          해시태그 추가
        </Button>
        <Table columns={columns} dataSource={datas} style={{ marginTop: '50px' }} rowKey={record => record.id}/>

        <Modal
          visible={visibleAdd}
          onOk={
            () => {
              formRef.current
                .validateFields()
                .then(values => {
                  formRef.current.resetFields();
                  addItem(values);
                  hideModal();
                })
                .catch(info => {
                  console.log('Validate Failed:', info);
                });
            }
          }
          onCancel={hideModal}
          okText="추가"
          cancelText="취소"
        >
          <p style={{fontWeight:'600', fontSize: '16px'}}>추가할 해시태그를 입력해 주세요</p>
          {/*<Input placeholder="#해시태그" style={{ width: '453px' }}/>*/}

          <Form
            ref={formRef}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: 'public' }}
          >
            <Form.Item
              name="hashtag"
              label="해시태그"
              rules={[{ required: true, message: '해시태그를 입력하세요.' }]}
            >
              <Input  />
            </Form.Item>
          </Form>
        </Modal>
        
        <Modal
          visible={visibleMod}
          onCancel={hideModal}
          footer={[
            <Button key="delete" type="primary" danger onClick={deleteConfirm} style={{position: 'absolute', left: '20px'}}>삭제</Button>,
            <Button key="cancel"  onClick={hideModal}>취소</Button>,
            <Button key="submit" type="primary" onClick={() => {
                formRef.current
                  .validateFields()
                  .then(values => {
                    formRef.current.resetFields();
                    updateItem(values);
                    hideModal();
                  })
                  .catch(info => {
                    console.log('Validate Failed:', info);
                  });
                }}>수정</Button>]}>
          <p style={{fontWeight:'600', fontSize: '16px'}}>수정할 해시태그를 입력해 주세요</p>

          <Form
            ref={formRef}
            layout="vertical"
            name="form_in_modal"
            fields={[
              {
                name: ["hashtag"],
                value: name,
              },
            ]}
            initialValues={{ modifier: 'public' }}>
            <Form.Item
              name="hashtag"
              label="해시태그"
              rules={[{ required: true, message: '해시태그를 입력하세요.' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Content>
  );
}

export default Hashtag;
