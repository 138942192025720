import React, {useEffect, useRef, useState} from "react";
import {Button, Input, Layout, Space, Spin, Table, Tag} from "antd";
import Highlighter from "react-highlight-words";
import {FileExcelOutlined, SearchOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUsersSubCollection, selectUsers} from "../redux/reducers/users";
import {Excel} from "antd-table-saveas-excel";
import zones from "../zones.json";

const {Content} = Layout;
const reg = /^([0-9]{0,3})[-. ]?([0-9]{0,4})[-. ]?([0-9]{0,4})$/;

let ranking = [];
const zoneList = zones.data;

const User = () => {
  const users = useSelector(selectUsers);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);

  let searchInputRef = useRef < HTMLInputElement > null;
  const dispatch = useDispatch();

  useEffect(async () => {

    let newData = [];

    console.log(users)

    if (users !== null) {
      let count = 1
      // 01. 유저 루프 시작
      for (const [key, user] of Object.entries(users)) {
        const data = user.data()
        let role;
        if (data.role === "admin") role = "관리자";
        else if (data.role === "branch") role = "가게 주인";
        else role = "회원";
        const userSggnm = zoneList
          .filter((zone) => zone.sgg === data.sgg)
          .map((zone) => zone.sggnm);
        //
        // 기본 데이터
        const idx = newData.push({
          ...data,
          key: user.id,
          mobile: data.mobile.trim().replace(reg, "$1-$2-$3"),
          role: role,
          useCoin: 0,
          sidonm: data.adm_nm.substring(0, data.adm_nm.indexOf(" ")),
          sggnm: Array.from(new Set(userSggnm)),
          countCollect: null,
          countGoods: null,
          countDonate: null,
          order: count,
        });
        //
        // 콜렉트 갯수
        if (user.collect === null) {
          await getUsersSubCollection({id: user.id, subCollection: "collect"})
        }
        newData[idx - 1]["countCollect"] = user.collect ? user.collect.size : 0;
        //
        // 상품 갯수
        if (user.goods === null) {
          await getUsersSubCollection({id: user.id, subCollection: "goods"})
        }
        const countGoods = user.goods ? user.goods.size : 0
        newData[idx - 1]["countGoods"] = countGoods
        for (let i = 0; i < countGoods; i++) {
          newData[idx - 1]["useCoin"] =
            newData[idx - 1]["useCoin"] + user.goods.docs[i].data().coin;
        }
        //
        // 기부 갯수
        if (user.donate === null) {
          await getUsersSubCollection({id: user.id, subCollection: "donate"})
        }
        newData[idx - 1]["countDonate"] = user.donate ? user.donate.size : 0;
        //
        // 리뷰 갯수
        if (user.review === null) {
          await getUsersSubCollection({id: user.id, subCollection: "review"})
        }
        newData[idx - 1]["countReview"] = user.review ? user.review.size : 0;
        count++
      } // 01. 유저 루프 종료

      setTimeout(() => {
        setDatas(newData);
        setLoading(false);
      }, 100);
    } // if (users !== null) { 종료


  }, [users, dispatch]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
                       setSelectedKeys,
                       selectedKeys,
                       confirm,
                       clearFilters,
                     }) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: "block"}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const rankSort = (score) => {
    ranking.push(score);
    const userRank = [...new Set(ranking)];
    userRank.sort(function (a, b) {
      return b - a;
    });
    return userRank.indexOf(score, 0) + 1;
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "order",
      key: "order",
      width: "1%",
      render: (order) => {
        return order;
      },
    },
    {
      title: "닉네임",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "전화번호",
      dataIndex: "mobile",
      key: "mobile",
      ...getColumnSearchProps("mobile"),
    },
    {
      title: "적립",
      dataIndex: "countCollect",
      key: "countCollect",
      sorter: (a, b) => a.countCollect - b.countCollect,
      sortDirections: ["descend", "ascend"],
      align: 'right',
    },
    {
      title: "구매",
      dataIndex: "countGoods",
      key: "countGoods",
      sorter: (a, b) => a.countGoods - b.countCollect,
      sortDirections: ["descend", "ascend"],
      align: 'right',
    },
    {
      title: "기부",
      dataIndex: "countDonate",
      key: "countDonate",
      sorter: (a, b) => a.countDonate - b.countDonate,
      sortDirections: ["descend", "ascend"],
      align: 'right',
    },
    {
      title: "구분",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => {
        if (a.role > b.role) return 1;
        if (a.role < b.role) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("role"),
      render: (role) => {
        let color;
        if (role === "회원") {
          color = "green";
        } else if (role === "관리자") {
          color = "red";
        } else if (role === "가게 주인") {
          color = "gold";
        }
        return (
          <Tag color={color} key={role}>
            {role}
          </Tag>
        );
      },
      excelRender: (role) => {
        return role;
      },
    },
    {
      title: "스코어",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
      sortDirections: ["descend", "ascend"],
      render: (score) => {
        return (
          score = new Intl.NumberFormat().format(score)
        );
      },
      align: 'right',
    },
    {
      title: "사용 코인",
      dataIndex: "useCoin",
      key: "useCoin",
      sorter: (a, b) => a.useCoin - b.useCoin,
      sortDirections: ["descend", "ascend"],
      render: (useCoin) => {
        return (
          useCoin = new Intl.NumberFormat().format(useCoin)
        );
      },
      align: 'right',
    },
    {
      title: "잔여 코인",
      dataIndex: "coin",
      key: "coin",
      sorter: (a, b) => a.coin - b.coin,
      sortDirections: ["descend", "ascend"],
      render: (coin) => {
        return (
          coin = new Intl.NumberFormat().format(coin)
        );
      },
      align: 'right',
    },
    {
      title: "시",
      dataIndex: "sidonm",
      key: "sidonm",
      ...getColumnSearchProps("sidonm"),
      sorter: (a, b) => {
        if (a.sidonm > b.sidonm) return 1;
        if (a.sidonm < b.sidonm) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "구",
      dataIndex: "sggnm",
      key: "sggnm",
      ...getColumnSearchProps("sggnm"),
      sorter: (a, b) => {
        if (a.sggnm > b.sggnm) return 1;
        if (a.sggnm < b.sggnm) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
      excelRender: (sggnm) => {
        return sggnm[0];
      },
    },
    {
      title: "동네",
      dataIndex: "dp_nm",
      key: "dp_nm",
      ...getColumnSearchProps("dp_nm"),
      sorter: (a, b) => {
        if (a.dp_nm > b.dp_nm) return 1;
        if (a.dp_nm < b.dp_nm) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "랭킹",
      dataIndex: "ranking",
      key: "ranking",
      sorter: (a, b) => a.score - b.score,
      sortDirections: ["descend", "ascend"],
      render: (text, record, index) => {
        return rankSort(record.score);
      },
    },
    {
      title: "가입일",
      dataIndex: "create",
      key: "create",
      sorter: (a, b) => a.create.seconds - b.create.seconds,
      sortDirections: ["descend", "ascend"],
      render: (create) => {
        return new Date(create.seconds * 1000).toISOString().slice(0, 10);
      },
    },
    {
      title: "비고",
      dataIndex: "active",
      key: "active",
      align: "center",
      width: "5%",
      render: (text, record, index) => {
        return (
          <Link
            to={{
              pathname: "/user/info/" + record.key,
            }}
          >
            <Button type="primary" ghost>수정</Button>
          </Link>
        );
      },
      excelRender: () => {
        return;
      },
    },
  ];

  return (
    <Content style={{margin: "0 16px", height: "100%"}}>
      <div
        className="site-layout-background"
        style={{padding: 24, height: "100%"}}
      >
        <Button
          icon={<FileExcelOutlined/>}
          style={{position: "absolute", left: "240px"}}
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({background: "FFFFFF"})
              .addSheet("회원 목록")
              .addColumns(columns)
              .addDataSource(datas)
              .saveAs("회원 목록.xlsx");
          }}
        >
          다운로드
        </Button>
        {/* <Button type="primary"style={{ position:'absolute', right: '40px',}}><Link to="/user/info">회원 추가</Link></Button> */}
        <Spin tip="Loading..." spinning={loading}>
          <Table
            columns={columns}
            dataSource={datas}
            style={{marginTop: "50px"}}
            rowKey={(record) => record.key}
          />
        </Spin>
      </div>
    </Content>
  );
};

export default User;
