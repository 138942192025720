import React, { useState } from "react";
import './App.css';
import SignIn from './components/SignIn';
import Admin from "./components/Admin"
// import Branch from "./components/Branch";
import LoadBranch from "./components/LoadBranch";

import {db, auth} from "./FirebaseApp";
import {onAuthStateChanged} from "firebase/auth";
import {doc, getDoc} from "firebase/firestore";
import {Provider} from "react-redux";
import store from "./redux/store";
import SignFail from "./components/SignFail";
import Loading from "./components/Loading";


const LOGIN_STATUS = {
  UNKNOWN: 'unknown',
  LOGIN: 'login',
  LOGOUT: 'logout',
  USER: 'user',
  BRANCH: 'branch',
  ADMIN: 'admin',
  LOGIN_FAIL: 'loginFail',
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(LOGIN_STATUS.UNKNOWN);
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        //console.log("==== onAuthStateChanged ====")
        //console.log(docSnap.data());
        const data = docSnap.data();
        if(data.role==="admin"){
          setIsLoggedIn(LOGIN_STATUS.ADMIN)
        }else if(data.role==="branch"){
          setIsLoggedIn(LOGIN_STATUS.BRANCH)
        }else if(data.role==="user"){
          setIsLoggedIn(LOGIN_STATUS.USER)
        }
      } else {
        setIsLoggedIn(LOGIN_STATUS.LOGIN_FAIL)
      }
    } else {
      // User is signed out
      setIsLoggedIn(LOGIN_STATUS.LOGOUT)
    }
  });

  let screen = <Loading/>
  if(isLoggedIn===LOGIN_STATUS.ADMIN) screen = <Admin/>
  //if(isLoggedIn===LOGIN_STATUS.ADMIN) screen = <LoadBranch/>
  // else if(isLoggedIn===LOGIN_STATUS.BRANCH) screen = <Branch/>
  else if(isLoggedIn===LOGIN_STATUS.BRANCH) screen = <LoadBranch/>
  else if(isLoggedIn===LOGIN_STATUS.LOGOUT) screen = <SignIn/>
  else if(isLoggedIn===LOGIN_STATUS.LOGIN_FAIL
    // || isLoggedIn===LOGIN_STATUS.BRANCH
    || isLoggedIn===LOGIN_STATUS.USER) screen = <SignFail/>
  return (
    <Provider store={store}>
      {screen}
    </Provider>
  );
}

export default App;
