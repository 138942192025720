import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../FirebaseApp";

export const getUsers = createAsyncThunk(
  "getUsers",
  async (params, { dispatch, getState }) => {
    console.log("call getUsers");
    const usersRef = collection(db, "users");
    const q = query(usersRef, orderBy("score", "desc"));
    const usersSnap = await getDocs(q);
    // const usersSnap = await getDocs(usersRef)

    let temp = {};
    for (let i = 0; i < usersSnap.size; i++) {
      const user = usersSnap.docs[i];
      user.collect = null;
      user.goods = null;
      user.donate = null;
      user.shops = null;
      user.savings = null;
      user.review = null;

      // const collectRef = collection(user.ref, "collect")
      // const collectSnap = await getDocs(collectRef)
      // user.collect = collectSnap
      // const goodsRef = collection(user.ref, "goods")
      // const goodsSnap = await getDocs(goodsRef)
      // user.goods = goodsSnap
      // const donateRef = collection(user.ref, "donate")
      // const donateSnap = await getDocs(donateRef)
      // user.donate = donateSnap

      // const filesRef = collection(user.ref, "files")
      // const f = query(filesRef, orderBy("create", "desc"));
      // const filesSnap = await getDocs(f)
      user.files = null;
      temp[user.id] = user;
    }
    dispatch(setUsers(temp));
  }
);

export const getUsersSubCollection = createAsyncThunk(
  "getUsersSubCollection",
  async (params, { dispatch, getState }) => {
    const { id, subCollection } = params;
    const collectRef = collection(db, "users", id, subCollection);
    const q = query(collectRef, orderBy("create", "desc"));
    const snapData = await getDocs(q);
    dispatch(
      setUsersSubCollection({
        id: id,
        collection: subCollection,
        snap: snapData,
      })
    );
    return snapData;
  }
);

const initialState = {
  docs: null,
};

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.docs = action.payload;
    },
    setUsersSubCollection: (state, action) => {
      const { id, collection, snap } = action.payload;
      state.docs[id][collection] = snap;
    },
  },
});

//actions
export const { setUsers, setUsersSubCollection } = users.actions;

//selectors
export const selectUsers = (state) => {
  return state.users.docs;
};

export default users.reducer;
