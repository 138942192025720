import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getBalances = createAsyncThunk(
  "getBalances",
  async (params, { dispatch, getState }) => {
    //console.log("call getBalances")
    const balancesRef = collection(db, "balances");
    const q = query(balancesRef, orderBy("update", "desc"));
    const balancesSnap = await getDocs(q)
    dispatch(setBalances(balancesSnap));
  }
);

export const getBalancesByUid = createAsyncThunk(
  "getBalancesByUid",
  async (params, { dispatch, getState }) => {
    //console.log("call getBalancesByUid");
    //console.log("balances params Uid: ", params);
    const balancesRef = collection(db, "balances");
    const q = query(balancesRef, where('user_id', '==', params), orderBy("update", "desc"));
    const balancesSnap = await getDocs(q)
    dispatch(setBalances(balancesSnap));
  }
);

const initialState = {
  docs: null,
};

const balances = createSlice({
  name: "balances",
  initialState,
  reducers: {
    setBalances: (state, action) => {
      let temp = {};
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc;
      })
      state.docs = temp;
    },
    delBalances: (state, action) => {
      state.docs = null;
    },
  },
});

//actions
export const { setBalances, delBalances } = balances.actions;

//selectors
export const selectBalances = (state) => state.balances.docs;

export default balances.reducer;