import Branch from "./Branch";
import React, { useEffect, useState } from "react";
import {getHashtags} from "../redux/reducers/hashtags";
import {getItems} from "../redux/reducers/items";
import { useDispatch, useSelector } from "react-redux";
import {getUsers, selectUsers} from "../redux/reducers/users";
import { selectShops } from "../redux/reducers/shops";
import {getGoods} from "../redux/reducers/goods";
import {getCollectHistories} from "../redux/reducers/collect_history";
import {getGoodsHistories} from "../redux/reducers/goods_history";
import { db, auth } from "../FirebaseApp";
import { getDoc, doc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Loading from "./Loading";

const LoadBranch = () => {
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [shop, setShop] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);

  useEffect(()=>{
    dispatch(getHashtags())
    dispatch(getItems())
    dispatch(getUsers())
    dispatch(getGoods())
    dispatch(getCollectHistories())
    dispatch(getGoodsHistories())
  }, [dispatch])

  useEffect(() => {
    if(users !== null) {
      const user = auth.currentUser
      const userData = users[user.uid]
      // console.log("=== login user data ===")
      // console.log(userData)
      setUserName(userData.data().name)
      setShop(userData.data().shop)

      // const getUserFile = async() => {
      //   if(userData.files.docs[0]){
      //     // console.log("=== user file ===")
      //     const id = userData.files.docs[0].id
      //     // console.log(id)
      //     const docRef = doc(db, "files", id)
      //     const docSnap = await getDoc(docRef);
      //     if (docSnap.exists()) {
      //     // console.log("Document data:", docSnap.data());
      //       const data = docSnap.data();
      //       // console.log(data)
      //       const storage = getStorage();
      //       getDownloadURL(ref(storage, data.name)).then((url) => {
      //         setUserAvatar(url)
      //       })
      //       .catch((error) => {
      //         // Handle any errors
      //       });
      //     }
      //   }
      // }
      // getUserFile();
      setIsLoaded(true)
    }
}, [users, shops]);

  let screen = <Loading/>
  if (isLoaded) screen = <Branch shopid={shop} userName={userName}/>
  return(
    <>
    {screen}
    </>
  )
}

export default LoadBranch