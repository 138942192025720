import React, {useCallback, useEffect, useState} from 'react';
import {Button, Spin, Table} from 'antd';
import {FileExcelOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {selectUsers} from "../redux/reducers/users";
import {selectShops} from "../redux/reducers/shops";
import {getRents, getRentsBySid, selectRents} from "../redux/reducers/rents";
import {Excel} from "antd-table-saveas-excel";

const Rents = (props) => {
  // useSelector: 리덕스의 상태를 조회
  // const 결과 = useSelector(상태 선택 함수);
  const users = useSelector(selectUsers);
  const _shops = useSelector(selectShops);
  const rents = useSelector(selectRents);

  const [datas, setDatas] = useState(null);
  const [status, setStatus] = useState(null);
  const [noReturn, setNoReturn] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  // useCallback: 만들어 놨던 함수를 재사용, 첫 번째 파라미터에 생성하고 싶은 함수, 두 번째 파라미터에는 어떤 값이 바뀌었을 때 함수를 새로 생성해야 하는지 명시하는 배열
  // 함수 내부에서 상태 값에 의존해야 할 때는 그 값을 반드시 두 번째 파라미터 안에 포함시켜야 함
  const updateData = useCallback(() => {
    //console.log("updateData!!!");
    let newData = [];
    if (rents === null) {
      //console.log("props:", props);
      if (props.shopid !== null && props.shopid !== undefined) {
        //console.log("props shopid:", props);
        dispatch(getRentsBySid(props.shopid));
      } else {
        //console.log("props:", props);
        dispatch(getRents());
      }
    } else {
      //console.log('rent is not null', rents);
      for (const rentId in rents) {
        const rent = rents[rentId];
        if (status !== null && status !== rent.data().status) continue;
        if (noReturn === '1w') {
          if (rent.data().status === 'lost' || rent.data().returned_date !== undefined) continue;
          const now = Math.floor(Date.now() / 1000) + (9 * 3600);
          if ((now - rent.data().rented_date.seconds) < 86400 * 7 || (now - rent.data().rented_date.seconds) >= 86400 * 14) continue;
        } else if (noReturn === '2w') {
          if (rent.data().status === 'lost' || rent.data().returned_date !== undefined) continue;
          const now = Math.floor(Date.now() / 1000) + (9 * 3600);
          if ((now - rent.data().rented_date.seconds) < 86400 * 14) continue;
        }
        let name = null;
        if (users !== null) {
          if (users[rent.data().uid] !== undefined) {
            name = users[rent.data().uid].data().name
          }
        }
        let mobile = null;
        if (users !== null) {
          if (users[rent.data().uid] !== undefined) {
            mobile = users[rent.data().uid].data().mobile
          }
        }

        let rentPeriod = null;
        if (rent.data().returned_date !== undefined) {
          rentPeriod = Math.ceil((rent.data().returned_date.seconds - rent.data().rented_date.seconds) / 86400);
        } else {
          rentPeriod = Math.ceil((Math.floor(Date.now() / 1000) + (9 * 3600) - rent.data().rented_date.seconds) / 86400);
        }
        if (rent.data().status !== 'lost' && rentPeriod > 0) rentPeriod = rentPeriod + '일';
        else rentPeriod = '';

        let rentedShopName = null;
        if (_shops !== null) {
          console.log('_shops: ', _shops);
          if (_shops[props.shopid] !== undefined && _shops[props.shopid].data().name !== undefined) {
            rentedShopName = _shops[props.shopid].data().name;
          }
        }
        let returnedShopName = null;
        if (_shops !== null) {
          console.log("returned_shop_id: ", rent.data().returned_shop_id);

          if (_shops[rent.data().returned_shop_id] !== undefined) {
            console.log("returned_shop_id: ", rent.data().returned_shop_id);
            returnedShopName = _shops[rent.data().returned_shop_id].data().name;
          }
        }
        let returnedDate = null;
        if (rent.data().returned_date !== undefined) {
          returnedDate = rent.data().returned_date.toDate().toLocaleDateString("fr-CA");
        }
        newData.push({
          id: rentId,
          uid: rent.data().uid,
          status: rent.data().status,
          name: name,
          mobile: mobile,
          rent_period: rentPeriod,
          rented_shop: rentedShopName,
          rented_date: rent.data().rented_date,
          returned_shop: returnedShopName,
          returned_date: returnedDate
        })
      }
      //console.log("newData:", newData);
    }

    setDatas(newData);
    setLoading(false);
  }, [props, rents, _shops, users, status, noReturn, dispatch]);

  // useEffect: 리액트 컴포넌트가 렌더링될 때마다 특정 작업을 수행하도록 설정할 수 있는 Hook
  useEffect(() => {
    //console.log("useEffect!!!");
    updateData();
  }, [dispatch, updateData]);

  const columns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      width: '46px',
      filters: [
        {text: '대여중', value: 'rent'},
        {text: '반납 완료', value: 'return'},
        {text: '분실', value: 'lost'},
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend'],
      render: (status) => {
        let color = '#52C41A'
        if (status === 'return') {
          color = '#D9D9D9';
        } else if (status === 'lost') {
          color = '#F5222D';
        }
        return (
          <div style={{width: "16px", height: "16px", borderRadius: "50%", backgroundColor: color}}></div>
        );
      },
    },
    {
      title: '닉네임',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '전화번호',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: '대여 기간',
      dataIndex: 'rent_period',
      key: 'rent_period',
      sorter: (a, b) => a.rent_period.length - b.rent_period.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '대여 지점',
      dataIndex: 'rented_shop',
      key: 'rented_shop',
      sorter: (a, b) => a.rented_shop.length - b.rented_shop.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '대여 날짜',
      dataIndex: 'rented_date',
      key: 'rented_date',
      sorter: (a, b) => a.rented_date - b.rented_date,
      sortDirections: ['descend', 'ascend'],
      render: (rented_date) => {
        return rented_date.toDate().toLocaleDateString("fr-CA")
      }
    },
    {
      title: '반납 지점',
      dataIndex: 'returned_shop',
      key: 'returned_shop',
    },
    {
      title: '반납 날짜',
      dataIndex: 'returned_date',
      key: 'returned_date',
    }
  ];
  return (
    <div className="site-layout-background" style={{padding: 24, height: '100%', marginTop: 0}}>
      <Button type="primary" style={{
        backgroundColor: (status == null && noReturn == null) ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "240px"
      }} onClick={() => {
        setStatus(null);
        setNoReturn(null);
      }}>
        전체보기
      </Button>
      <Button type="primary" style={{
        backgroundColor: (status === 'rent') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "330px"
      }} onClick={() => {
        setNoReturn(null);
        setStatus('rent');
      }}>
        대여중
      </Button>
      <Button type="primary" style={{
        backgroundColor: (status === 'return') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "407px"
      }} onClick={() => {
        setNoReturn(null);
        setStatus('return');
      }}>반납 완료
      </Button>
      <Button type="primary" style={{
        backgroundColor: (noReturn === '1w') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "500px"
      }} onClick={() => {
        setStatus(null);
        setNoReturn('1w');
      }}>1주 미반납</Button>
      <Button type="primary" style={{
        backgroundColor: (noReturn === '2w') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "600px"
      }} onClick={() => {
        setStatus(null);
        setNoReturn('2w');
      }}>2주 미반납</Button>
      <Button type="primary" style={{
        backgroundColor: (status === 'lost') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "700px"
      }} onClick={() => {
        setNoReturn(null);
        setStatus('lost');
      }}>분실 신고</Button>
      <Button type="primary" ghost
              icon={<FileExcelOutlined/>}
              style={{position: "absolute", right: "40px"}}
              onClick={() => {
                const excel = new Excel();
                excel
                  .setTHeadStyle({background: "FFFFFF"})
                  .addSheet("가게 목록")
                  .addColumns(columns)
                  .addDataSource(datas)
                  .saveAs("가게 목록.xlsx");
              }}
      >
        다운로드
      </Button>

      <Spin tip="Loading..." spinning={loading}>
        <Table columns={columns} dataSource={datas} style={{marginTop: '44px'}} rowKey={record => record.id}/>
      </Spin>
    </div>
  );
}

export default Rents;