import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Input, Modal, Space, Spin, Table} from 'antd';
import {ExclamationCircleOutlined, FileExcelOutlined, SearchOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {doc, serverTimestamp, updateDoc} from "firebase/firestore";
import {db} from "../FirebaseApp";
import {selectUsers} from "../redux/reducers/users";
import {selectShops} from "../redux/reducers/shops";
import {delRents, getRents, getRentsBySid, getRentsByUid, selectRents} from "../redux/reducers/rents";
import {Excel} from "antd-table-saveas-excel";
import Highlighter from "react-highlight-words";

const Rents = (props) => {
  // useSelector: 리덕스의 상태를 조회
  // const 결과 = useSelector(상태 선택 함수);
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);
  const rents = useSelector(selectRents);

  const [datas, setDatas] = useState(null);
  const [status, setStatus] = useState(null);
  const [noReturn, setNoReturn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);

  let searchInputRef = useRef < HTMLInputElement > null;
  const dispatch = useDispatch();

  // useCallback: 만들어 놨던 함수를 재사용, 첫 번째 파라미터에 생성하고 싶은 함수, 두 번째 파라미터에는 어떤 값이 바뀌었을 때 함수를 새로 생성해야 하는지 명시하는 배열
  // 함수 내부에서 상태 값에 의존해야 할 때는 그 값을 반드시 두 번째 파라미터 안에 포함시켜야 함
  const updateData = useCallback(() => {
    //console.log("updateData!!!");
    let newData = [];
    if (rents === null) {
      //console.log("props:", props);
      if (props.uid !== null && props.uid !== undefined) {
        dispatch(getRentsByUid(props.uid));
      } else if (props.sid !== null && props.sid !== undefined) {
        dispatch(getRentsBySid(props.sid));
      } else {
        dispatch(getRents());
      }
    } else {
      //console.log('rent is not null', rents);
      for (const rentId in rents) {
        const rent = rents[rentId];
        if (status !== null && status !== rent.data().status) continue;
        if (noReturn === '1w') {
          if (rent.data().status === 'lost' || rent.data().returned_date !== undefined) continue;
          const now = Math.floor(Date.now() / 1000) + (9 * 3600);
          if ((now - rent.data().rented_date.seconds) < 86400 * 7 || (now - rent.data().rented_date.seconds) > 86400 * 14) continue;
        } else if (noReturn === '2w') {
          if (rent.data().status === 'lost' || rent.data().returned_date !== undefined) continue;
          const now = Math.floor(Date.now() / 1000) + (9 * 3600);
          if ((now - rent.data().rented_date.seconds) < 86400 * 14) continue;
        }

        let name = null;
        if (users !== null) {
          if (users[rent.data().uid] !== undefined) {
            name = users[rent.data().uid].data().name
          }
        }

        let mobile = null;
        if (users !== null) {
          if (users[rent.data().uid] !== undefined) {
            mobile = users[rent.data().uid].data().mobile
          }
        }

        let rentPeriod = 0;
        if (rent.data().returned_date !== undefined) {
          rentPeriod = Math.ceil((rent.data().returned_date.seconds - rent.data().rented_date.seconds) / 86400);
        } else if (rent.data().status === 'lost') {
          // console.log(rent.data().lost_date);
          rentPeriod = Math.ceil((rent.data().lost_date.seconds - rent.data().rented_date.seconds) / 86400);
        } else {
          rentPeriod = Math.ceil((Math.floor(Date.now() / 1000) + (9 * 3600) - rent.data().rented_date.seconds) / 86400);
        }

        let rentedShopName = null;
        if (shops !== null) {
          if (shops[rent.data().rented_shop_id] !== undefined) {
            rentedShopName = shops[rent.data().rented_shop_id].data().name
          }
        }

        let returnedShopName = null;
        if (shops !== null) {
          if (shops[rent.data().returned_shop_id] !== undefined) {
            returnedShopName = shops[rent.data().returned_shop_id].data().name
          }
        }

        let returnedDate = null;
        if (rent.data().returned_date !== undefined) {
          returnedDate = rent.data().returned_date.toDate().toLocaleDateString("fr-CA");
        }

        newData.push({
          ...rent.data(),
          id: rentId,
          //uid: rent.data().uid,
          //status: rent.data().status,
          name: name,
          mobile: mobile,
          rent_period: rentPeriod,
          rented_shop: rentedShopName,
          //rented_date: rent.data().rented_date,
          returned_shop: returnedShopName,
          returned_date: returnedDate
        })
      }
      //console.log("newData:", newData);
    }

    setDatas(newData);
    setLoading(false);
  }, [props, rents, shops, users, status, noReturn, dispatch]);

  // useEffect: 리액트 컴포넌트가 렌더링될 때마다 특정 작업을 수행하도록 설정할 수 있는 Hook
  useEffect(() => {
    updateData();
  }, [updateData]);
  useEffect(() => {
    dispatch(delRents());
  }, []);

  const setLost = (id) => {
    Modal.confirm({
      title: '해당 대여 내역을 분실처리 하겠습니까?',
      icon: <ExclamationCircleOutlined style={{color: "#F5222D"}}/>,
      content: '',
      okText: '분실',
      okType: 'danger',
      cancelText: '취소',
      async onOk() {
        // toDo: 분실처리
        console.log(id);
        const docRef = doc(db, "rents", id);
        await updateDoc(docRef, {
          lost_date: serverTimestamp(),
          status: 'lost',
        });
        dispatch(getRents());
      }
    });
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
                       setSelectedKeys,
                       selectedKeys,
                       confirm,
                       clearFilters,
                     }) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: "block"}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: '상태',
      dataIndex: "status",
      key: 'status',
      //width: '46px',
      filters: [
        {text: '대여중', value: 'rent'},
        {text: '반납 완료', value: 'return'},
        {text: '분실', value: 'lost'},
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => {
        if (a.status > b.status) return -1;
        if (a.status < b.status) return 1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
      render: (status, data) => {

        let color = '#52C41A'
        if (status === 'return') {
          color = '#D9D9D9';
        } else if (status === 'lost') {
          color = '#B37FEB';
        } else if (data.rent_period > 14) {
          color = "#F5222D"
        }

        return (
          <div style={{width: "16px", height: "16px", borderRadius: "50%", backgroundColor: color}}></div>
        );
      },
      excelRender: (status) => {
        let _text = '';
        if (status === 'rent') {
          _text = '대여중';
        } else if (status === 'return') {
          _text = '반납 완료';
        } else if (status === 'lost') {
          _text = '분실';
        }
        return _text;
      },
    },
    {
      title: '닉네임',
      dataIndex: 'name',
      key: 'name',
      //width: '15%',
      ...getColumnSearchProps("name"),
      sorter: (a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
      excelRender: (name) => {
        return name + '님';
      },
    },
    {
      title: '전화번호',
      dataIndex: 'mobile',
      key: 'mobile',
      excelRender: (mobile) => {
        return mobile;
      },
    },
    {
      title: '대여 기간',
      dataIndex: 'rent_period',
      key: 'rent_period',
      sorter: (a, b) => a.rent_period - b.rent_period,
      sortDirections: ['descend', 'ascend'],
      render: (rent_period) => {
        if (rent_period > 0) return (rent_period + '일');
      },
      excelRender: (rent_period) => {
        return (rent_period > 0) ? (rent_period + '일') : '';
      },
    },
    {
      title: '대여 지점',
      dataIndex: 'rented_shop',
      key: 'rented_shop',
      sorter: (a, b) => {
        if (a.rented_shop > b.rented_shop) return 1;
        if (a.rented_shop < b.rented_shop) return -1;
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
      excelRender: (rented_shop) => {
        return rented_shop;
      },
    },
    {
      title: '대여 날짜',
      dataIndex: 'rented_date',
      key: 'rented_date',
      sorter: (a, b) => a.rented_date - b.rented_date,
      sortDirections: ['descend', 'ascend'],
      render: (rented_date) => {
        return rented_date.toDate().toLocaleDateString("fr-CA");
      },
      excelRender: (rented_date) => {
        return rented_date.toDate().toLocaleDateString("fr-CA");
      },
    },
    {
      title: '반납 지점',
      dataIndex: 'returned_shop',
      key: 'returned_shop',
      excelRender: (returned_shop) => {
        return returned_shop;
      },
    },
    {
      title: '반납 날짜',
      dataIndex: 'returned_date',
      key: 'returned_date',
      excelRender: (returned_date) => {
        return returned_date;
      },
    },
    {
      title: '비고',
      dataIndex: 'id',
      key: 'id',
      render: (id) => {
        const _status = rents !== null ? rents[id].data().status : null;
        if (_status !== null && (_status === 'lost' || _status === 'return')) {
          return (
            <Button danger disabled>분실</Button>
          );
        } else {
          return (
            <Button danger onClick={() => setLost(id)}>분실</Button>
          );
        }
      },
      excelRender: () => {
        return '';
      }
    }
  ];
  return (
    <div className="site-layout-background" style={{padding: 24, height: '100%', marginTop: 0}}>
      <Button type="primary" style={{
        backgroundColor: (status == null && noReturn == null) ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "240px"
      }} onClick={() => {
        setStatus(null);
        setNoReturn(null);
      }}>
        전체보기
      </Button>
      <Button type="primary" style={{
        backgroundColor: (status === 'rent') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "330px"
      }} onClick={() => {
        setNoReturn(null);
        setStatus('rent');
      }}>
        대여중
      </Button>
      <Button type="primary" style={{
        backgroundColor: (status === 'return') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "407px"
      }} onClick={() => {
        setNoReturn(null);
        setStatus('return');
      }}>반납 완료
      </Button>
      <Button type="primary" style={{
        backgroundColor: (noReturn === '1w') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "500px"
      }} onClick={() => {
        setStatus(null);
        setNoReturn('1w');
      }}>1주 미반납</Button>
      <Button type="primary" style={{
        backgroundColor: (noReturn === '2w') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "600px"
      }} onClick={() => {
        setStatus(null);
        setNoReturn('2w');
      }}>2주 미반납</Button>
      <Button type="primary" style={{
        backgroundColor: (status === 'lost') ? "#40A9FF" : "#096DD9",
        position: "absolute",
        left: "700px"
      }} onClick={() => {
        setNoReturn(null);
        setStatus('lost');
      }}>분실 신고</Button>
      <Button type="primary" ghost
              icon={<FileExcelOutlined/>}
              style={{position: "absolute", right: "40px"}}
              onClick={() => {
                const excel = new Excel();
                excel
                  .setTHeadStyle({background: "FFFFFF"})
                  .addSheet("대여 목록")
                  .addColumns(columns)
                  .addDataSource(datas)
                  .saveAs("대여 목록.xlsx");
              }}
      >
        다운로드
      </Button>

      <Spin tip="Loading..." spinning={loading}>
        <Table
          columns={columns}
          dataSource={datas}
          style={{marginTop: '44px'}}
          rowKey={record => record.id}
        />
      </Spin>
    </div>
  );
}

export default Rents;