import firebaseConfig from "./firebaseConfig";
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const functions = getFunctions(firebaseApp);

export {firebaseApp, db, auth, functions};
