import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../FirebaseApp";

// 리스트 가져오기
export const getContents = createAsyncThunk(
  "getContents",
  async (params, { dispatch, getState }) => {
    const contentsRef = collection(db, "contents");
    const q = query(contentsRef, orderBy("create", "desc"));
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(setContents({ data }));
  }
);

// 저장
export const addContents = createAsyncThunk(
  "addContents",
  async (params, { dispatch, getState }) => {
    const { title, link, hashtag, thumb, uri } = params;
    const docRef = doc(collection(db, "contents"));
    setDoc(docRef, {
      id: docRef.id,
      title,
      link,
      hashtag,
      thumb,
      uri,
      create: serverTimestamp(),
    })
      .then(() => {
        console.log("======addContents======");
        dispatch(getContents());
      })
      .catch((err) => {
        console.log("🚀 ~ file: contents.js ~ line 48 ~ err", err);
      });
  }
);

// 업데이트
export const updateContents = createAsyncThunk(
  "updateContents",
  async (params, { dispatch, getState }) => {
    const { id, data } = params;
    const docRef = doc(db, "contents", id);
    await updateDoc(docRef, { ...data, update: serverTimestamp() });
    dispatch(getContents());
  }
);

// 삭제
export const deleteContents = createAsyncThunk(
  "deleteContents",
  async (params, { dispatch, getState }) => {
    const { id } = params;
    const docRef = doc(db, "contents", id);
    await deleteDoc(docRef);
    dispatch(getContents());
  }
);

const initialState = {
  docs: [],
};

const contents = createSlice({
  name: "contents",
  initialState,
  reducers: {
    setContents: (state, action) => {
      const { data } = action.payload;
      state.docs = data;
    },
  },
});

//actions
export const { setContents } = contents.actions;

//selectors
export const selectContents = (state) => state.contents.docs;

export default contents.reducer;
