import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query, onSnapshot } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getCollectHistories = createAsyncThunk(
  "getCollectHistories",
  async (params, { dispatch, getState }) => {
    console.log("call getCollectHistories")
    const collectHistoriesRef = collection(db, "collect_history")
    const q = query(collectHistoriesRef, orderBy("create", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      dispatch(setCollectHistories(querySnapshot));
    });
    /* const collectHistoriesSnap = await getDocs(q)
    // const collectHistoriesSnap = await getDocs(collectHistoriesRef)
    let temp = {}
    for (let i = 0; i < collectHistoriesSnap.size; i++) {
      const collectHistory = collectHistoriesSnap.docs[i]
      collectHistory.collect_items = null
      temp[collectHistory.id] = collectHistory
    }
    dispatch(setCollectHistories(temp)); */
  }
);

export const getCollectHistorySubCollection = createAsyncThunk(
  "getCollectHistorySubCollection",
  async (params, { dispatch, getState }) => {
    const {id, subCollection} = params
    const collectRef = collection(db, "collect_history", id, subCollection)
    // const q = query(collectRef, orderBy("create", "desc"));
    const unsubscribe = onSnapshot(collectRef, (querySnapshot) => {
      dispatch(setCollectHistoriesSubCollection({id: id, collection: subCollection, snap: querySnapshot}));
    });
    const snapData = await getDocs(collectRef)
    return snapData
  }
);


const initialState = {
  // docs: null,
  collectHistories: {},
  collect_items: {},
};

const collectHistories = createSlice({
  name: "collectHistories",
  initialState,
  reducers: {
    setCollectHistories: (state, action) => {
      // state.docs = action.payload
      state.collectHistories = {}
      const docs = action.payload.docs.slice();
      // sorting by create
      docs.sort((a,b) => {
        return b.data().create - a.data().create
      })
      for (const doc of docs) {
        state.collectHistories[doc.id] = doc
      }
    },
    setCollectHistoriesSubCollection: (state, action) => {
      const {id, collection, snap} = action.payload
      state[collection][id] = snap
    },
  },
});

//actions
export const {
  setCollectHistories,
  setCollectHistoriesSubCollection,
} = collectHistories.actions;

//selectors
export const selectCollectHistories = (state) => state.collectHistories.collectHistories;
export const selectCollectHistoriesItems = (state) => state.collectHistories.collect_items;

export default collectHistories.reducer;
