import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { UserOutlined, RestOutlined, ShopOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import { useSelector} from "react-redux";
import {selectUsers} from "../redux/reducers/users";
// import {selectItems} from "../redux/reducers/items";
import { doc, getDoc } from "firebase/firestore";
import {db} from "../FirebaseApp";
import { selectShops } from "../redux/reducers/shops";
import { selectCollectHistories } from "../redux/reducers/collect_history";

const { Content } = Layout;
const { Title } = Typography;
const year = new Date().getFullYear().toString();
const month = new Date().getMonth() + 1;

const Dashboard = () => {
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);
  const collectHistories = useSelector(selectCollectHistories);
  // const items = useSelector(selectItems);

  const [totalUsers, setTotalUsers] = useState(0);
  const [monthUsers, setMonthUsers] = useState(0);
  const [variationUsers, setVariationUsers] = useState(0);
  const [realUsers, setRealUsers] = useState(0);
  const [totalCollect, setTotalCollect] = useState(0);
  const [monthCollect, setMonthCollect] = useState(0);
  // const [totalCollectKey, setTotalCollectKey] = useState([]);
  const [totalCollectItems, setTotalCollectItems] = useState([]);
  const [monthCollectItems, setMonthCollectItems] = useState([]);
  const [totalShops, setTotalShops] = useState(0);
  const [monthShops, setMonthShops] = useState(0);
  const [totalCollectHistory, setTotalCollectHistory] = useState(0);
  const [monthCollectHistory, setMonthCollectHistory] = useState(0);
  const [totalReusableCup, setTotalReusableCup] = useState([]);
  const [totalReusablePacking, setTotalReusablePacking] = useState([]);
  const [totalReusableContainer, setTotalReusableContainer] = useState([]);
  const [totalRefillContainer, setTotalRefillContainer] = useState([]);
  const [totalRefusing, setTotalRefusing] = useState([]);
  
  useEffect(() => {
    if(users !== null) {
      setTotalUsers(Object.keys(users).length); //총 이용자수
      
      let countMonthUsers = 0
      let countRealUsers = 0
      let countVariationUsers = 0;

      for (const usersKey in users) {
        const data = users[usersKey].data()
        const date = data.create.toDate()
        if( date.getFullYear().toString() === year && date.getMonth()+1 === month ) {
          countMonthUsers++;
        }
        if(data.score > 0) {
          countRealUsers++;
        }
        if( date.getFullYear().toString() === year && date.getMonth()+1 === month - 1) {
          countVariationUsers++;
        }
      }

      setMonthUsers(countMonthUsers)
      setRealUsers(countRealUsers)
      setVariationUsers(countMonthUsers - countVariationUsers )
    }

    if(shops !== null) {
      // 총 제휴 가게 수
      setTotalShops(Object.keys(shops).length)
      // 신규 제휴 가게 수
      let countMontShops = 0
      for (const shopsKey in shops) {
        const date = shops[shopsKey].data().create.toDate()
        if( date.getFullYear().toString() === year && date.getMonth()+1 === month) {
          countMontShops++;
        }
      }
      setMonthShops(countMontShops)
    }
    
    if(collectHistories !== null) {
      setTotalCollectHistory(Object.keys(collectHistories).length)
      let countMonthCollectHistory = 0
      for (const collectHistoriesKey in collectHistories) {
        if(collectHistories[collectHistoriesKey].data().create.toDate().getMonth()+1 === month) {
          countMonthCollectHistory++;
        }
      }
      setMonthCollectHistory(countMonthCollectHistory)
    }
    
    async function getStatistics() {
      const totalStatisticsRef = doc(db, "savings", "total")
      const totalStatisticsSnap = await getDoc(totalStatisticsRef)
      const monthStatisticsRef = doc(db, "savings", year)
      const monthStatisticsSnap = await getDoc(monthStatisticsRef)
      
      // setTotalCollectKey(Object.keys(totalStatisticsSnap.data()))//품목 이름 array
      if (totalStatisticsSnap.data() !== undefined && monthStatisticsSnap.data() !== undefined) {
        const totalCollectValue = Object.values(totalStatisticsSnap.data()) //아이템별 총 적립수가 담긴 array
        let countTotal = 0;
        for (let i = 0; i < totalCollectValue.length; i++) {
          countTotal = countTotal + totalCollectValue[i]
        }
        setTotalCollect(countTotal) //총 적립수

        // console.log(totalStatisticsSnap.data())
        setTotalCollectItems(totalStatisticsSnap.data()) // {아이템id: 총 적립수} obj

        let countMonth = 0;
        let monthCollect = {}
      
        for (let i = 0; i < Object.keys(monthStatisticsSnap.data()).length; i++) {
          countMonth = countMonth + Object.values(monthStatisticsSnap.data())[i][month] //월 적립수

          const key =  Object.keys(monthStatisticsSnap.data())[i]
          monthCollect={
            ...monthCollect,
            [key]: Object.values(monthStatisticsSnap.data())[i][month]
          }
        }
        setMonthCollect(countMonth) //월 적립수
        setMonthCollectItems(monthCollect) // {아이템id: 월 적립수} obj
      }
/*       
R8z3aEf9Huk2BL0hGLLt // dev 거절하기
cvHrGEJ8xfCZ9ig9AHBl // real
UV6iYNI37JZOtxZw7Wmc 재사용 포장재
T3cJr0cogVeKsbERcPlj
YSnqXqyyHra9WJ4qJGRZ 리필 용기
r6V568cm0yGwDfQ8vooW
dgwCZM6hNX7jKUdQc8sW 다회 용기
hjzsUXGds7dcqJyQYQzr
rsioVX3qv0VX2AF3AlFJ 다회용 컵
AESpVawGP202Tg4QOmvH
*/
      setTotalReusableCup(totalStatisticsSnap.data()['rsioVX3qv0VX2AF3AlFJ'] !== undefined? totalStatisticsSnap.data()['rsioVX3qv0VX2AF3AlFJ']:(totalStatisticsSnap.data()['AESpVawGP202Tg4QOmvH'] !== undefined? totalStatisticsSnap.data()['AESpVawGP202Tg4QOmvH']:0))
      setTotalReusablePacking(totalStatisticsSnap.data()['UV6iYNI37JZOtxZw7Wmc'] !== undefined? totalStatisticsSnap.data()['UV6iYNI37JZOtxZw7Wmc']:(totalStatisticsSnap.data()['T3cJr0cogVeKsbERcPlj'] !== undefined? totalStatisticsSnap.data()['T3cJr0cogVeKsbERcPlj']:0))
      setTotalReusableContainer(totalStatisticsSnap.data()['dgwCZM6hNX7jKUdQc8sW'] !== undefined? totalStatisticsSnap.data()['dgwCZM6hNX7jKUdQc8sW']:(totalStatisticsSnap.data()['hjzsUXGds7dcqJyQYQzr'] !== undefined? totalStatisticsSnap.data()['hjzsUXGds7dcqJyQYQzr']:0))
      setTotalRefillContainer(totalStatisticsSnap.data()['YSnqXqyyHra9WJ4qJGRZ'] !== undefined? totalStatisticsSnap.data()['YSnqXqyyHra9WJ4qJGRZ']:(totalStatisticsSnap.data()['r6V568cm0yGwDfQ8vooW'] !== undefined? totalStatisticsSnap.data()['r6V568cm0yGwDfQ8vooW']:0))
      setTotalRefusing(totalStatisticsSnap.data()['R8z3aEf9Huk2BL0hGLLt'] !== undefined? totalStatisticsSnap.data()['R8z3aEf9Huk2BL0hGLLt']:(totalStatisticsSnap.data()['cvHrGEJ8xfCZ9ig9AHBl'] !== undefined? totalStatisticsSnap.data()['cvHrGEJ8xfCZ9ig9AHBl']:0))
      
    }
    
    getStatistics();
    // setReusableCup(totalCollectItems['AESpVawGP202Tg4QOmvH'] !== undefined? totalCollectItems['AESpVawGP202Tg4QOmvH']:0)
  }, [users]);

  return (
    
    <>
        <Col>
          <Row justify="space-around" align="middle">
            <Content style={{ margin: '40px 16px 0', width: 1000, height: 273 }}>
              <div className="site-layout-background" style={{ padding: 40, height: '100%'}}>
                <div style={{display: "flex", alignItems:'center'}}>
                  <UserOutlined style={{width: 50, height: 50, fontSize: '50px'}}/>
                  <Title level={3} style={{position:'relative', top:0, left:50, margin: 0}}>이용자 수</Title>
                </div>
                  {/* <p style={{ marginTop: '70px'}}>이용자</p>
                  <Title>{totalUsers}</Title> */}
                  <Row justify="space-around" style={{ textAlign: 'center' }}>
                  <Col span={4}>
                    <p style={{ marginTop: '70px'}}>{month}월 신규 가입자 수</p>
                    <p style={{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(monthUsers)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px'}}>실제 이용자 수</p>
                    <p style={{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(realUsers)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px'}}>전월 대비 증감</p>
                    <p style={{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(variationUsers)}</p>
                  </Col>
                </Row>
              </div>
            </Content> 
          </Row>

          <Row>
            <Content style={{ margin: '40px 16px 0', width: 500, height: 370 }}>
              <div className="site-layout-background" style={{ padding: 40, height: '100%' }}>
                <div style={{display: "flex", alignItems:'center'}}>
                  <RestOutlined style={{width: 50, height: 50, fontSize: '50px'}}/>
                  <Title level={3} style={{position:'relative', top:0, left:50, margin: 0}}>적립 현황</Title>
                </div>
                  <Row justify="space-around" style={{ textAlign: 'center' }}>
                    <Col>
                      <p style={{ marginTop: '70px'}}>{month}월 적립</p>
                      <p style= {{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(monthCollect)}</p>
                    </Col>
                    <Col>
                      <p style={{ marginTop: '70px'}}>총 적립</p>
                      <p style= {{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(totalCollect)}</p>
                    </Col>
                    <Col>
                      <p style={{ marginTop: '70px'}}>스코어</p>
                      <p style= {{fontSize:'34px', fontWeight:'600'}}>
                        {new Intl.NumberFormat().format(totalReusableCup * 10 + totalReusablePacking * 12 + totalReusableContainer * 15 + totalRefillContainer * 20 + totalRefusing * 5)}
                      </p>
                    </Col>
                    <Col>
                      <p style={{ marginTop: '70px'}}>코인</p>
                      <p style= {{fontSize:'34px', fontWeight:'600'}}>
                        {new Intl.NumberFormat().format((totalReusableCup * 10 + totalReusablePacking * 12 + totalReusableContainer * 15 + totalRefillContainer * 20 + totalRefusing * 5) * 10)}
                      </p>
                    </Col>
                  </Row>
              </div>
            </Content> 
            <Content style={{ margin: '40px 16px 0', width: 500, height: 370 }}>
              <div className="site-layout-background" style={{ padding: 40, height: '100%', }}>
                <Title level={3}>품목별 적립수</Title>
                <Row justify="space-around" style={{ textAlign: 'center' }}>
                  <Col span={3}>
                    <p style={{ marginTop: '85px', height:'20px' }}> </p>
                    <p style={{ height:'70px' }}>{month}월 적립</p>
                    <p style={{ height:'70px' }}>총 적립</p>
                  </Col>
{/*
R8z3aEf9Huk2BL0hGLLt // dev 거절하기
cvHrGEJ8xfCZ9ig9AHBl // real
UV6iYNI37JZOtxZw7Wmc 재사용 포장재
T3cJr0cogVeKsbERcPlj
YSnqXqyyHra9WJ4qJGRZ 리필 용기
r6V568cm0yGwDfQ8vooW
dgwCZM6hNX7jKUdQc8sW 다회 용기
hjzsUXGds7dcqJyQYQzr
rsioVX3qv0VX2AF3AlFJ 다회용 컵
AESpVawGP202Tg4QOmvH
*/}        
                  <Col span={4}>
                    <p style={{ marginTop: '70px', textAlign:'right'}}>다회용컵</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{monthCollectItems['rsioVX3qv0VX2AF3AlFJ'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['rsioVX3qv0VX2AF3AlFJ']):(monthCollectItems['AESpVawGP202Tg4QOmvH'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['AESpVawGP202Tg4QOmvH']):0)}</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{new Intl.NumberFormat().format(totalReusableCup)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px', textAlign:'right'}}>재사용포장재</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{monthCollectItems['UV6iYNI37JZOtxZw7Wmc'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['UV6iYNI37JZOtxZw7Wmc']):(monthCollectItems['T3cJr0cogVeKsbERcPlj'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['T3cJr0cogVeKsbERcPlj']):0)}</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{new Intl.NumberFormat().format(totalReusablePacking)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px', textAlign:'right'}}>다회용기</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{monthCollectItems['dgwCZM6hNX7jKUdQc8sW'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['dgwCZM6hNX7jKUdQc8sW']):(monthCollectItems['hjzsUXGds7dcqJyQYQzr'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['hjzsUXGds7dcqJyQYQzr']):0)}</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{new Intl.NumberFormat().format(totalReusableContainer)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px', textAlign:'right'}}>리필용기</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{monthCollectItems['YSnqXqyyHra9WJ4qJGRZ'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['YSnqXqyyHra9WJ4qJGRZ']):(monthCollectItems['r6V568cm0yGwDfQ8vooW'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['r6V568cm0yGwDfQ8vooW']):0)}</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{new Intl.NumberFormat().format(totalRefillContainer)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px', textAlign:'right'}}>거절하기</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{monthCollectItems['R8z3aEf9Huk2BL0hGLLt'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['R8z3aEf9Huk2BL0hGLLt']):(monthCollectItems['cvHrGEJ8xfCZ9ig9AHBl'] !== undefined? new Intl.NumberFormat().format(monthCollectItems['cvHrGEJ8xfCZ9ig9AHBl']):0)}</p>
                    <p style={{fontSize:'34px', fontWeight:'600', textAlign:'right'}}>{new Intl.NumberFormat().format(totalRefusing)}</p>
                  </Col>
                </Row>
              </div>
            </Content> 
          </Row>
          <Row justify="space-around" align="middle">
            <Content style={{ margin: '40px 16px 0', width: 1000, height: 273 }}>
              <div className="site-layout-background" style={{ padding: 40, height: '100%'}}>
                <div style={{display: "flex", alignItems:'center'}}>
                  <ShopOutlined style={{width: 50, height: 50, fontSize: '50px'}}/>
                  <Title level={3} style={{position:'relative', top:0, left:50, margin: 0}}>제휴 가게</Title>
                </div>
                <Row justify="space-around" style={{ textAlign: 'center' }}>
                  <Col span={4}>
                    <p style={{ marginTop: '70px'}}>{month}월 신규 제휴 가게 수</p>
                    <p style={{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(monthShops)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px'}}>총 제휴 가게 수</p>
                    <p style={{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(totalShops)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px'}}>누적 적립 횟수</p>
                    <p style={{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(totalCollectHistory)}</p>
                  </Col>
                  <Col span={4}>
                    <p style={{ marginTop: '70px'}}>월별 적립 횟수</p>
                    <p style={{fontSize:'34px', fontWeight:'600'}}>{new Intl.NumberFormat().format(monthCollectHistory)}</p>
                  </Col>
                </Row>
              </div>
            </Content> 
          </Row>
        </Col>
    </>
  );
}

export default Dashboard;