const firebaseConfig = {
  // Dev
  //     apiKey: "AIzaSyAB9uNd_hDebX3Fpcnu89fn7yaEJOpb0pk",
  //     authDomain: "linkwithexpo.firebaseapp.com",
  //     databaseURL: "https://linkwithexpo-default-rtdb.firebaseio.com",
  //     projectId: "linkwithexpo",
  //     storageBucket: "linkwithexpo.appspot.com",
  //     messagingSenderId: "1065020310463",
  //     appId: "1:1065020310463:web:48b057237d8cc749b8170a",
  //     measurementId: "G-3GZZWN8S39",
  //     itemCupId: 'rsioVX3qv0VX2AF3AlFJ',
  //     middleHost: "https://linkwithexpo.web.app",
  // Real
  apiKey: "AIzaSyD2YCaz0uPDmtz5e3Jho9CIdaZ7uiBF_8k",
  authDomain: "zero-club.firebaseapp.com",
  databaseURL: "https://zero-club.firebaseio.com",
  projectId: "zero-club",
  storageBucket: "zero-club.appspot.com",
  messagingSenderId: "617879114314",
  appId: "1:617879114314:web:c54bab738b51f807b88cb3",
  measurementId: "G-N1ZRLWR8RW",
  itemCupId: 'AESpVawGP202Tg4QOmvH',
  middleHost: "https://zero-club.web.app/",
};

export default firebaseConfig