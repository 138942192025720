import React from "react";
import {Button} from "antd";
// import {signOut} from "firebase/auth";
import firebase from 'firebase/compat/app';

const SignFail = () => {
  return(
    <div className="wrapper">
      잘못된 접근입니다.
      <br/><br/>
      <Button onClick={()=>{
        firebase.auth().signOut()
      }}>로그인</Button>
    </div>
  )
}
export default SignFail