import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Input, Button, Space, Tag, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { selectGoodsHistories } from "../redux/reducers/goods_history";
import { getUsersSubCollection, selectUsers } from "../redux/reducers/users";
import { getGoods, selectGoods } from "../redux/reducers/goods";

const UserGoods = (props) => {
  const goodsHistories = useSelector(selectGoodsHistories);
  const users = useSelector(selectUsers);
  const goods = useSelector(selectGoods);

  const [datas, setDatas] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const updateData = useCallback(data => {
    if(goods === null) dispatch(getGoods())
    let newData = [];
    for (let i = 0; i < data.size; i++) {
      const good = data.docs[i]
      const userGoods = goodsHistories[good.id]
      let status;
      if (userGoods !== undefined && goods !== null) {
        if (userGoods.data().status === 'enable') status = '미사용'
        else if (userGoods.data().status === 'used') status = '사용 완료'
        else status = '기간 만료'
        newData.push({
          id: good.id,
          status: status,
          name: goods[userGoods.data().goods_id] === undefined ? '': goods[userGoods.data().goods_id].data().name,
          coin: userGoods.data().coin,
          create: userGoods.data().create.toDate(),
          time: userGoods.data().create.toDate().toLocaleTimeString('it-IT'),
          update: userGoods.data().status === 'used'? userGoods.data().update.toDate(): "",
          updateTime: userGoods.data().status === 'used'? userGoods.data().update.toDate().toLocaleTimeString('it-IT'): "",
        })
      }
    }
    setDatas(newData)
    setLoading(false)
  }, [dispatch, goodsHistories, goods])
  
  useEffect(() => {
    async function loadSubCollection() {
      const user = users ? users[props?.id] : null
      // if(goods === null) dispatch(getGoods())
      if(user !== null) {
        setLoading(true)
        if(user.goods===null){
          const action = await dispatch( getUsersSubCollection( {id: props.id, subCollection: "goods"} ) )
          updateData(action.payload)
        } else {
          updateData(user.goods)
        }
      }
    }
    loadSubCollection();
  }, [dispatch, props, updateData, users]);

  const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };  

  const columns = [
    {
      title: '사용 상태',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      ...getColumnSearchProps('status'),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend'],
      render: (status)=> {
        let color = 'default'
        if (status === '사용 완료') {
        color = 'green';
        } else if (status === '기간 만료') {
        color = 'red';
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: '상품 이름',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '사용 코인',
      dataIndex: 'coin',
      key: 'coin',
      sorter: (a, b) => a.coin - b.coin,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '잔여 코인',
      dataIndex: 'coin',
      key: 'coin',
      sorter: (a, b) => a.coin - b.coin,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '구매 날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '구매 시간',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '사용 날짜',
      dataIndex: 'update',
      key: 'update',
      sorter: (a, b) => a.update - b.update,
      sortDirections: ['descend', 'ascend'],
      render: (update) => {
        if(update !== "")
          return update.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '사용 시간',
      dataIndex: 'updateTime',
      key: 'updateTime',
    },
  ];
  return (
    <div className="site-layout-background" style={{ padding: 24, height: '100%', marginTop: 0 }}>
      <Spin tip="Loading..." spinning={loading} >
        <Table columns={columns} dataSource={datas} style={{ marginTop: '30px' }} rowKey={record => record.id}/>
      </Spin>
    </div>   
  );
}

export default UserGoods;