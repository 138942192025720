import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getGoodsHistories = createAsyncThunk(
  "getGoodstHistories",
  async (params, { dispatch, getState }) => {
    console.log("call getGoodsHistories")
    const goodsHistoriesRef = collection(db, "goods_history")
    const q = query(goodsHistoriesRef, orderBy("create", "desc"));
    const goodsHistoriesSnap = await getDocs(q)
    dispatch(setGoodsHistories(goodsHistoriesSnap));
  }
);

const initialState = {
  docs: null,
};

const goodsHistories = createSlice({
  name: "goodsHistories",
  initialState,
  reducers: {
    setGoodsHistories: (state, action) => {
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp
    },

  },
});

//actions
export const {
    setGoodsHistories,
} = goodsHistories.actions;

//selectors
export const selectGoodsHistories = (state) => state.goodsHistories.docs;

export default goodsHistories.reducer;
