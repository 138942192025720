import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Input, Button, Space, Tag, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {getCollectHistorySubCollection, selectCollectHistories, selectCollectHistoriesItems} from "../redux/reducers/collect_history";
import { selectItems } from "../redux/reducers/items";
import { selectShops } from "../redux/reducers/shops";
import {getUsersSubCollection, selectUsers} from "../redux/reducers/users";

const UserCollect = (props) => {
  const collectHistories = useSelector(selectCollectHistories);
  const collectHistoriesItems = useSelector(selectCollectHistoriesItems);
  const users = useSelector(selectUsers);
  const shops = useSelector(selectShops);
  const items = useSelector(selectItems);
  
  const [datas, setDatas] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const updateData = useCallback( async data => {
    let newData = [];
    for (let i = 0; i < data.size; i++) {
      const collect = data.docs[i]
      const userCollect = collectHistories[collect.id]
      let newItems = [];
      let quantity = null;

      const collectHistoriesLength = Object.keys(collectHistories).length
      let count = 0
      /* let itemSnap = userCollect.collect_items
      if(itemSnap===null) {
        const action = await dispatch( getCollectHistorySubCollection({id: collect.id, subCollection: "collect_items"}) )
        itemSnap = action.payload
      } */
      if (userCollect !== undefined) {
        /* itemSnap.forEach((item) => {
          newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
        }) */
        const idx = newData.push({
          id: collect.id,
          shopName: shops[userCollect.data().shop_id] !== undefined ? shops[userCollect.data().shop_id].data().name: '',
          items: newItems,
          quantity: quantity,
          score: userCollect.data().score,
          create: userCollect.data().create.toDate(),
          time: userCollect.data().create.toDate().toLocaleTimeString('it-IT'),
        })

        if (!collectHistoriesItems[collect.id]) {
          dispatch( getCollectHistorySubCollection( {id: collect.id, subCollection: "collect_items"} ) ).then((data)=>{
            data.payload.docs?.forEach((item)=>{
                newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
                quantity = quantity + item.data().quantity
              // console.log(items[item.id].data().name)
            })
            newData[idx-1]["items"] = newItems
            newData[idx-1]["quantity"] = quantity
          })
        } else {
          collectHistoriesItems[collect.id]?.forEach((item)=>{
            newItems.push({name: items[item.id].data().name, quantity:item.data().quantity})
            quantity = quantity + item.data().quantity
          })
          newData[idx-1]["items"] = newItems
          newData[idx-1]["quantity"] = quantity
        }

        count++
      }
    }
    setTimeout(()=>{
      setDatas(newData)
      setLoading(false)
    },500)
  }, [collectHistories, dispatch, items, shops ])

  useEffect(() => {
    async function loadSubCollection() {
      const user = users ? users[props?.id] : null
      if(user !== null) {
        setLoading(true)
        if(user.collect===null){
          const action = await dispatch( getUsersSubCollection( {id: props.id, subCollection: "collect"} ) )
          updateData(action.payload)
        } else {
          updateData(user.collect)
        }
      }
    }
    loadSubCollection();
  }, [dispatch, props, updateData, users]);
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };  

  const columns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
      width: '1%',
      render: (text, record, index) => {
        return (
          index+1
        );
      }
    },
    {
      title: '적립 가게',
      dataIndex: 'shopName',
      key: 'shopName',
      width: '20%',
      ...getColumnSearchProps('shopName'),
      sorter: (a, b) => a.shopName.length - b.shopName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '적립 품목/개수',
      dataIndex: 'items',
      key: 'items',
      width: '30%',
      sorter: (a, b) => a.items.length - b.items.length,
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('items'),
      render: (items)=> {
        return (
          <>
          {items.map(item => (
            <Tag color="magenta" key={item.name}>
              {item.name} +{item.quantity}
            </Tag>
            // <p>{item.quantity}개</p>
          ))}
          </>
          );
      },
    },
    {
      title: '획득 스코어/코인',
      dataIndex: 'score',
      key: 'score',
      width: '20%',
      sorter: (a, b) => a.score - b.score,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '날짜',
      dataIndex: 'create',
      key: 'create',
      sorter: (a, b) => a.create - b.create,
      sortDirections: ['ascend'],
      render: (create) => {
        return create.toLocaleDateString("fr-CA")
      }
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
    },
  ];
  return (
    <div className="site-layout-background" style={{ padding: 24, height: '100%', marginTop: 0 }}>
      <Spin tip="Loading..." spinning={loading} >
        <Table columns={columns} dataSource={datas} style={{ marginTop: '30px' }} rowKey={record => record.id}/>
      </Spin>
    </div>   
  );
  
}

export default UserCollect;