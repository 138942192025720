import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import {collection, deleteDoc, doc, getDocs, serverTimestamp, setDoc, updateDoc,} from "firebase/firestore";
import {db} from "../FirebaseApp";
import FileManager from "./FileManager";
import {useDispatch, useSelector} from "react-redux";
import {getShopsSubCollection, selectShopHashtags, selectShopItems, selectShops,} from "../redux/reducers/shops";
import {selectItems} from "../redux/reducers/items";
import {selectHashtags} from "../redux/reducers/hashtags";
import zones from "../zones.json";
import {handleInputPhoneNember} from "../utils/phoneNumber";
import QRCode from "react-qr-code";
import {cyan} from 'console-log-colors';

const saveSvgAsPng = require("save-svg-as-png");
const imageOptions = {
  scale: 5,
  encoderOptions: 1,
  backgroundColor: "white",
};

const { Content } = Layout;
const { TextArea, Search } = Input;

const zoneOptions = zones.data.map((zone) => {
  return {
    value: zone.adm_nm,
  };
});
const zoneList = zones.data;
let shopZone = [];
let branchID;
let beforeBranchID;

function onChange(value) {
  console.log(value);
}
const divisionList = [
  { label: "미가입", value: "none" },
  { label: "개별", value: "individual" },
  { label: "클러스터1", value: "cluster1" },
  { label: "클러스터2", value: "cluster2" },
];
const publishList = [
  { label: "공개", value: "public" },
  { label: "비공개", value: "private" },
  { label: "숨김", value: "protect" },
];

const ShopModify = (props) => {
  const dispatch = useDispatch();
  const shops = useSelector(selectShops);
  const shopItems = useSelector(selectShopItems);
  const shopHashtags = useSelector(selectShopHashtags);
  const items = useSelector(selectItems);
  const hashtags = useSelector(selectHashtags);

  // const [id, setId] = useState('');
  const [name, setName] = useState("");
  const [summary, setSummary] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  // const [zone, setZone] = useState([]);
  const [adm_nm, setAdm_nm] = useState("");
  const [division, setDivision] = useState("");
  const [publish, setPublish] = useState("");
  const [checkedItem, setCheckedItem] = useState([]);
  const [checkedHashtag, setCheckedHashtag] = useState([]);
  const [pin, setPin] = useState("");
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState("");
  const [info, setInfo] = useState("");
  const [tip, setTip] = useState("");
  const [itemList, setItemList] = useState([]);
  const [hashtagList, setHashtagList] = useState([]);
  const [files, setFiles] = useState(null);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [type, setType] = useState([]);
  const [initial_stock, setInitialStock] = useState([]);
  const [current_stock, setCurrentStock] = useState([]);

  let updating = false;

  const formRef = useRef();
  const fileRef = useRef();
  const qrcodeRef = useRef();
  // const formRef = React.createRef(null);
  // const fileRef = React.createRef();

  const history = useHistory();
  const id = props.match.params.id;

  const updateData = useCallback(async () => {
    console.log(">>>>>>>>> updating: " + updating);
    if (shops === null || updating) return;
    const shop = shops[id];
    if (shop === undefined) {
      history.push({ pathname: "/shop" });
      return;
    }

    // 기본 정보 등록
    const data = shop.data()
    setName(data.name);
    setSummary(data.summary);
    setPin(data.pin);
    handleInputPhoneNember(data.phone, setPhone);
    setAddress(data.address);
    setDivision(data.division);
    setPublish(
      data.publish === undefined ? "private" : data.publish
    );
    setUrl(data.url);
    setOpen(data.open);
    setTip(data.tip);
    setInfo(data.info);
    setAdm_nm(data.adm_nm);
    setInitialStock(data.initial_stock);
    setCurrentStock(data.current_stock);
    shopZone = zoneList.filter((zone) => zone.adm_nm === data.adm_nm);

    // 파일 등록
    const filesRef = collection(db, "shops", id, "files");
    setFiles(filesRef);

    // 선택한 품목
    let newItems = []
    let itemDocs
    if (!shopItems[id]) {
      const getShopItems = await dispatch(getShopsSubCollection({ id: id, subCollection: "items" }))
      itemDocs = getShopItems.payload.docs
    } else {
      itemDocs = shopItems[id].docs
    }
    for (const item of itemDocs) {
      newItems.push(item.id)
    }
    setCheckedItem(newItems)

    // 선택한 해시태그
    let newHashtags = []
    let hashtagDocs
    if (!shopHashtags[id]) {
      const getShopHashtags = await dispatch(getShopsSubCollection({ id: id, subCollection: "hashtags" }))
      hashtagDocs = getShopHashtags.payload.docs
    } else {
      hashtagDocs = shopHashtags[id].docs
    }
    for (const hashtag of hashtagDocs) {
      newHashtags.push(hashtag.id)
    }
    setCheckedHashtag(newHashtags)

    // 가게 유형
    let shopType = [];
    if (hashtags !== null && hashtags[data.type] !== undefined) {
      shopType.push(data.type);
      setType(shopType);
    }

    // 품목 목록
    let tempItems = [];
    for (const itemsKey in items) {
      tempItems.push({
        value: items[itemsKey].data().id,
        label: items[itemsKey].data().name,
      });
    }
    setItemList(tempItems);

    // 해시태그 목록
    let tempHashtags = [];
    for (const hashtagsKey in hashtags) {
      tempHashtags.push({
        value: hashtags[hashtagsKey].data().id,
        label: hashtags[hashtagsKey].data().hashtag,
      });
    }
    setHashtagList(tempHashtags);
  }, [dispatch, history, items, hashtags, shops, id]);

  useEffect(() => {
    if (shops !== null && !updating) updateData();
  }, [shops, updateData]);

  const onSelect = (value) => {
    shopZone = zoneList.filter((zone) => zone.adm_nm === value);
    console.log(shopZone);
  };

  const modifyConfirm = (value) => {
    console.log("name: " + name);
    console.log("before user ID: " + beforeBranchID);
    console.log("update user ID: " + branchID);

    Modal.confirm({
      title: "가게 정보를 수정하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "수정",
      cancelText: "취소",
      async onOk() {
        updating = true;
        const id = props.match.params.id;
        const docRef = doc(db, "shops", id);

        console.log("%c items: ", "color: orange", value.items)
        console.log("%c hashtags: ", "color: blue", value.hashtags)

        // update items
        await deleteSubCollection(docRef, "items");
        for (let i = 0; i < value.items.length; i++) {
          const itemRef = doc(docRef, "items", value.items[i]);
          await setDoc(itemRef, {
            create: serverTimestamp(),
          });
        }
        // update hashtags
        await deleteSubCollection(docRef, "hashtags");
        for (let i = 0; i < value.hashtags.length; i++) {
          const itemRef = doc(docRef, "hashtags", value.hashtags[i]);
          await setDoc(itemRef, {
            create: serverTimestamp(),
          });
        }
        // update files
        await deleteSubCollection(docRef, "files");
        const idList = await fileRef.current.addFiles();

        console.log(cyan.bgRed.bold("update files"))
        console.log(idList)

        for (const idListKey in idList) {
          const data = idList[idListKey];
          if (data) {

            const name = data.name;
            const uri = data.uri;
            const fileRef = doc(docRef, "files", data.id);

            console.log(data)
            console.log(fileRef)

            await setDoc(fileRef, {
              id: data.id,
              create: serverTimestamp(),
              name,
              uri,
            });

          }
        }

        // update shop
        await updateDoc(docRef, {
          id: docRef.id,
          name: value.name,
          summary: value.summary === undefined ? "" : value.summary,
          phone: value.phone,
          address: value.address,
          publish: value.publish.join(),
          division: value.division.join(),
          pin: value.pin === undefined ? "" : value.pin,
          open: value.open === undefined ? "" : value.open,
          url: value.url === undefined ? "" : value.url,
          info: value.info === undefined ? "" : value.info,
          tip: value.tip === undefined ? "" : value.tip,
          update: serverTimestamp(),
          sido: shopZone[0].sido,
          sgg: shopZone[0].sgg,
          sggnm: shopZone[0].sggnm,
          adm_cd2: shopZone[0].adm_cd2,
          adm_nm: shopZone[0].adm_nm,
          type: value.type[0] === undefined ? "" : value.type[0],
          items: value.items,
          hashtags: value.hashtags,
          initial_stock:
            value.initial_stock === undefined ? "" : value.initial_stock,
          current_stock:
            value.current_stock === undefined ? "" : value.current_stock,
        });

        updating = false;
        history.push({ pathname: "/shop" });
      },
    });
  };

  const deleteSubCollection = async (docRef, sub) => {
    const q = collection(docRef, sub);
    const docs = await getDocs(q);
    for (let i = 0; i < docs.size; i++) {
      const d = docs.docs[i];
      await deleteDoc(d.ref);
    }
    console.log(sub + " - collection all docs deleted");
  };

  const deleteConfirm = () => {
    Modal.confirm({
      title: "가게 정보를 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        const id = props.match.params.id;
        const docRef = doc(db, "shops", id);
        await deleteSubCollection(docRef, "items");
        await deleteSubCollection(docRef, "hashtags");
        await deleteSubCollection(docRef, "files");
        await deleteSubCollection(docRef, "savings");
        await fileRef.current.deleteAll();
        await deleteDoc(docRef);

        history.push({ pathname: "/shop" });
      },
    });
  };

  const showModal = () => {
    setQrModalVisible(true);
  };

  const handleOk = () => {
    setQrModalVisible(false);
  };

  const handleCancel = () => {
    setQrModalVisible(false);
  };

  const downloadQR = () => {
    saveSvgAsPng.saveSvgAsPng(
      document.getElementById("qrcode"),
      name + "_" + id + ".png",
      imageOptions
    );
  };

  return (
    <Content style={{ margin: "0 1px", height: "100%" }}>
      <Modal
        title="QR"
        visible={qrModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>id : {id}</p>
        <QRCode id={"qrcode"} value={id} />
        <br />
        <Button onClick={downloadQR}>Download</Button>
      </Modal>
      <div
        className="site-layout-background"
        style={{ padding: 24, height: "100%", marginTop: 0 }}
      >
        <div style={{ padding: 50, height: "100%" }}>
          <Form
            ref={formRef}
            layout="vertical"
            name="form_in_modal"
            fields={[
              {
                name: ["name"],
                value: name,
              },
              {
                name: ["summary"],
                value: summary,
              },
              {
                name: ["pin"],
                value: pin,
              },
              {
                name: ["phone"],
                value: phone,
              },
              {
                name: ["address"],
                value: address,
              },
              {
                name: ["publish"],
                value: [publish],
              },
              {
                name: ["division"],
                value: [division],
              },
              {
                name: ["items"],
                value: checkedItem,
              },
              {
                name: ["hashtags"],
                value: checkedHashtag,
              },
              {
                name: ["url"],
                value: url,
              },
              {
                name: ["open"],
                value: open,
              },
              {
                name: ["info"],
                value: info,
              },
              {
                name: ["tip"],
                value: tip,
              },
              {
                name: ["zone"],
                value: adm_nm,
                // value: zone[0].temp,
                // value: zoneList.filter(zone => (zone.adm_nm === adm_nm))[0].adm_nm,
              },
              {
                name: ["type"],
                value: type,
              },
              {
                name: ["initial_stock"],
                value: initial_stock,
              },
              {
                name: ["current_stock"],
                value: current_stock,
              },
            ]}
          >
            <Descriptions title="" bordered>

              {/*---------------------------------------------------------*/}
              
              <Descriptions.Item label="가게 이름 *" span={2}>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "가게 이름을 입력하세요." },
                  ]}
                >
                  <Input placeholder="가게 이름" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="공개 여부 *" span={1}>
                <Form.Item
                  name="publish"
                  rules={[{ required: true, message: '공개 여부를 선택하세요.' }]}
                >
                  <Cascader
                    options={publishList}
                    onChange={onChange}
                    placeholder="선택"
                  />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="컵공유 *" span={2}>
                <Form.Item
                  name="division"
                  rules={[{ required: true, message: "컵공유 유형을 선택하세요." }]}
                >
                  <Cascader
                    options={divisionList}
                    onChange={onChange}
                    placeholder="선택"
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="최초 재고" span={1}>
                <Form.Item name="initial_stock">
                  <Input maxLength="6" />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="핀번호" span={2}>
                <Row>
                  <Col>
                    <Form.Item name="pin">
                      <Input maxLength="4" readOnly={true} />
                    </Form.Item>
                  </Col>
                  <Col offset={1}>
                    <Button type="primary" onClick={showModal}>
                      QR 생성
                    </Button>
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label="현재 재고" span={1}>
                <Form.Item name="current_stock">
                  <Input maxLength="6" />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="동네 *" span={2}>
                <Form.Item
                  name="zone"
                  rules={[{ required: true, message: "동네를 선택하세요." }]}
                >
                  <AutoComplete
                    options={zoneOptions}
                    onSelect={onSelect}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    <Search placeholder="검색" />
                  </AutoComplete>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="가게 유형" span={1}>
                <Form.Item
                  name="type"
                >
                  <Cascader
                    options={hashtagList}
                    onChange={onChange}
                    placeholder="선택"
                  />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="주소" span={3}>
                <Form.Item name="address">
                  <Input placeholder="00시 00동 00-00" />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="전화번호" span={1}>
                <Form.Item name="phone">
                  <Input placeholder="000-0000-0000" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사이트" span={1}>
                <Form.Item name="url">
                  <Input placeholder="http://" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="영업 시간" span={1}>
                <Form.Item name="open">
                  <Input placeholder="00:00 ~ 00:00" />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="품목" span={3}>
                <Form.Item
                  name="items"
                >
                  <Checkbox.Group options={itemList} onChange={onChange} />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="해시태그" span={3}>
                <Form.Item
                  name="hashtags"
                >
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Tags Mode"
                    options={hashtagList}
                    onChange={onChange}
                  />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="한줄 요약" span={3}>
                <Form.Item name="summary">
                  <Input placeholder="한줄 요약" />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="상세 정보" span={3}>
                <Form.Item name="info">
                  <TextArea showCount />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="제로웨이스트 팁" span={3}>
                <Form.Item name="tip">
                  <TextArea showCount />
                </Form.Item>
              </Descriptions.Item>

              {/*---------------------------------------------------------*/}

              <Descriptions.Item label="사진 추가하기" span={3}>
                <FileManager
                  ref={fileRef}
                  path={"shop"}
                  max={5}
                  files={files}
                />
              </Descriptions.Item>


            </Descriptions>
          </Form>

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Button
              onClick={deleteConfirm}
              danger
            >가게 삭제</Button>
            <Row justify="end" style={{ width: "100%" }}>
              <Space>
                <Link to="/shop">
                  <Button>목록</Button>
                </Link>
                <Button
                  onClick={() => {
                    formRef.current
                      .validateFields()
                      .then((values) => {
                        modifyConfirm(values);
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                  type="primary"
                >
                  수정
                </Button>
              </Space>
            </Row>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ShopModify;
