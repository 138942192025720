import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Modal,
  Descriptions,
  Row,
  Col,
  Checkbox,
  Form,
  Space,
  AutoComplete,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  collection,
  doc,
  getDocs,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../FirebaseApp";
import FileManager from "./FileManager";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShops,
  getBranch,
  getShopsSubCollection,
  selectShopItems,
  selectShopHashtags,
} from "../redux/reducers/shops";
import { selectItems } from "../redux/reducers/items";
import { selectHashtags } from "../redux/reducers/hashtags";
import { selectUsers } from "../redux/reducers/users";
import zones from "../zones.json";
import { useHistory } from "react-router-dom";
import { handleInputPhoneNember } from "../utils/phoneNumber";
import QRCode from "react-qr-code";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const { Content } = Layout;
const { TextArea, Search } = Input;

const zoneOptions = zones.data.map((zone) => {
  return {
    value: zone.adm_nm,
  };
});
const zoneList = zones.data;
let shopZone = [];

function onChange(value) {
  console.log(value);
}

const BranchInfo = (props) => {
  const dispatch = useDispatch();
  const shops = useSelector(selectShops);
  const shopItems = useSelector(selectShopItems);
  const shopHashtags = useSelector(selectShopHashtags);
  const items = useSelector(selectItems);
  const hashtags = useSelector(selectHashtags);
  const users = useSelector(selectUsers);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [summary, setSummary] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  // const [zone, setZone] = useState([]);
  const [adm_nm, setAdm_nm] = useState("");
  const [checkedItem, setCheckedItem] = useState([]);
  const [checkedHashtag, setCheckedHashtag] = useState([]);
  const [pin, setPin] = useState("");
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState("");
  const [info, setInfo] = useState("");
  const [tip, setTip] = useState("");
  const [itemList, setItemList] = useState([]);
  const [hashtagList, setHashtagList] = useState([]);
  const [files, setFiles] = useState(null);
  const [qrModalVisible, setQrModalVisible] = useState(false);

  const formRef = useRef();
  const fileRef = useRef();
  // const formRef = React.createRef(null);
  // const fileRef = React.createRef();

  const history = useHistory();

  useEffect(() => {
    async function updateData() {
      if (shops === null) return;

      const id = props.shopid;
      if (Object.keys(shops).length !== 0) {
        const shop = shops;
        if (shop === undefined) {
          history.push({ pathname: "/info" });
          return;
        }

        setId(id);
        const docRef = doc(db, "shops", id);
        const filesRef = collection(docRef, "files");
        setFiles(filesRef);

        setName(shop.data().name);
        setSummary(shop.data().summary);
        setPin(shop.data().pin);
        handleInputPhoneNember(shop.data().phone, setPhone);
        setAddress(shop.data().address);
        setUrl(shop.data().url);
        setOpen(shop.data().open);
        setTip(shop.data().tip);
        setInfo(shop.data().info);
        setAdm_nm(shop.data().adm_nm);
        shopZone = zoneList.filter(
          (zone) => zone.adm_nm === shop.data().adm_nm
        );

        let newItems = [];
        if (!shopItems[id]) {
          dispatch(
            getShopsSubCollection({ id: id, subCollection: "items" })
          ).then((data) => {
            data.payload.docs?.forEach((item) => {
              newItems.push(item.id);
            });
            setCheckedItem(newItems);
          });
        } else {
          shopItems[id]?.forEach((item) => {
            newItems.push(item.id);
          });
          setCheckedItem(newItems);
        }

        let newHashtags = [];
        if (!shopHashtags[id]) {
          dispatch(
            getShopsSubCollection({ id: id, subCollection: "hashtags" })
          ).then((data) => {
            console.log(data);
            data.payload.docs?.forEach((tag) => {
              newHashtags.push(tag.id);
            });
            setCheckedHashtag(newHashtags);
          });
        } else {
          shopHashtags[id]?.forEach((tag) => {
            newHashtags.push(tag.id);
          });
          setCheckedHashtag(newHashtags);
        }

        /* let newItems = []
        if(shop.items === null) {
          await dispatch( getShopsSubCollection( {id: id, subCollection: "items"} ) )
        }
        shop.items.forEach((item)=>{
          newItems.push(item.id)
        })
        setCheckedItem(newItems)
        
        let newHashtags = []
        if(shop.hashtags === null) {
          await dispatch( getShopsSubCollection( {id: id, subCollection: "hashtags"} ) )
        }
        shop.hashtags.forEach((tag)=>{
          newHashtags.push(tag.id)
        })
        setCheckedHashtag(newHashtags) */

        let tempItems = [];
        for (const itemsKey in items) {
          tempItems.push({
            value: items[itemsKey].data().id,
            label: items[itemsKey].data().name,
          });
        }
        setItemList(tempItems);

        let tempHashtags = [];
        for (const hashtagsKey in hashtags) {
          tempHashtags.push({
            value: hashtags[hashtagsKey].data().id,
            label: hashtags[hashtagsKey].data().hashtag,
          });
        }
        setHashtagList(tempHashtags);
      }
    }
    updateData();
  }, [shops]);

  const onSelect = (value) => {
    shopZone = zoneList.filter((zone) => zone.adm_nm === value);
    console.log(shopZone);
  };

  const modifyConfirm = (value) => {
    Modal.confirm({
      title: "가게 정보를 수정하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "수정",
      cancelText: "취소",
      async onOk() {
        const docRef = doc(db, "shops", id);
        await updateDoc(docRef, {
          id: docRef.id,
          name: value.name,
          summary: value.summary === undefined ? "" : value.summary,
          phone: value.phone,
          address: value.address,
          pin: value.pin === undefined ? "" : value.pin,
          open: value.open === undefined ? "" : value.open,
          url: value.url === undefined ? "" : value.url,
          info: value.info === undefined ? "" : value.info,
          tip: value.tip === undefined ? "" : value.tip,
          update: serverTimestamp(),
          sido: shopZone[0].sido,
          sgg: shopZone[0].sgg,
          sggnm: shopZone[0].sggnm,
          adm_cd2: shopZone[0].adm_cd2,
          adm_nm: shopZone[0].adm_nm,
        });

        await deleteSubCollection(docRef, "items");
        for (let i = 0; i < value.items.length; i++) {
          const itemRef = doc(docRef, "items", value.items[i]);
          await setDoc(itemRef, {
            create: serverTimestamp(),
          });
        }

        await deleteSubCollection(docRef, "hashtags");
        for (let i = 0; i < value.hashtags.length; i++) {
          const itemRef = doc(docRef, "hashtags", value.hashtags[i]);
          await setDoc(itemRef, {
            create: serverTimestamp(),
          });
        }

        await deleteSubCollection(docRef, "files");
        const idList = await fileRef.current.addFiles();
        for (const idListKey in idList) {
          const data = idList[idListKey];
          const fileRef = doc(docRef, "files", data.id);
          await setDoc(fileRef, {
            id: data.id,
            create: serverTimestamp(),
            name: data.name,
          });
          // const storage = getStorage();
          // getDownloadURL(ref(storage, data.name))
          //   .then(async (url) => {
          //     await setDoc(fileRef, {
          //       id: data.id,
          //       create: serverTimestamp(),
          //       link: url,
          //     });
          //   })
          //   .catch((error) => {
          //     // Handle any errors
          //   });
        }

        dispatch(getBranch({ id: props.shopid }));
      },
    });
  };

  const deleteSubCollection = async (docRef, sub) => {
    const q = collection(docRef, sub);
    const docs = await getDocs(q);
    for (let i = 0; i < docs.size; i++) {
      const d = docs.docs[i];
      await deleteDoc(d.ref);
    }
    console.log(sub + " - collection all docs deleted");
  };

  const deleteConfirm = () => {
    Modal.confirm({
      title: "가게 정보를 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        const docRef = doc(db, "shops", id);
        await deleteSubCollection(docRef, "items");
        await deleteSubCollection(docRef, "hashtags");
        await deleteSubCollection(docRef, "files");
        await fileRef.current.deleteAll();
        await deleteDoc(docRef);
        dispatch(getBranch({ id: props.shopid }));
        history.push({ pathname: "/" });
      },
    });
  };

  const showModal = () => {
    setQrModalVisible(true);
  };

  const handleOk = () => {
    setQrModalVisible(false);
  };

  const handleCancel = () => {
    setQrModalVisible(false);
  };

  return (
    <Content style={{ margin: "0 16px", height: "100%" }}>
      <Modal
        title="QR"
        visible={qrModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>id : {id}</p>
        <QRCode value={id} />
      </Modal>
      <div
        className="site-layout-background"
        style={{ padding: 24, height: "100%", marginTop: 0 }}
      >
        <div style={{ padding: 50, height: "100%" }}>
          <Form
            ref={formRef}
            layout="vertical"
            name="form_in_modal"
            fields={[
              {
                name: ["name"],
                value: name,
              },
              {
                name: ["summary"],
                value: summary,
              },
              {
                name: ["pin"],
                value: pin,
              },
              {
                name: ["phone"],
                value: phone,
              },
              {
                name: ["address"],
                value: address,
              },
              {
                name: ["items"],
                value: checkedItem,
              },
              {
                name: ["hashtags"],
                value: checkedHashtag,
              },
              {
                name: ["url"],
                value: url,
              },
              {
                name: ["open"],
                value: open,
              },
              {
                name: ["info"],
                value: info,
              },
              {
                name: ["tip"],
                value: tip,
              },
              {
                name: ["zone"],
                value: adm_nm,
                // value: zone[0].temp,
                // value: zoneList.filter(zone => (zone.adm_nm === adm_nm))[0].adm_nm,
              },
            ]}
          >
            <Descriptions title="" bordered>
              <Descriptions.Item label="가게 이름" span={1}>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "가게 이름을 입력하세요." },
                  ]}
                >
                  <Input placeholder="가게 이름" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="전화번호" span={2}>
                <Form.Item name="phone">
                  <Input placeholder="000-0000-0000" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="핀번호" span={3}>
                <Row>
                  <Col>
                    <Form.Item name="pin">
                      <Input maxLength="4" readOnly={true} />
                    </Form.Item>
                  </Col>
                  <Col offset={1}>
                    <Button type="primary" onClick={showModal}>
                      QR 생성
                    </Button>
                  </Col>
                </Row>
              </Descriptions.Item>

              <Descriptions.Item label="품목" span={3}>
                <Form.Item
                  name="items"
                  rules={[{ required: true, message: "품목을 선택하세요." }]}
                >
                  <Checkbox.Group options={itemList} onChange={onChange} />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="주소" span={3}>
                <Form.Item name="address">
                  <Input placeholder="00시 00동 00-00" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="동네" span={3}>
                <Form.Item
                  name="zone"
                  rules={[{ required: true, message: "동네를 선택하세요." }]}
                >
                  <AutoComplete
                    options={zoneOptions}
                    onSelect={onSelect}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  >
                    <Search placeholder="검색" />
                  </AutoComplete>
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="사이트" span={1}>
                <Form.Item name="url">
                  <Input placeholder="http://" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="영업 시간" span={2}>
                <Form.Item name="open">
                  <Input placeholder="00:00 ~ 00:00" />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="해시태그" span={3}>
                <Form.Item
                  name="hashtags"
                  rules={[{ required: true, message: "해시태그를 선택하세요" }]}
                >
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Tags Mode"
                    options={hashtagList}
                    onChange={onChange}
                  />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="한줄 요약" span={3}>
                <Form.Item name="summary">
                  <Input placeholder="한줄 요약" style={{ width: "453px" }} />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="상세 정보" span={1}>
                <Form.Item name="info">
                  <TextArea showCount />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="제로웨이스트 팁" span={2}>
                <Form.Item name="tip">
                  <TextArea showCount />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사진 추가하기" span={3}>
                <FileManager
                  ref={fileRef}
                  path={"shop"}
                  max={5}
                  files={files}
                />
              </Descriptions.Item>
            </Descriptions>
          </Form>

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Button onClick={deleteConfirm}>가게 삭제</Button>
            <Row justify="end" style={{ width: "100%" }}>
              <Space>
                {/* <Link to="/shop"><Button>목록</Button></Link> */}
                <Button
                  onClick={() => {
                    formRef.current
                      .validateFields()
                      .then((values) => {
                        modifyConfirm(values);
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                  type="primary"
                >
                  수정
                </Button>
              </Space>
            </Row>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default BranchInfo;
