import {
  Avatar,
  Breadcrumb,
  Button,
  Divider,
  Layout,
  Menu,
  Modal,
  Space,
  Typography,
} from "antd";
import {
  AppstoreAddOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  GiftOutlined,
  LineChartOutlined,
  NotificationOutlined,
  NumberOutlined,
  ReadOutlined,
  RestOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { Link, Route } from "react-router-dom";
import AnnouncementAdd from "./AnnouncementAdd";
import AnnouncementInfo from "./AnnouncementInfo";
import Announcement from "./Announcement";
import UserInfo from "./UserInfo";
import User from "./User";
import CollectHistory from "./CollectHistory";
import GoodsHistory from "./GoodsHistory";
import DonateHistory from "./DonateHistory";
import ShopAdd from "./ShopAdd";
import ShopInfo from "./ShopInfo";
import Shop from "./Shop";
import GoodsAdd from "./GoodsAdd";
import GoodsInfo from "./GoodsInfo";
import Goods from "./Goods";
import DonateAdd from "./DonateAdd";
import DonateInfo from "./DonateInfo";
import Donate from "./Donate";
import Item from "./Item";
import Hashtag from "./Hashtag";
import Region from "./Region";
import Dashboard from "./Dashboard";
import React, { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { getHashtags } from "../redux/reducers/hashtags";
import { getItems } from "../redux/reducers/items";
import { useDispatch, useSelector } from "react-redux";
import { getShops } from "../redux/reducers/shops";
import { getUsers, selectUsers } from "../redux/reducers/users";
// import {getGoods} from "../redux/reducers/goods";
// import {getDonates} from "../redux/reducers/donate";
// import {getAnnouncements} from "../redux/reducers/announcement";
import { getCollectHistories } from "../redux/reducers/collect_history";
import { getGoodsHistories } from "../redux/reducers/goods_history";
import { getDonateHistories } from "../redux/reducers/donate_history";
import { db, auth } from "../FirebaseApp";
import { getDoc, doc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useHistory } from "react-router-dom";
import ShopSort from "./ShopSort";
import GoodsSort from "./GoodsSort";
import DonateSort from "./DonateSort";
import ItemSort from "./ItemSort";
import HashtagSort from "./HashtagSort";
import Bottleclub from "./Bottleclub";
import Contents from "./Contents";

const { SubMenu } = Menu;
const { Header, Footer, Sider } = Layout;
const { Text } = Typography;

const Admin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [headTitle, setHeadTitle] = useState("대시보드");
  const [breadcrumb1, setBreadcrumb1] = useState(false);
  const [breadcrumb2, setBreadcrumb2] = useState("대시보드");
  const [key, setKey] = useState(1);
  const [userName, setUserName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");

  const users = useSelector(selectUsers);

  const setState = (obj) => {
    setKey(obj.key);
    setHeadTitle(obj.headTitle);
    setBreadcrumb1(obj.breadcrumb1);
    setBreadcrumb2(obj.breadcrumb2);
  };

  function logout() {
    Modal.confirm({
      title: "로그아웃 하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "로그아웃",
      cancelText: "취소",
      onOk() {
        signOut(auth)
          .then(() => {
            history.push("/");
            // this.destroy();
          })
          .catch((error) => {});
      },
    });
  }

  useEffect(() => {
    dispatch(getHashtags());
    dispatch(getItems());
    dispatch(getShops());
    dispatch(getUsers());
    // dispatch(getGoods())
    // dispatch(getDonates())
    // dispatch(getAnnouncements())
    dispatch(getCollectHistories());
    dispatch(getGoodsHistories());
    dispatch(getDonateHistories());
  }, [dispatch]);

  useEffect(() => {
    if (!!users) {
      const user = auth.currentUser;
      const userData = users[user.uid];
      console.log("=== login user data ===");
      console.log(userData);
      setUserName(userData.data().name);

      // const getUserFile = async() => {
      //   if(userData.files !== null) {
      //     if(userData.files.docs[0]){
      //       console.log("=== user file ===")
      //       const id = userData.files.docs[0].id
      //       console.log(id)
      //       const docRef = doc(db, "files", id)
      //       const docSnap = await getDoc(docRef);
      //       if (docSnap.exists()) {
      //       console.log("Document data:", docSnap.data());
      //         const data = docSnap.data();
      //         console.log(data)
      //         const storage = getStorage();
      //         getDownloadURL(ref(storage, data.name)).then((url) => {
      //           setUserAvatar(url)
      //         })
      //         .catch((error) => {
      //           // Handle any errors
      //         });
      //       }
      //     }
      //   }
      // }
      // getUserFile();
    }
  }, [users]);

  return (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100%",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo">
          <Text style={{ color: "#FFFFFF", fontSize: "18px" }}>보틀클럽</Text>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={key}>
          <Menu.Item key="1" icon={<LineChartOutlined />}>
            <Link
              to="/"
              onClick={() =>
                setState({
                  key: "1",
                  headTitle: "대시보드",
                  breadcrumb1: "",
                  breadcrumb2: "대시보드",
                })
              }
            >
              대시보드
            </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<NotificationOutlined />}>
            <Link
              to="/announcement"
              onClick={() =>
                setState({
                  key: "3",
                  headTitle: "공지사항",
                  breadcrumb1: "",
                  breadcrumb2: "공지사항",
                })
              }
            >
              공지사항
            </Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<UserOutlined />}>
            <Link
              to="/user"
              onClick={() =>
                setState({
                  key: "2",
                  headTitle: "회원 목록",
                  breadcrumb1: "",
                  breadcrumb2: "회원",
                })
              }
            >
              회원
            </Link>
          </Menu.Item>
          <SubMenu key="sub1" icon={<ReadOutlined />} title="히스토리">
            <Menu.Item key="4">
              <Link
                to="/collect_history"
                onClick={() =>
                  setState({
                    headTitle: "히스토리",
                    breadcrumb1: "히스토리",
                    breadcrumb2: "적립",
                  })
                }
              >
                적립
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link
                to="/goods_history"
                onClick={() =>
                  setState({
                    headTitle: "히스토리",
                    breadcrumb1: "히스토리",
                    breadcrumb2: "구매",
                  })
                }
              >
                구매
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link
                to="/donate_history"
                onClick={() =>
                  setState({
                    headTitle: "히스토리",
                    breadcrumb1: "히스토리",
                    breadcrumb2: "기부",
                  })
                }
              >
                기부
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="7" icon={<ShopOutlined />}>
            <Link
              to="/shop"
              onClick={() =>
                setState({
                  headTitle: "가게",
                  breadcrumb1: "",
                  breadcrumb2: "가게",
                })
              }
            >
              가게
            </Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<ShoppingCartOutlined />}>
            <Link
              to="/goods"
              onClick={() =>
                setState({
                  headTitle: "상품",
                  breadcrumb1: "",
                  breadcrumb2: "상품",
                })
              }
            >
              상품
            </Link>
          </Menu.Item>
          <Menu.Item key="9" icon={<GiftOutlined />}>
            <Link
              to="/donate"
              onClick={() =>
                setState({
                  headTitle: "기부",
                  breadcrumb1: "",
                  breadcrumb2: "기부",
                })
              }
            >
              기부
            </Link>
          </Menu.Item>
          <Menu.Item key="10" icon={<AppstoreAddOutlined />}>
            <Link
              to="/item"
              onClick={() =>
                setState({
                  headTitle: "품목",
                  breadcrumb1: "",
                  breadcrumb2: "품목",
                })
              }
            >
              품목
            </Link>
          </Menu.Item>
          <Menu.Item key="11" icon={<NumberOutlined />}>
            <Link
              to="/hashtags"
              onClick={() =>
                setState({
                  headTitle: "해시태그",
                  breadcrumb1: "",
                  breadcrumb2: "해시태그",
                })
              }
            >
              해시태그
            </Link>
          </Menu.Item>
          <Menu.Item key="12" icon={<EnvironmentOutlined />}>
            <Link
              to="/region"
              onClick={() =>
                setState({
                  headTitle: "동네",
                  breadcrumb1: "",
                  breadcrumb2: "동네",
                })
              }
            >
              동네
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="13" icon={<RestOutlined />}><Link to="/bottleclub" onClick={() => setState({headTitle:'보틀클럽', breadcrumb1:  '보틀클럽', breadcrumb2: '히스토리'})}>보틀클럽</Link></Menu.Item> */}
          <Menu.Item key="13" icon={<RestOutlined />}>
            <Link
              to="/bottleclub"
              onClick={() =>
                setState({
                  headTitle: "컵 대여",
                  breadcrumb1: "컵 대여",
                  breadcrumb2: "히스토리",
                })
              }
            >
              컵 대여
            </Link>
          </Menu.Item>
          <Menu.Item key="14" icon={<PictureOutlined />}>
            <Link
              to="/contents"
              onClick={() =>
                setState({
                  headTitle: "콘텐츠",
                  breadcrumb1: "콘텐츠",
                  breadcrumb2: "",
                })
              }
            >
              콘텐츠
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: 200, height: "100%" }}
      >
        <Header
          className="site-layout-background"
          style={{ paddingLeft: 24, display: "flex" }}
        >
          <h2 style={{ fontWeight: 600 }}>{headTitle}</h2>
          <div style={{ position: "absolute", right: 0 }}>
            <Space size={"small"}>
              {/* <Avatar icon={<UserOutlined />} src={userAvatar}/> */}
              {userName}
            </Space>
            <Divider type="vertical" />
            <Button type="text" onClick={logout} style={{ paddingLeft: 0 }}>
              로그아웃
            </Button>
          </div>
        </Header>
        <Breadcrumb style={{ margin: "16px 16px" }}>
          <Breadcrumb.Item>보틀클럽</Breadcrumb.Item>
          <Breadcrumb.Item>{breadcrumb1}</Breadcrumb.Item>
          <Breadcrumb.Item>{breadcrumb2}</Breadcrumb.Item>
        </Breadcrumb>
        <Route exact path="/announcement/add">
          <AnnouncementAdd />
        </Route>
        <Route
          exact
          path="/announcement/info/:id"
          component={AnnouncementInfo}
        ></Route>
        <Route exact path="/announcement">
          <Announcement />
        </Route>
        <Route exact path="/user/info/:id" component={UserInfo}></Route>
        <Route exact path="/user">
          <User />
        </Route>
        <Route exact path="/collect_history">
          <CollectHistory />
        </Route>
        <Route exact path="/goods_history">
          <GoodsHistory />
        </Route>
        <Route exact path="/donate_history">
          <DonateHistory />
        </Route>
        <Route exact path="/shop/add" component={ShopAdd}></Route>
        <Route exact path="/shop/info/:id" component={ShopInfo}></Route>
        <Route exact path="/shop/sort">
          <ShopSort />
        </Route>
        <Route exact path="/shop">
          <Shop />
        </Route>
        <Route exact path="/goods/add" component={GoodsAdd}></Route>
        <Route exact path="/goods/info/:id" component={GoodsInfo}></Route>
        <Route exact path="/goods/sort">
          <GoodsSort />
        </Route>
        <Route exact path="/goods">
          <Goods />
        </Route>
        <Route exact path="/donate/add" component={DonateAdd}></Route>
        <Route exact path="/donate/info/:id" component={DonateInfo}></Route>
        <Route exact path="/donate/sort">
          <DonateSort />
        </Route>
        <Route exact path="/donate">
          <Donate />
        </Route>
        <Route exact path="/item/sort">
          <ItemSort />
        </Route>
        <Route exact path="/item">
          <Item />
        </Route>
        <Route exact path="/hashtags/sort">
          <HashtagSort />
        </Route>
        <Route exact path="/hashtags">
          <Hashtag />
        </Route>
        <Route exact path="/region">
          <Region />
        </Route>
        <Route exact path="/bottleclub">
          <Bottleclub />
        </Route>
        <Route exact path="/contents">
          <Contents />
        </Route>
        <Route exact path="/">
          <Dashboard />
        </Route>
        {/* <Content style={{ margin: '8px 16px 0', overflow: 'initial' }}>
        </Content> */}
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
};

export default Admin;
