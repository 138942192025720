import React from "react";
import { Layout, Tabs } from "antd";
import UserBookmark from "./UserShops";
import UserDonate from "./UserDonate";
import UserCollect from "./UserCollect";
import UserGoods from "./UserGoods";
import UserReviews from "./UserReviews";
import UserModify from "./UserModify";
//import UserRental from "./UserRental";
//import UserPayment from "./UserPayment";
import RentalList from "./RentalList";
import PaymentList from "./PaymentList";

const { Content } = Layout;
const { TabPane } = Tabs;

const UserInfo = (props) => {
  const id = props.match.params.id;

  return (
    <Content style={{ margin: "0 16px", height: "100%" }}>
      <Tabs onChange={() => {}} type="card">
        <TabPane tab="회원 정보" key="1">
          <UserModify {...props} />
        </TabPane>
        <TabPane tab="적립 내역" key="2">
          <UserCollect id={id} />
        </TabPane>
        <TabPane tab="구매 목록" key="3">
          <UserGoods id={id} />
        </TabPane>
        <TabPane tab="기부 목록" key="4">
          <UserDonate id={id} />
        </TabPane>
        <TabPane tab="찜한 가게" key="5">
          <UserBookmark id={id} />
        </TabPane>
        <TabPane tab="대여 목록" key="7">
          <RentalList uid={id} />
        </TabPane>
        <TabPane tab="결제 목록" key="8">
          <PaymentList uid={id} />
        </TabPane>
        <TabPane tab="리뷰" key="6">
          <UserReviews id={id} />
        </TabPane>
      </Tabs>
    </Content>
  );
};
export default UserInfo;
