import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getDonates = createAsyncThunk(
  "getDonates",
  async (params, { dispatch, getState }) => {
    console.log("call getDonates")
    const donatesRef = collection(db, "donate")
    // const q = query(donatesRef, orderBy("create", "desc"));
    const q = query(donatesRef, orderBy("order", "asc"));
    const donatesSnap = await getDocs(q)
    dispatch(setDonates(donatesSnap));
  }
);

const initialState = {
  docs: null,
};

const donates = createSlice({
  name: "donates",
  initialState,
  reducers: {
    setDonates: (state, action) => {
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp
    },

  },
});

//actions
export const {
    setDonates,
} = donates.actions;

//selectors
export const selectDonates = (state) => state.donates.docs;

export default donates.reducer;
