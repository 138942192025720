import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getGoods = createAsyncThunk(
  "getGoods",
  async (params, { dispatch, getState }) => {
    console.log("call getGoods")
    const goodsRef = collection(db, "goods")
    // const q = query(goodsRef, orderBy("create", "desc"));
    const q = query(goodsRef, orderBy("order", "asc"));
    const goodsSnap = await getDocs(q)
    dispatch(setGoods(goodsSnap));
  }
);

const initialState = {
  docs: null,
};

const goods = createSlice({
  name: "goods",
  initialState,
  reducers: {
    setGoods: (state, action) => {
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp
    },

  },
});

//actions
export const {
    setGoods,
} = goods.actions;

//selectors
export const selectGoods = (state) => state.goods.docs;

export default goods.reducer;
