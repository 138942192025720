import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Modal,
  Descriptions,
  Row,
  DatePicker,
  Form,
  InputNumber,
  Space,
  AutoComplete,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../FirebaseApp";
import { useHistory } from "react-router-dom";
import FileManager from "./FileManager";
import { useDispatch, useSelector } from "react-redux";
import { getDonates, selectDonates } from "../redux/reducers/donate";
import { selectShops } from "../redux/reducers/shops";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const { Content } = Layout;
const { TextArea, Search } = Input;
const { RangePicker } = DatePicker;
let newOrder = null;

const DonateAdd = () => {
  const dispatch = useDispatch();
  const formRef = React.createRef(null);
  const fileRef = React.createRef(null);
  const history = useHistory();

  const [shopList, setShopList] = useState([]);
  const [shopID, setShopID] = useState("");
  const shops = useSelector(selectShops);
  const donates = useSelector(selectDonates);

  useEffect(() => {
    newOrder = Object.keys(donates).length;
    let tempShops = [];
    for (const shopsKey in shops) {
      tempShops.push({
        value: shops[shopsKey].data().name,
        label: shops[shopsKey].data().name,
        id: shops[shopsKey].data().id,
      });
    }
    setShopList(tempShops);
  }, [shops]);

  const onSelectShop = (value) => {
    setShopID(shopList.filter((shop) => shop.value === value)[0].id);
    console.log(shopID);
  };

  const addConfirm = (value) => {
    console.log(value);
    Modal.confirm({
      title: "기부 정보를 추가하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{ color: "#52C41A" }} />,
      content: "",
      okText: "추가",
      cancelText: "취소",
      async onOk() {
        const docRef = doc(collection(db, "donate"));
        await setDoc(docRef, {
          id: docRef.id,
          shop_id: shopID,
          name: value.name,
          subtitle: value.subtitle,
          sponsor: value.sponsor,
          start: value.dateOfDonate[0]._d,
          end: value.dateOfDonate[1]._d,
          description: value.description,
          create: serverTimestamp(),
          update: serverTimestamp(),
          goal: value.goal,
          current: value.current,
          order: newOrder,
        });
        const idList = await fileRef.current.addFiles();

        for (const idListKey in idList) {
          const data = idList[idListKey];
          const name = data.name;
          const uri = data.uri;
          const fileRef = doc(docRef, "files", data.id);
          await setDoc(fileRef, {
            id: data.id,
            create: serverTimestamp(),
            name,
            uri,
          });
          // const storage = getStorage();
          // getDownloadURL(ref(storage, data.name))
          //   .then(async (url) => {
          //     await setDoc(fileRef, {
          //       id: data.id,
          //       create: serverTimestamp(),
          //       link: url,
          //     });
          //   })
          //   .catch((error) => {
          //     // Handle any errors
          //   });
        }

        dispatch(getDonates());
        history.push({ pathname: "/donate" });
      },
    });
  };

  return (
    <Content style={{ margin: "0 16px", height: "100%" }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, height: "100%", marginTop: 0 }}
      >
        <div style={{ padding: 50, height: "100%" }}>
          <Form ref={formRef} layout="vertical" name="form_in_modal">
            <Descriptions bordered>
              <Descriptions.Item label="기부 이름" span={3}>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "기부 이름을 입력하세요." },
                  ]}
                >
                  <Input placeholder="기부 이름" style={{ width: "453px" }} />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="서브타이틀" span={3}>
                <Form.Item
                  name="subtitle"
                  rules={[
                    { required: true, message: "서브타이틀을 입력하세요." },
                  ]}
                >
                  <TextArea showCount maxLength={500} />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="후원처" span={3}>
                <Form.Item
                  name="sponsor"
                  rules={[{ required: true, message: "후원처를 입력하세요." }]}
                >
                  <AutoComplete
                    style={{
                      width: 200,
                    }}
                    options={shopList}
                    placeholder=""
                    onSelect={onSelectShop}
                    filterOption={(inputValue, option) =>
                      option.value.indexOf(inputValue) !== -1
                    }
                  >
                    <Search placeholder="검색" />
                  </AutoComplete>
                  {/* <Search placeholder="input search text" style={{ width: 200 }} /> */}
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="목표 코인" span={1}>
                <Form.Item
                  name="goal"
                  rules={[
                    { required: true, message: "목표 코인을 입력하세요." },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="현재 코인" span={2}>
                <Form.Item
                  name="current"
                  rules={[
                    { required: true, message: "현재 코인을 입력하세요." },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="모금 기간" span={3}>
                <Form.Item
                  name="dateOfDonate"
                  rules={[
                    { required: true, message: "모금 기간을 선택하세요." },
                  ]}
                >
                  <RangePicker />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="기부 설명" span={3}>
                <Form.Item
                  name="description"
                  rules={[{ required: true, message: "설명을 입력하세요" }]}
                >
                  <TextArea showCount />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="사진 추가하기" span={3}>
                <FileManager ref={fileRef} path={"donate"} max={5} />
              </Descriptions.Item>
            </Descriptions>
          </Form>

          <Row justify="end" style={{ marginTop: "30px" }}>
            <Space>
              <Link to="/donate">
                <Button>목록</Button>
              </Link>
              <Button
                onClick={() => {
                  formRef.current
                    .validateFields()
                    .then((values) => {
                      addConfirm(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
                type="primary"
              >
                추가
              </Button>
            </Space>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default DonateAdd;
