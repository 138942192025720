/**
 *
 * @param value 인풋 폰넘버
 * @param setValue 인풋 온체인지에 필요한 setState
 */
export const handleInputPhoneNember = (
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>
) => {
  const reg = /^([0-9]{0,3})[-. ]?([0-9]{0,4})[-. ]?([0-9]{0,4})$/
  let regForm = ""
  if (value.length <= 3) regForm = "$1"
  else if (value.length <= 8) regForm = "$1-$2"
  else regForm = "$1-$2-$3"
  setValue(value.replace(reg, regForm))
}