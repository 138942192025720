import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import hashtags from "./reducers/hashtags";
import items from "./reducers/items";
import shops from "./reducers/shops";
import users from "./reducers/users";
import goods from "./reducers/goods";
import donates from "./reducers/donate";
import reviews from "./reducers/reviews";
import announcements from "./reducers/announcement";
import collectHistories from "./reducers/collect_history";
import goodsHistories from "./reducers/goods_history";
import donateHistories from "./reducers/donate_history";
import rents from "./reducers/rents";
import balances from "./reducers/balances";
import contents from "./reducers/contents";
import files from "./reducers/files";

const middlewares = [thunk];
// const middlewares = [logger, thunk];

const store = configureStore({
  reducer: {
    hashtags,
    items,
    shops,
    users,
    goods,
    donates,
    reviews,
    announcements,
    collectHistories,
    goodsHistories,
    donateHistories,
    rents,
    balances,
    contents,
    files,
  },
  middleware: [...middlewares],
});

export default store;
