import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../FirebaseApp";

export const deleteReview = createAsyncThunk(
  "deleteReview",
  async (params, { dispatch, getState }) => {
    const { userId, shopId, reviewId } = params;
    //
    // delete review on shop and user
    const shopDocRef = doc(db, "shops", shopId, "reviews", reviewId);
    await deleteDoc(shopDocRef);
    //
    const userDocRef = doc(db, "users", userId, "review", reviewId);
    await deleteDoc(userDocRef);
  }
);

const initialState = {};

const reviews = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
});

export const {} = reviews.actions;

export default reviews.reducer;
