import {AutoComplete, Avatar, Button, Cascader, Descriptions, Form, Input, InputNumber, Modal, Row, Space,} from "antd";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import React, {createRef, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {db} from "../FirebaseApp";
import {selectShops} from "../redux/reducers/shops";
import {getUsers, selectUsers} from "../redux/reducers/users";
import {handleInputPhoneNember} from "../utils/phoneNumber";
import {ExclamationCircleOutlined, UserOutlined} from "@ant-design/icons";
import zones from "../zones.json";
import FileManager from "./FileManager";

const {Search} = Input;

const zoneOptions = zones.data.map((zone) => {
  return {
    value: zone.adm_nm,
  };
});
const zoneList = zones.data;
let userZone = [];

function UserModify(props) {
  const dispatch = useDispatch();
  const shops = useSelector(selectShops);
  const users = useSelector(selectUsers);

  const formRef = createRef();
  const fileRef = createRef();

  // const [id, setId] = useState(null)
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("");
  const [shop, setShop] = useState("");
  // const [zone, setZone] = useState(null)

  const [adm_nm, setAdm_nm] = useState("");
  const [score, setScore] = useState(null);
  const [coin, setCoin] = useState(null);
  const [userAvatar, setUserAvatar] = useState("");
  const [files, setFiles] = useState(null);
  const [selfExpression, setSelfExpression] = useState("");
  const [instagram, setInstagram] = useState("");
  const id = props.match.params.id;

  const history = useHistory();

  const [shopList, setShopList] = useState([]);
  const roleList = [
    {
      value: "user",
      label: "회원",
    },
    {
      value: "branch",
      label: "가게 주인",
      children: shopList,
    },
    {
      value: "admin",
      label: "관리자",
    },
  ];

  const updateData = useCallback(() => {
    if (users === null) return;

    const user = users[id];

    if (user === undefined) {
      history.push({pathname: "/user"});
      return;
    }

    const data = user.data();
    // setId(id)
    const docRef = doc(db, "users", id);
    const filesRef = collection(docRef, "files");
    setFiles(filesRef);
    setName(data.name);
    handleInputPhoneNember(data.mobile, setMobile);
    setRole(
      data.role === "admin" || data.role === "branch" ? data.role : "user"
    );
    setShop(data.shop !== undefined ? data.shop : "");
    setAdm_nm(data.adm_nm);
    setScore(data.score);
    setCoin(data.coin);
    setSelfExpression(data.selfExpression);
    setInstagram(data.instagram);
    userZone = zoneList.filter((zone) => zone.adm_nm === data.adm_nm);

    const getUserFile = async () => {
      const f = query(filesRef, orderBy("create", "desc"));
      const filesSnap = await getDocs(f);
      if (filesSnap.docs[0]) {
        const id = filesSnap.docs[0].id;
        console.log("=== user file ===");
        console.log(id);
        const docRef = doc(db, "files", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          console.log(data);
          if (data?.uri) {
            setUserAvatar(data.uri);
          } else {
            const storage = getStorage();
            getDownloadURL(ref(storage, data.name))
              .then((url) => {
                setUserAvatar(url);
              })
              .catch((error) => {
                // Handle any errors
              });
          }
        }
      }
    };
    getUserFile();

    let shopList = [];
    for (const shopsKey in shops) {
      shopList.push({
        value: shopsKey,
        label: shops[shopsKey].data().name,
      });
    }
    setShopList(shopList);
  }, [history, id, shops, users]);

  useEffect(() => {
    if (users !== null) updateData();
  }, [users, updateData]);

  const onSelect = (value) => {
    userZone = zoneList.filter((zone) => zone.adm_nm === value);
    console.log(userZone);
  };

  const modifyConfirm = (value) => {
    Modal.confirm({
      title: "회원 정보를 수정하시겠습니까?",
      icon: <ExclamationCircleOutlined/>,
      content: "",
      okText: "수정",
      cancelText: "취소",
      async onOk() {
        const id = props.match.params.id;
        const docRef = doc(db, "users", id);
        await updateDoc(docRef, {
          id: docRef.id,
          role: value.role[0],
          shop: value.role.length > 1 ? value.role[1] : "",
          score: value.score,
          coin: value.coin,
          update: serverTimestamp(),
          sido: userZone[0].sido,
          sgg: userZone[0].sgg,
          adm_cd2: userZone[0].adm_cd2,
          adm_nm: userZone[0].adm_nm,
          dp_nm: userZone[0].temp.split(" ")[1],
          selfExpression: value.selfExpression ?? "",
          instagram: value.instagram ?? "",
        });

        await deleteSubCollection(docRef, "files");
        const idList = await fileRef.current.addFiles();
        for (const idListKey in idList) {
          const data = idList[idListKey];
          const name = data.name;
          const uri = data.uri;
          const fileDocRef = doc(docRef, "files", data.id);
          await setDoc(fileDocRef, {
            id: data.id,
            create: serverTimestamp(),
            name,
            uri,
          });
          //
          // 회원 데이터 필드에 업데이트
          // 앱에서 사용 됨
          await updateDoc(docRef, {
            profile: data.id,
            profile_link: name,
            profile_uri: uri,
          });
        }
        dispatch(getUsers());
        history.push({pathname: "/user"});
      },
    });
  };

  const deleteSubCollection = async (docRef, sub) => {
    const q = collection(docRef, sub);
    const docs = await getDocs(q);
    for (let i = 0; i < docs.size; i++) {
      const d = docs.docs[i];
      await deleteDoc(d.ref);
    }
    console.log(sub + " - collection all docs deleted");
  };

  const deleteConfirm = () => {
    Modal.confirm({
      title: "회원 정보를 삭제하시겠습니까?",
      icon: <ExclamationCircleOutlined style={{color: "red"}}/>,
      content: "",
      okText: "삭제",
      cancelText: "취소",
      async onOk() {
        const id = props.match.params.id;
        const docRef = doc(db, "users", id);
        await deleteDoc(docRef);
        await deleteSubCollection(docRef, "files");
        await fileRef.current.deleteAll();

        dispatch(getUsers());
        history.push({pathname: "/user"});
      },
    });
  };

  const onChange = (value) => {
    console.log(value);
  };

  return (
    <div
      className="site-layout-background"
      style={{padding: 24, height: "100%", marginTop: 0}}
    >
      <div style={{padding: 50, height: "100%"}}>
        <Avatar
          size={64}
          icon={<UserOutlined/>}
          style={{marginBottom: 35}}
          src={userAvatar}
        ></Avatar>
        <Form
          ref={formRef}
          layout="vertical"
          name="form_in_modal"
          fields={[
            {
              name: ["name"],
              value: name,
            },
            {
              name: ["mobile"],
              value: mobile,
            },
            {
              name: ["score"],
              value: score,
            },
            {
              name: ["coin"],
              value: coin,
            },
            {
              name: ["role"],
              value: [role, shop],
            },
            {
              name: ["zone"],
              value: adm_nm,
            },
            {
              name: ["selfExpression"],
              value: selfExpression,
            },
            {
              name: ["instagram"],
              value: instagram,
            },
          ]}
        >
          <Descriptions title="" bordered>
            <Descriptions.Item label="닉네임" span={3}>
              <Form.Item name="name">
                <Input placeholder="닉네임" readOnly={true}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="전화번호" span={3}>
              <Form.Item name="mobile">
                <Input placeholder="000-0000-0000" readOnly={true}/>
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="스코어" span={1}>
              <Form.Item name="score">
                <InputNumber/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="코인" span={2}>
              <Form.Item name="coin">
                <InputNumber/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="구분" span={1}>
              <Form.Item
                name="role"
                rules={[{required: true, message: "구분을 선택하세요."}]}
              >
                <Cascader
                  options={roleList}
                  onChange={onChange}
                  style={{width: "200px"}}
                  placeholder="Please select"
                  displayRender={(label) => label.join(" / ")}
                />
              </Form.Item>
            </Descriptions.Item>

            <Descriptions.Item label="동네" span={2}>
              <Form.Item
                name="zone"
                // rules={[{ required: true, message: 'Please input the zone!' }]}
              >
                {/* <Cascader options={region} onChange={onChange} placeholder="Please select" displayRender={label => label}/> */}
                {/* <Input placeholder="동네" style={{ width: '200px' }}/> */}
                <AutoComplete
                  options={zoneOptions}
                  onSelect={onSelect}
                  placeholder=""
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                >
                  <Search placeholder="검색"/>
                </AutoComplete>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="내 소개" span={1}>
              <Form.Item name="selfExpression">
                <Input placeholder=""/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="인스타그램" span={2}>
              <Form.Item name="instagram">
                <Input placeholder=""/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="사진 추가하기" span={3}>
              <FileManager ref={fileRef} path={"users"} max={1} files={files}/>
            </Descriptions.Item>
          </Descriptions>
        </Form>

        <div
          style={{
            marginTop: "30px",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Button onClick={deleteConfirm}>회원 삭제</Button>
          <Row justify="end" style={{width: "100%"}}>
            <Space>
              <Link to="/user">
                <Button>목록</Button>
              </Link>
              <Button
                onClick={() => {
                  formRef.current
                    .validateFields()
                    .then((values) => {
                      console.log(values);
                      modifyConfirm(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
                type="primary"
              >
                수정
              </Button>
            </Space>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default UserModify;
