import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Highlighter from "react-highlight-words";
import {Link} from "react-router-dom";
import {Button, Input, Layout, Modal, Space, Spin, Table, Tag} from "antd";
import {Excel} from "antd-table-saveas-excel";
import {
  DollarOutlined,
  FileExcelOutlined,
  PlusOutlined,
  SearchOutlined,
  VerticalAlignMiddleOutlined
} from "@ant-design/icons";
import {selectHashtags} from "../redux/reducers/hashtags";
import {selectItems} from "../redux/reducers/items";
import {getRents, selectRents} from "../redux/reducers/rents";
import {getShopsSubCollection, selectShopItems, selectShops, selectShopSavings} from "../redux/reducers/shops";

const {Content} = Layout;

const Shop = () => {
  const shops = useSelector(selectShops);
  const shopItems = useSelector(selectShopItems);
  const shopSavings = useSelector(selectShopSavings);
  const items = useSelector(selectItems);
  const hashtags = useSelector(selectHashtags);

  const rents = useSelector(selectRents);

  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [loading, setLoading] = useState(true);
  let searchInputRef = useRef < HTMLInputElement > null;

  const dispatch = useDispatch();

  const [headTitle, setHeadTitle] = useState("대시보드");
  const [breadcrumb1, setBreadcrumb1] = useState(false);
  const [breadcrumb2, setBreadcrumb2] = useState("대시보드");
  const [key, setKey] = useState(1);
  const setState = (obj) => {
    setKey(obj.key)
    setHeadTitle(obj.headTitle)
    setBreadcrumb1(obj.breadcrumb1)
    setBreadcrumb2(obj.breadcrumb2)
  }
  let needUpdate = true
  const [shopStat, setShopStat] = useState(null);
  useEffect(() => {
    dispatch(getRents());
  }, []);

  useEffect(() => {
    if (shops !== null) {
      const shopsLength = Object.keys(shops).length;
      if (shopsLength > 0) {
        let temp = {};
        for (const shopsKey in shops) {
          temp[shopsKey] = {
            total: 0,
            rent: 0,
            return: 0,
            lost: 0
          }
        }
        setShopStat(temp);
      }
    }
  }, [shops]);

  useEffect(() => {
    if (rents !== null) {
      if (shopStat !== null) {
        for (const rid in rents) {
          const rent = rents[rid];
          const sid = rent.data().rented_shop_id;
          const status = rent.data().status;
          if (shopStat[sid] !== undefined) {
            shopStat[sid].total++;
            if (status === 'rent') shopStat[sid].rent++;
            else if (status === 'return') shopStat[sid].return++;
            else if (status === 'lost') shopStat[sid].lost++;
          }
        }
      }
    } else {
      dispatch(getRents());
    }
  }, [rents, shopStat]);

  useEffect(() => {
    setLoading(true);
    const shopsLength = Object.keys(shops).length

    if (shops !== null && shopsLength > 0
      && hashtags && needUpdate
    ) {
      needUpdate = false

      let newData = [];
      let count = 0;

      for (const shopsKey in shops) {
        let newItems = [];
        let shopType = "";
        const shop = shops[shopsKey];
        const data = shop.data()

        let division;
        if (data.division === "none") division = "미가입";
        else if (data.division === "individual") division = "개별";
        else if (data.division === "cluster1") division = "클러스터1";
        else division = "클러스터2";
        let publish;
        if (data.publish === "public") publish = "공개";
        else if (data.publish === "protect") publish = "숨김";
        else publish = "비공개";

        if (hashtags !== null && hashtags[data.type] !== undefined) {
          shopType = hashtags[data.type].data().hashtag;
        }

        const idx = newData.push({
          idx: shopsLength - count,
          key: shop.id,
          id: shop.id,
          name: data.name,
          pin: data.pin,
          address: data.address,
          division: division,
          publish: publish,
          items: newItems,
          phone: data.phone,
          adm_nm: data.adm_nm,
          sggnm: data.sggnm,
          create: data.create,
          order: data.order,
          sidonm: data.adm_nm.substring(0, data.adm_nm.indexOf(" ")),
          type: shopType,
          initial_stock: data.initial_stock,
          current_stock: data.current_stock,
          rent: (shopStat !== null && shopStat[shop.id] !== undefined ? shopStat[shop.id].total : 0),
          returned: (shopStat !== null && shopStat[shop.id] !== undefined ? shopStat[shop.id].return : 0),
        });

        count++;
      }

      setDatas(newData);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }

  }, [shops, items, hashtags, dispatch, shopStat]);

  const loadSavings = async () => {
    const cloneData = datas.slice()
    let count = 0
    let itemDocs;
    let savingDocs;

    // 01. 숍 루프
    for (const shopsKey in shops) {
      let tempItems = []

      if (!shopItems[shopsKey]) {
        // store 에 저장된 데이터가 없으면 서버에서 가져옴
        const getShopItems = await dispatch(getShopsSubCollection({id: shopsKey, subCollection: "items"}))
        const getShopSavings = await dispatch(getShopsSubCollection({id: shopsKey, subCollection: "savings"}))
        itemDocs = getShopItems.payload.docs
        savingDocs = getShopSavings.payload.docs
      } else {
        // store 에 있는 데이터 사용함
        itemDocs = shopItems[shopsKey].docs
        savingDocs = shopSavings[shopsKey].docs
      }

      let total = null;
      // 02-1. 세이빙 루프, total 문서 필터링
      for (const saving of savingDocs) {
        if (saving.id === "total") {
          total = saving
        }
      } // 02-1. 세이빙 루프 종료
      const totalData = total?.data()

      // 02-2. 아이템즈 루프
      for (const item of itemDocs) {
        if (items[item.id]) {
          if (totalData && totalData[item.id]) {
            tempItems.push({
              name: items[item.id].data().name,
              quantity: totalData[item.id],
            });
          } else {
            tempItems.push({
              name: items[item.id].data().name,
              quantity: 0,
            });
          }
        }
      } // 02-2. 아이템즈 루프 종료

      tempItems.sort((a,b)=>{
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });

      cloneData[count]["items"] = tempItems;
      count++
    } // 01. 숍 루푸 종료

    setDatas(cloneData)
  }


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
                       setSelectedKeys,
                       selectedKeys,
                       confirm,
                       clearFilters,
                     }) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInputRef = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: "block"}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "order",
      key: "order",
      width: "1%",
      render: (order) => {
        return order + 1;
      },
    },
    {
      title: "시",
      dataIndex: "sidonm",
      key: "sidonm",
      ...getColumnSearchProps("sidonm"),
      sorter: (a, b) => {
        if (a.sidonm > b.sidonm) return 1;
        if (a.sidonm < b.sidonm) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "지역",
      dataIndex: "sggnm",
      key: "sggnm",
      ...getColumnSearchProps("sggnm"),
      sorter: (a, b) => {
        if (a.sggnm > b.sggnm) return 1;
        if (a.sggnm < b.sggnm) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "공개",
      dataIndex: "publish",
      key: "publish",
      sorter: (a, b) => {
        if (a.publish > b.publish) return 1;
        if (a.publish < b.publish) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
      render: (publish) => {
        let color = "default";
        if (publish === "공개") {
          color = "green";
        } else if (publish === "숨김") {
          color = "blue";
        }
        return (
          <Tag color={color} key={publish}>
            {publish}
          </Tag>
        );
      },
      excelRender: (publish) => {
        return publish;
      },
    },
    {
      title: "가게 유형",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type"),
      sorter: (a, b) => {
        if (a.type > b.type) return 1;
        if (a.type < b.type) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "가게 이름",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "대여",
      dataIndex: "rent",
      key: "rent",
      align: "right",
      render: (rent) => {
        return (new Intl.NumberFormat().format(rent));
      }
    },
    {
      title: "반납",
      dataIndex: "returned",
      key: "returned",
      align: "right",
      render: (returned) => {
        return (new Intl.NumberFormat().format(returned));
      }
    },
    {
      title: "현재",
      dataIndex: "current_stock",
      key: "current_stock",
      align: "right",
      render: (current_stock) => {
        return ((current_stock !== undefined && current_stock !== '') ? new Intl.NumberFormat().format(current_stock) : '');
      }
    },
    {
      title: "최초",
      dataIndex: "initial_stock",
      key: "initial_stock",
      align: "right",
      render: (initial_stock) => {
        return ((initial_stock !== undefined && initial_stock !== '') ? new Intl.NumberFormat().format(initial_stock) : '');
      }
    },
    {
      title: "컵공유",
      dataIndex: "division",
      key: "division",
      render: (division) => {
        let color = "default";
        if (division === "개별") {
          color = "green";
        } else if (division === "클러스터1") {
          color = "blue";
        } else if (division === "클러스터2") {
          color = "purple";
        }
        return (
          <Tag color={color} key={division}>
            {division}
          </Tag>
        );
      },
      excelRender: (division) => {
        return division;
      },
    },
    {
      title: "품목",
      dataIndex: "items",
      key: "items",
      render: (items) => {
        return (
          <>
            {items.map((item) => (
              <Tag color="magenta">{item.name}</Tag>
            ))}
          </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map((item) => {
          return item.name;
        });
        return resultItems.join(", ");
      },
    },
    {
      title: "적립 수",
      dataIndex: "items",
      key: "itemsQuantity",
      render: (items) => {
        return (
          <>
            {items.map((item) => (
              <Tag color="magenta" key={item.name}>
                {item.quantity === undefined ? 0 : item.quantity}
              </Tag>
            ))}
          </>
        );
      },
      excelRender: (items) => {
        let resultItems = items.map((item) => {
          return item.quantity === undefined ? 0 : item.quantity;
        });
        return resultItems.join(", ");
      },
    },
    {
      title: "비고",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: "5%",
      render: (text, record, index) => {
        return (
          <Link
            to={{
              pathname: "/shop/info/" + record.id,
            }}
          >
            <Button type="primary" ghost
                    onClick={() => setState({headTitle: '가게', breadcrumb1: '가게', breadcrumb2: '정보 수정'})}>수정</Button>
          </Link>
        );
      },
      excelRender: () => {
        return;
      },
    },
  ];

  return (
    <Content style={{margin: "0 16px", height: "100%"}}>
      <div
        className="site-layout-background"
        style={{padding: 24, height: "100%"}}
      >

        <Link to={"/shop/sort"}>
          <Button
            icon={<VerticalAlignMiddleOutlined/>}
            type="second"
            style={{position: "absolute", left: "240px"}}
            disabled={loading}
          >
            가게 정렬
          </Button>
        </Link>
        <Button
          icon={<DollarOutlined/>}
          type="second"
          style={{position: "absolute", left: "365px"}}
          onClick={() => {
            Modal.confirm({
              title: '적립 로드',
              icon: <DollarOutlined/>,
              content: '첫 작업시 가게 수에 따라 시간이 약 1분 이상 소요됩니다. 로드 하시겠습니까?',
              okText: '로드',
              cancelText: '취소',
              onOk: loadSavings,
            })
          }}
          disabled={loading}
        >
          적립 로드
        </Button>
        <Button
          icon={<FileExcelOutlined/>}
          type="second"
          style={{position: "absolute", left: "490px"}}
          onClick={() => {
            const excel = new Excel();
            excel
              .setTHeadStyle({background: "FFFFFF"})
              .addSheet("가게 목록")
              .addColumns(columns)
              .addDataSource(datas)
              .saveAs("가게 목록.xlsx");
          }}
          disabled={loading}
        >
          다운로드
        </Button>
        {/*|*/}
        <Link to="/shop/add">
          <Button
            icon={<PlusOutlined/>}
            type="primary"
            style={{position: "absolute", right: "40px"}}
            disabled={loading}
          >
            가게 추가
          </Button>
        </Link>
        {/*-----------------------------------------------------------*/}
        <Spin tip="Loading..." spinning={loading}>
          <Table
            columns={columns}
            dataSource={datas}
            style={{marginTop: "50px"}}
            rowKey={(record) => record.id}
          />
        </Spin>

      </div>
    </Content>
  );
};

export default Shop;
