import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {db} from "../../FirebaseApp";

export const getItems = createAsyncThunk(
  "getItems",
  async (params, { dispatch, getState }) => {
    console.log("call getItems")
    const itemsRef = collection(db, "items")
    // const q = query(itemsRef, orderBy("order", "asc"));
    const q = query(itemsRef, orderBy("create", "desc"))
    const itemsSnap = await getDocs(q)
    dispatch(setItems(itemsSnap));
  }
);

const initialState = {
  docs: null,
};

const items = createSlice({
  name: "items",
  initialState,
  reducers: {
    setItems: (state, action) => {
      // 키로 값을 참조하기 위한 오브젝트 생성
      let temp = {}
      action.payload.forEach((doc)=>{
        temp[doc.id] = doc
      })
      state.docs = temp
    },

  },
});

//actions
export const {
  setItems,
} = items.actions;

//selectors
export const selectItems = (state) => state.items.docs;

export default items.reducer;
