import {
  Avatar,
  Breadcrumb,
  Button,
  Divider,
  Layout,
  Menu,
  Modal,
  Space,
  Typography,
} from "antd";
import {
  ExclamationCircleOutlined,
  LineChartOutlined,
  ReadOutlined,
  RestOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, Route } from "react-router-dom";
import BranchInfo from "./BranchInfo";
import BranchCollectHistory from "./BranchCollectHistory";
import BranchGoodsHistory from "./BranchGoodsHistory";
import BranchBottleclub from "./BranchBottleclub";
import BranchDashboard from "./BranchDashboard";
import React, { useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../FirebaseApp";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getShops, getBranch } from "../redux/reducers/shops";

const { SubMenu } = Menu;
const { Header, Footer, Sider } = Layout;
const { Text } = Typography;

const Branch = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [key, setKey] = useState("1");
  const [headTitle, setHeadTitle] = useState("대시보드");
  const [breadcrumb1, setBreadcrumb1] = useState(false);
  const [breadcrumb2, setBreadcrumb2] = useState("대시보드");

  useEffect(() => {
    //dispatch(getBranch({id: props.shopid}))
    dispatch(getShops());
  }, [props.shopid, dispatch]);

  const setState = (obj) => {
    setKey(obj.key);
    setHeadTitle(obj.headTitle);
    setBreadcrumb1(obj.breadcrumb1);
    setBreadcrumb2(obj.breadcrumb2);
  };

  function logout() {
    Modal.confirm({
      title: "로그아웃 하시겠습니까?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "로그아웃",
      cancelText: "취소",
      onOk() {
        signOut(auth)
          .then(() => {
            history.push("/");
            // this.destroy();
          })
          .catch((error) => {});
      },
    });
  }

  return (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100%",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo">
          <Text style={{ color: "#FFFFFF", fontSize: "18px" }}>보틀클럽</Text>
        </div>
        <Menu theme="dark" mode="inline">
          <Menu.Item key="1" icon={<LineChartOutlined />}>
            <Link
              to={{ pathname: "/" }}
              onClick={() =>
                setState({
                  key: "1",
                  headTitle: "대시보드",
                  breadcrumb1: "",
                  breadcrumb2: "대시보드",
                })
              }
            >
              대시보드
            </Link>
          </Menu.Item>
          {/*          
          <Menu.Item key="2" icon={<ShopOutlined />}><Link to={{ pathname: "/info", state: {shopid: props.shopid} }} onClick={() => setState({key:'2', headTitle:'가게 관리', breadcrumb1: '', breadcrumb2: '가게 관리'})}>가게 관리</Link></Menu.Item>
*/}
          <SubMenu key="sub1" icon={<ReadOutlined />} title="히스토리">
            <Menu.Item key="4">
              <Link
                to={{
                  pathname: "/collect_history",
                  state: { shopid: props.shopid },
                }}
                onClick={() =>
                  setState({
                    headTitle: "히스토리",
                    breadcrumb1: "히스토리",
                    breadcrumb2: "적립",
                  })
                }
              >
                적립
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link
                to={{
                  pathname: "/goods_history",
                  state: { shopid: props.shopid },
                }}
                onClick={() =>
                  setState({
                    headTitle: "히스토리",
                    breadcrumb1: "히스토리",
                    breadcrumb2: "쿠폰 사용",
                  })
                }
              >
                쿠폰 사용
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="13" icon={<RestOutlined />}>
            <Link
              to="/branch_bottleclub"
              onClick={() =>
                setState({
                  headTitle: "컵 공유",
                  breadcrumb1: "컵 공유",
                  breadcrumb2: "",
                })
              }
            >
              컵 공유
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: 200, height: "100%" }}
      >
        <Header
          className="site-layout-background"
          style={{ paddingLeft: 24, display: "flex" }}
        >
          <h2 style={{ fontWeight: 600 }}>{headTitle}</h2>
          <div style={{ position: "absolute", right: 0 }}>
            <Space size={"small"}>
              {/* <Avatar icon={<UserOutlined />} src={props.userAvatar}/> */}
              {props.userName}
            </Space>
            <Divider type="vertical" />
            <Button type="text" onClick={logout} style={{ paddingLeft: 0 }}>
              로그아웃
            </Button>
          </div>
        </Header>
        <Breadcrumb style={{ margin: "16px 16px" }}>
          <Breadcrumb.Item>보틀클럽</Breadcrumb.Item>
          <Breadcrumb.Item>{breadcrumb1}</Breadcrumb.Item>
          <Breadcrumb.Item>{breadcrumb2}</Breadcrumb.Item>
        </Breadcrumb>
        {/* <Route exact path="/info" component={BranchInfo}></Route> */}
        <Route exact path="/info">
          <BranchInfo shopid={props.shopid} />
        </Route>
        <Route
          exact
          path="/collect_history"
          component={BranchCollectHistory}
        ></Route>
        <Route
          exact
          path="/goods_history"
          component={BranchGoodsHistory}
        ></Route>
        <Route exact path="/branch_bottleclub">
          <BranchBottleclub shopid={props.shopid} />
        </Route>
        {/* <Route exact path="/" component={BranchDashboard}></Route> */}
        <Route exact path="/">
          <BranchDashboard shopid={props.shopid} />
        </Route>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
};

export default Branch;
